import API from "../../../../../global/api";
import { isEmpty, isNull, sum } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
export const requestAddDeal = () => {
  return {
    type: "REQUEST_ADD_DEAL",
  };
};
export const successAddDeal = (data) => {
  return {
    type: "SUCCESS_ADD_DEAL",
    payload: data,
  };
};
export const errorAddDeal = () => {
  return {
    type: "ERROR_ADD_DEAL",
  };
};
export const addDeal = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddDeal());
    API.post(`/deal/add_deal`, data)
      .then((res) => {
        dispatch(successAddDeal(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddDeal());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestDealsData = () => {
  return {
    type: "REQUEST_DEALS_GET_DATA",
  };
};
export const successDealsGetData = (data) => {
  return {
    type: "SUCCESS_DEALS_GET_DATA",
    payload: data,
  };
};
export const errorDealsGetData = () => {
  return {
    type: "ERROR_DEALS_GET_DATA",
  };
};
export const getDealsData = () => {
  return (dispatch) => {
    dispatch(requestDealsData());
    API.get(`/deals`)
      .then((res) => {
        let getData = res.data;
        dispatch(successDealsGetData(getData));
      })
      .catch((error) => {
        dispatch(errorDealsGetData());
      });
  };
};
export const DeleteRequestDeals = () => {
  return {
    type: "DELETE_REQUEST_DEAL",
  };
};
export const DeleteSuccessDeals = (user_id) => {
  return {
    type: "DELETE_SUCCESS_DEAL",
    user_id: user_id,
  };
};
export const DeleteErrorDeals = () => {
  return {
    type: "DELETE_ERROR_DEAL",
  };
};
export const deleteDeals = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestDeals());
    API.delete(`/deal/${vendorId}`)
      .then((res) => {
        dispatch(DeleteSuccessDeals(vendorId));
      })
      .catch((error) => {
        dispatch(DeleteErrorDeals());
      });
  };
};
