import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  couponsList: [],
  isAddingCoupons: false,
  couponsDataList: [],
  isFetchingCouponsData: false,
};

const CouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_COUPONS":
      return { ...state, isAddingCoupons: true };
    case "SUCCESS_ADD_COUPONS":
      return {
        ...state,
        couponsList: action.payload,
        isAddingCoupons: false,
      };
    case "ERROR_ADD_COUPONS":
      return { ...state, isAddingCoupons: false };
    case "REQUEST_GET_COUPONS":
      return { ...state, isFetchingCouponsData: true };
    case "SUCCESS_GET_COUPONS":
      return {
        ...state,
        couponsDataList: action.payload.data,
        isFetchingCouponsData: false,
      };
    case "ERROR_GET_COUPONS":
      return { ...state, isFetchingCouponsData: false };
    default:
      return state;
  }
};
export default CouponReducer;
