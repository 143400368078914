import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL ,headers} from "../../../../../global/api";
import { deleteProduct, getProductBalance, getProductData, getSimpleCompleteProduct, updateProduct } from "./Product.action";
import { setHeading } from "../../../accounts/account.actions";
import { isEmpty } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png"
const ProductBalacneReport = (props) => {
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType,permissionsName) => {
    console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
   return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
  }
  // const resData={props.productDataList}
  useEffect(() => {
    props.setHeading("Product Difference Report");
    props.getProductBalance();
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingProductBalance ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
        <div className="main">
          {/* <div className="sendDiv">
          <Button onClick={()=>{props.getCompleteProduct()}}>
                      Generate Report
                    </Button>
          </div> */}
        <MaterialTable
                  title="Product Balacne Detail"
                  columns={[
                     { title: 'Code', field: 'code', editable: 'never' },
                     { title: "Product Name", field: "productName"},
                     { title: "Product Balance", field: "productBalance",editable:'never' },
                     { title: "Closign Balance", field: "closing_balance",editable:'never' },
                  
                  ]}

                  data={props.productBalanceList}
                  //    data={(query) =>
                  //     new Promise((resolve, reject) => {
                  //         let url = `${baseURL}/products/completeProduct?`;
                  //         url += "limit=" + query.pageSize;
                  //         url += "&page=" + (query.page);
                  //          url += "&search=" + query.search;
                  //         fetch(url, {
                  //             method: 'GET',
                  //             headers: headers,
                  //         })
                  //             .then((response) => response.json())
                  //             .then((result) => {
                  //                 resolve({
                  //                   data1:result,
                  //                     data: result.allData,
                  //                     page: result.page,
                  //                     totalCount: result.total_results,
                  //                 });
                  //             });

                  //     })
                  // }
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                    paging: true,
                    pageSize: 50, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [20,50, 100,150, 200], // rows selection options
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                    exportPdf: (columns, data,data12) => {
                  console.log(data12,"ttttttttttttttttttttt")
                      console.log(data, "ttttttt");
                      const doc = new jsPDF();
                      const columnTitles = [
                        { title: 'Code', field: 'code', editable: 'never' },
                        { title: "Product Name", field: "productName"},
                        { title: "Product Balance", field: "productBalance",editable:'never' },
                        { title: "Closign Balance", field: "closing_balance",editable:'never' },
                     ];
                      //  const pdfData = data.map((rowData) => [rowData.sub_event_name, rowData.sub_event_desc,rowData.event.event_name]);
                      const pdfData = data.map((columnDef) => [
                        columnDef.code,
                        columnDef.productName,
                        columnDef.data.uomLabel,
                        columnDef.productBalance, 
                        columnDef.closing_balance,
                        
                      ]);
                      let content = {
                        startY: 50,
                        startX: 5,
                        head: [columnTitles],
                        body: pdfData,
                      };
                      const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                      const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                      const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;
                      // const si=Number(deValue)>Number(CrValue)?"Debit":Number(deValue)<Number(CrValue)?"Credit":"";
                      doc.addImage(logo, "PNG", 10, 11, 30, 30);
                      // doc.text(`Company Name: ${companyName}`, 75, 20, 0, 20);

                      doc.setFont("Times New Roman", "bolditalic");
                      // doc.setFontT("bolditalic");
                      doc.text(companyName, 75, 15, 0, 20);
                      doc.setFontSize(9);
                      doc.setFont("Times New Roman", "normal");
                      doc.text(companyAddress, 75, 20, 0, 20);
                      doc.text(companyNTN, 75, 25, 0, 20);
                      doc.setFontSize(14);
                      doc.setFont("Times New Roman", "normal");
                      doc.text("Store Product Report", 80, 40, 0, 20);
                      // doc.text(`Total Debit: ${deValue}`, 5, 56, 0, 20);
                      // doc.text(`Total Credit: ${CrValue}`, 5, 66, 0, 20);
                      // doc.text(`Net Balance:${Math.abs(netValue)} ${si}`, 5, 76, 0, 20);
                      doc.autoTable(content);

                      doc.save(`Product Balance Report.pdf`);
                    },
                  }
                }
                  
                  // editable={{
                  //   isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Product Edit"),
                  //   isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Product Delete"),
                  //   onRowUpdate: (newData, oldData) =>
                  //     new Promise((resolve) => {
                  //       console.log(oldData,"uuuuuuuuuuuuuu")
                  //       setTimeout(() => {
                  //         resolve();
                  //         {
                  //           props.updateProduct(newData, oldData);
                  //         }
                  //       }, 600);
                  //     }),
                  //     isDeletable:false,
                  //   onRowDelete: (oldData) =>
                  //     new Promise((resolve) => {
                  //       setTimeout(() => {
                  //         resolve();
                  //         props.deleteProduct(oldData._id);
                  //       }, 600);
                  //     }),
                  // }}
                />
                </div>
                </Container>
                )}
                </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProductBalance:()=>dispatch(getProductBalance()),
    setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productBalanceList: state.ProductReducer.productBalanceList,
  isFetchingProductBalance: state.ProductReducer.isFetchingProductBalance,
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductBalacneReport);
