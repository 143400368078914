import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL ,headers} from "../../../../../global/api";

import { setHeading } from "../../../accounts/account.actions";
import { isEmpty } from "lodash";
import { getRecipeData } from "./Recipe.action";
const ViewRecipe = (props) => {
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType,permissionsName) => {
    console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
   return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
  }
  useEffect(() => {
    props.setHeading("Recipe List");
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingRecipe ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
        <div className="main">
        <MaterialTable
                  title="Recipe Details"
                  columns={[
                    // { title: 'Product Id', field: 'product_id', editable: 'never' },
                    { title: "Product Name", field: "productName" ,editable:'never'},
                    { title: "Variation Name", field: "productVariationName" ,editable:'never'},
                    { title: "Product Date", field: "productDate",editable:'never' },
                   // { title: "Product Description", field: "desc", },
                  ]}
                 // data={wareHouseData}
                     data={(query) =>
                      new Promise((resolve, reject) => {
                          let url = `${baseURL}/recipe?`;
                          url += "limit=" + query.pageSize;
                          url += "&page=" + (query.page);
                           url += "&search=" + query.search;
                          fetch(url, {
                              method: 'GET',
                              headers: headers,
                          })
                              .then((response) => response.json())
                              .then((result) => {
                                  resolve({
                                      data: result.data,
                                      page: result.page,
                                      totalCount: result.total_results,
                                  });
                              });

                      })
                  }
                
                  detailPanel={rowData => {
                    return (
                        <MaterialTable
                            //columns={state.columnsData}
                            columns={[
                                { title: 'Recipe Product ', field: 'recipeProductName' },
                                { title: 'Quantity', field: 'quantity' },
                                { title: 'UOM', field: 'uomName' },
                                { title: 'Price', field: 'price' },
                            ]}
                            data={rowData.recipeProducts}
                            // data={(query) =>
                            //     new Promise((resolve, reject) => {
                            //         let url = `${baseURL}/receiving?`;
                            //         url += "limit=" + query.pageSize;
                            //         url += "&page=" + (query.page);
                            //         url += "&search=" + query.search;
                            //         fetch(url, {
                            //             method: 'GET',
                            //             headers: headers,
                            //         })
                            //             .then((response) => response.json())
                                        
                            //             .then((result) => {
                            //                 let a=-1;
                            //                 resolve({
                                                
                            //                     data: result.data[a+1].receivingData,
                            //                     page: result.page,
                            //                     totalCount: result.total_results,
                            //                 });
                            //             });
                                        
                            //     })
                            // }                            
                            options={{
                                actionsColumnIndex: -1,
                                toolbar: false,
                                exportButton: true,
                                sorting: true,
                               paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}

                        />
                    )
                }}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                    paging: true,
                    pageSize: 50, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [30, 50,100,150,200], // rows selection options
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  // editable={{
                  //   onRowUpdate: (newData, oldData) =>
                  //     new Promise((resolve) => {
                  //       setTimeout(() => {
                  //         resolve();
                  //         {
                  //           props.updateProduct(newData, oldData);
                  //         }
                  //       }, 600);
                  //     }),
                  //   onRowDelete: (oldData) =>
                  //     new Promise((resolve) => {
                  //       setTimeout(() => {
                  //         resolve();
                  //         props.deleteProduct(oldData._id);
                  //       }, 600);
                  //     }),
                  // }}
                />
                </div>
                </Container>
                )}
                </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRecipe: () => dispatch(getRecipeData()),
    // deleteProduct: (productId) => dispatch(deleteProduct(productId)),
    // updateProduct: (newData, oldData) =>
    //   dispatch(updateProduct(newData, oldData)),
      setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  recipeList: state.RecipeReducer.recipeList,
  isFetchingRecipe: state.RecipeReducer.isFetchingRecipe,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecipe);
