import React, { useEffect } from "react";
import { Container, Form, Row, Col, Button, FormGroup, Navbar } from "react-bootstrap";
import Select from "react-select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Accordion from "react-bootstrap/Accordion";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { style } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import { filterOrderByTableKds, filterOrderKds, getPendingOrder, updateStatus } from "./KDSView.action.js";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Spinner from "react-bootstrap/Spinner";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import PrintIcon from "@mui/icons-material/Print";
import IconMenu from "../../../drawer/IconMenu";
import { getOrderPrintReport } from "../Reports/Report.action";
import { useState } from "react";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import swal from "sweetalert";
import { socket } from "../../../../../socket.js";
import { baseURL } from "../../../../../global/api.js";
import audio from "../../../../../Images/beep.mp3";
import moment from "moment";
import { ElectricalServicesSharp } from "@mui/icons-material";
import { io } from "socket.io-client";
import { isEmpty } from "lodash";

let test = false;

function KDSView(props) {


  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTable, setSearchTable] = useState("");
  const playAudio = () => {
    new Audio(audio).play();
  };

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    swal({
      title: "Success",
      text: "Order Status update Successfully",
      icon: "success",
      button: "Ok",
      timer:3000,
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const getColor = (startTime, endTime, greenTime, yellowTime, redTime) => {
    console.log(startTime, endTime, "testcolor");
    let diff = parseInt(
      moment.duration(moment(endTime).diff(moment(startTime))).asMinutes()
    );
    console.log(diff, "color");
    if (diff <= greenTime) {
      return "green";
    } else if (diff >= greenTime && diff <= yellowTime) {
      return "#fdb515";
    } else {
      return "red";
    }
  };

  useEffect(() => {
    props.getPendingOrder();
    socket.on("addOrderSocket", (dispatch) => {
      props.getPendingOrder();
      playAudio()
    });
    socket.on("cancelOrderSocket", (dispatch) => {
      props.getPendingOrder();
      playAudio()
    });
    socket.on("timeUpdate", (dispatch) => {
      props.getPendingOrder();
    });
    socket.on("updateOrderSocket", (dispatch) => {
      console.log("updateOrder")
      props.getPendingOrder();
    });
  }, [socket]);
  const sendMessage = () => {
    console.log("clicked")
    socket.emit("send_message", { message:'hello' });
  };
  return (
    <Container fluid>
      <div >
    <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Kitchen Display System</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
      </div>
      {props.isGettingPendingOrder ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isGettingPendingOrder ? "Loading..." : "Saving..."}
          </Button>
        </div>
      ) : (
        <div className="main" style={{ backgroundColor: "#e9ecef" }}>
           <Row>
                <Col xl="6" lg="6" md="6" sm="6" className="mt-2 mb-2">
                <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Search Order..."
                      name="receivedBy"
                      value={search}
                      onChange={(e) => {
                        setSearchTable("")
                        props.searchOrder(e.target.value);
                        setSearch(e.target.value)

                       
                        console.log(e.target.value, "aaaaaaaaaaaaaa");
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xl="6" lg="6" md="6" sm="6" className="mt-2 mb-2">
                <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Search Order By Table..."
                      value={searchTable}
                      name="receivedBy"
                      onChange={(e) => {
                        setSearch("")
                        props.searchOrderbyTable(e.target.value);
                        setSearchTable(e.target.value)
                        console.log(e.target.value, "aaaaaaaaaaaaaa");
                      }}
                    />
                  </Form.Group>
                  </Col>
              </Row>
          <Row>
            {isEmpty(props.SearchPendingOrderList) ? (
              <b style={{ textAlign: "center" }}>No Order Found</b>
            ) : (
              props.SearchPendingOrderList.map((item, i) => {
                
                return (
                  <React.Fragment key={i}>
                    <Col
                      xl="3"
                      lg="4"
                      md="6"
                      sm="12"
                      // style={{
                      //   borderRightStyle: "solid",
                      //   borderRightColor: "#e9ecef",
                      // }}
                    >
                      <Card
                        style={{ marginBottom: "5%", borderRadius: "15px", }}
                      >
                        <CardActionArea>
                          <CardContent>
                            <Typography
                              gutterBottom
                              // variant="body3"
                              // component="div"
                            >
                              {/* <div style={{ backgroundColor: "Green" }}>
                            <p style={{ marginTop: "5px", marginLeft: "5px" }}>
                              <b>Table:{item.table}</b>
                            </p>
                            <p
                              style={{ marginLeft: "200px", marginTop: "-5px" }}
                            >
                              <b>{item.customer}</b>
                            </p>
                          </div> */}

                              <Row >
                              <Col xl="3" lg="3" md="3" sm="3" ><Button ><b style={{fontSize:'20px'}}>{item.number}</b></Button></Col>
                              {/* <Col xl="1" lg="1" md="1" sm="1"></Col> */}
                                <Col
                                  xl="5"
                                  lg="5"
                                  md="5"
                                  sm="5"
                                  style={{
                                    // backgroundColor: getColor(
                                    //   item.createdAt,
                                    //   new Date(),
                                    //   10,
                                    //   25,
                                    //   45
                                    // ),
                                    borderRadius: "10px",
                                    
                                  }}
                                >
                                  <Button
                                    // onClick={()=>{
                                    //   props.updateStatus(item,"Cooking")
                                    //   console.log(item._id,"clcik ico")}}
                                    style={{
                                      backgroundColor: getColor(
                                        item.createdAt,
                                        new Date(),
                                        10,
                                        25,
                                        45
                                      ),
                                      border: "none",
                                      paddingTop:'9px',
                                      paddingBottom:'9px'
                                    }}
                                  >
                                  <b>  {`${parseInt(
                                      moment
                                        .duration(
                                          moment(new Date()).diff(
                                            moment(item.createdAt)
                                          )
                                        )
                                        .asMinutes()
                                    )} min`}</b>
                                  </Button>
                                </Col>
                                
                                <Col xl="4" lg="4" md="4" sm="4" >
                                  <Button
                                    // onClick={()=>{
                                    //   props.updateStatus(item,"Cooking")
                                    //   console.log(item._id,"clcik ico")}}
                                    style={{
                                      backgroundColor: "green",
                                      border: "none",
                                       paddingBottom:'12px'
                                    }}
                                  >
                                    <b >{`${item.status}`}</b>
                                  </Button>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <b>{`${item.customer.name}`}</b>

                                  <span
                                    style={{ float: "right" }}
                                  ><b>{`${isEmpty(item.tableId)?"":item.tableId.tableName}`}</b></span>
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col>{isEmpty(item.tableId)?"":item.tableId.tableName}</Col>
                              </Row> */}
                              {/* <Row style={{backgroundColor:'silver',marginTop:'2%',marginBottom:'2%', marginLeft:'2%',marginRight:'2%',paddingTop:'3px',paddingBottom:'3px'}}>
                              <Col>
                              {"test"}
                              <span style={{float:'right'}}><PrintIcon/></span>
                              </Col>
                            </Row> */}

                              {item.items.map((item1, i1) => {

                                return (
                                  <Card style={{marginTop:'3px',height:'auto',backgroundColor:item.isEdit==true&&item1.itemStatus=="Pending"&&"#c1c1c1", }}>
                                    <CardContent >
                                      <Typography style={{marginTop:'-5px',marginBottom:'-20px'}}>
                                        <React.Fragment key={i1}>
                                        {item1.product.productTypeName=="Deal"?
                                                     <Accordion>
                  {/* <Card style={{ marginTop: 10 }}> */}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                    <b>  {item1.quantity}</b> X {item1.productLabel}
                      </Accordion.Header>
                      <Accordion.Body>
                      {item1.product.variation.map((item2, i2) => {
                                return (
                                  <React.Fragment key={i2}>
                                   <p>{item2.ProductLabel}{" "}-{" "}{item2.ProductVariationLabel}<b>{item2.quantity}</b> 
                                  
                                   </p>
                                  </React.Fragment>
                                )})}
                      </Accordion.Body>
                    </Accordion.Item>
                  {/* </Card> */}
                </Accordion>
                                        :
                                       <Row>
                                        <Col xl="10" lg="10" md="10" sm="10">{item1.productLabel}
                                            {item1.productLabel==item1.productVariationLabel?"":
                                           " "+"-"+" "+item1.productVariationLabel}
                                            <br/>
                                          <span style={{fontSize:'12px'}}>{item1.comment}</span>
                                   </Col>
                                        <Col xl="2" lg="2" md="2" sm="2">
                                        
                                           <b>
                                        {item1.quantity}</b>
                                        </Col>
                                       </Row>
                                        // <p style={{paddingLeft:"8px",paddingRight:"8px",marginBottom:'-5px',
                                        // fontSize:'14px'
                                        // }}>
                                          
                                        //    {item1.productLabel}
                                        //    {item1.productLabel==item1.productVariationLabel?"":
                                        //   " "+"-"+" "+item1.productVariationLabel}
                                        //   <b>
                                        //  {item1.quantity}</b>
                                            
                                        //   <br/>
                                        //   <span style={{fontSize:'12px'}}>{item1.comment}</span>
                                        //   </p>
                                }
                             
                                          {/* <p
                                  style={{
                                  
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    borderBottom: "2px solid silver",
                                    paddingBottom: "2px",
                                  }}
                                >
                                  <b>{item1.quantity}</b> X {item1.productLabel}
                                </p> */}
                                        </React.Fragment>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                );
                              })}
                            </Typography>
                            <Row style={{ marginTop: "5%" }}>
                              {/* <Col xl="3" lg="3" md="3" sm="3" ></Col> */}
                              <Col
                                xl="3"
                                lg="3"
                                md="3"
                                sm="3"
                                style={{ marginLeft: "2%" }}
                              >
                                <Button
                                  disabled={
                                    item.status == "Pending" ? false : true
                                  }
                                  onClick={() => {
                                    props.updateStatus(
                                      item,
                                      "Cooking",
                                      "",
                                      "",
                                      handleVisible,
                                      handleDangerVisible
                                    );
                                    console.log(item._id, "clcik ico");
                                    props.getPendingOrder();
                                  }}
                                  style={{ border: "none", cursor: "pointer" }}
                                >
                                  Accept
                                </Button>
                              </Col>
                              <Col xl="1" lg="1" md="1" sm="1"></Col>
                              <Col
                                xl="3"
                                lg="3"
                                md="3"
                                sm="3"
                                style={{ marginLeft: "2%" }}
                              >
                                <Button
                                  disabled={
                                    item.status == "Cooking" ? false : true
                                  }
                                  onClick={() => {
                                    props.updateStatus(
                                      item,
                                      "Served",
                                      "",
                                      "",
                                      handleVisible,
                                      handleDangerVisible
                                    );
                                    console.log(item._id, "clcik ico");
                                    props.getPendingOrder();
                                  }}
                                  style={{ border: "none", cursor: "pointer" }}
                                >
                                  Ready
                                </Button>
                              </Col>
                              <Col xl="1" lg="1" md="1" sm="1"></Col>
                              <Col
                                xl="3"
                                lg="3"
                                md="2"
                                sm="2"
                                style={{ marginLeft: "2%" }}
                              >
                                <Button>
                                  <span
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <PrintIcon
                                      onClick={() => {
                                        props.getOrderPrint(item._id);
                                      }}
                                    />
                                  </span>
                                </Button>
                              </Col>
                            </Row>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Col>
                  </React.Fragment>
                );
              })
            )}
          </Row>
        </div>
      )}
    </Container>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    getPendingOrder: () => dispatch(getPendingOrder()),
    updateStatus: (data, status,a,b, handleVisible, handleDangerVisible) =>
      dispatch(updateStatus(data, status,a,b, handleVisible, handleDangerVisible)),
    getOrderPrint: (id) => dispatch(getOrderPrintReport(id)),
    searchOrder: (data) => dispatch(filterOrderKds(data)),
     searchOrderbyTable:(data)=>dispatch(filterOrderByTableKds(data)),
  };

};
const mapStateToProps = (state, ownProps) => ({
  PendingOrderList: state.KDSReducer.PendingOrderList,
  isGettingPendingOrder: state.KDSReducer.isGettingPendingOrder,
  ordersList: state.POSViewReducer.ordersList,
  SearchPendingOrderList:state.KDSReducer.SearchPendingOrderList,
});

export default connect(mapStateToProps, mapDispatchToProps)(KDSView);
