import API from "../../../../global/api";
import { get, isEmpty } from "lodash";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
import { sumBy } from "lodash";
// import BalanceSheetDetail from "../BalanceSheet/BalanceSheetDetail";

export const requestAddAging = () => {
  return {
    type: "REQUEST_ADD_AGING",
  };
};
export const successAddAging = (data) => {
  return {
    type: "SUCCESS_ADD_AGING",
    payload: data,
  };
};
export const errorAddAging = () => {
  return {
    type: "ERROR_ADD_AGING",
  };
};

export const agingReport = (data, id, ACtitle) => {
  return (dispatch) => {
    dispatch(requestAddAging());
    API.post(`/reports/aging_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "AGING Report";
        
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;

        console.log(getData.restaurantImage,"image")
        const logo1=getData.restaurantImage;
        const code = `Code: ${id}`;
        const TitleOfAC = `Title Of A/C: ${ACtitle}`;
        const headers = [
          [
            "Last Paid Amount",
            "Last Paid On",
            "Balance",
            `    1-15\n${getData.duration[0].endDate}\n${getData.duration[0].startedDate}`,
            `    16-30\n${getData.duration[1].endDate}\n${getData.duration[1].startedDate}`,
            `    31-45\n${getData.duration[2].endDate}\n${getData.duration[2].startedDate}`,
            `    46-60\n${getData.duration[3].endDate}\n${getData.duration[3].startedDate}`,
            `    61-75\n${getData.duration[4].endDate}\n${getData.duration[4].startedDate}`,
            `    76-90\n${getData.duration[5].endDate}\n${getData.duration[5].startedDate}`,
            `   90-105\n${getData.duration[6].endDate}\n${getData.duration[6].startedDate}`,
            `   105-120\n${getData.duration[7].endDate}\n${getData.duration[7].startedDate}`,
          ],
        ];
        // const data = getData.ledger_history.map((elt) => ["last","Bal",elt[0],elt[1].elt[2].elt[3],elt[4],elt[5],elt[6],elt[7]])
        const data = [
          [
            getData.lastDebit,
            getData.lastDebitDate,
            "Balance",
            `${getData.ledger_history[0]}`,
            `${getData.ledger_history[1]}`,
            `${getData.ledger_history[2]}`,
            `${getData.ledger_history[3]}`,
            `${getData.ledger_history[4]}`,
            `${getData.ledger_history[5]}`,
            `${getData.ledger_history[6]}`,
            `${getData.ledger_history[7]}`,
          ],
        ];
        console.log(
          getData.ledger_history[0] +
            getData.ledger_history[1] +
            getData.ledger_history[2] +
            getData.ledger_history[3] +
            getData.ledger_history[4] +
            getData.ledger_history[5] +
            getData.ledger_history[6] +
            getData.ledger_history[7],
          "aaaaaaaaaaaaaaaaaaaaaa"
        );
        const grandTotal = `Grand Total: ${sumBy(getData.ledger_history)}`;
        //  const categoryTotal =`Category Total: `;
        // const headersSigned = [["Prepared By:", "Approved By:", "Audited By:", "Recieved By:"]];
        let content = {
          startY: 200,
          startX: 0,
          head: headers,
          body: data,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont(undefined, "bold");
        doc.text(title, 390, 120);
        doc.setFontSize(12);
        doc.setFont(undefined, "normal");
        // doc.text(code, 40, 140);
        doc.setFontSize(14);
        doc.setFont(undefined, "bold");
        doc.text(TitleOfAC, 340, 160);
        doc.setFontSize(12);
        doc.setFont(undefined, "normal");
        doc.autoTable(content);
        // doc.text(categoryTotal,570,doc.lastAutoTable.finalY + 20)
        doc.text(grandTotal, 670, doc.lastAutoTable.finalY + 40);
        // doc.text(`Total Quantity:${Total}`,370,doc.lastAutoTable.finalY + 10)
        // let contentSign = {
        //     startY: doc.lastAutoTable.finalY + 30,
        //     startX: 5,
        //     head: headersSigned,
        //     0: { cellWidth: 130 },
        //     1: { cellWidth: 130 },
        //     2: { cellWidth: 130 },
        //     3: { cellWidth: 130 },
        //     body: [[{ content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }]],
        //     theme: 'grid',
        //     headStyles: {
        //         fillColor: '#2980ba'
        //     },

        //     bodyStyles: {
        //         minCellHeight: 300
        //     }
        // };
        // doc.autoTable(contentSign);
        doc.save(`Aging Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
        dispatch(successAddAging(res.data));
      })
      .catch((error) => {
        dispatch(errorAddAging());
      });
  };
};
// export const addAging1 = (data) => {
//     return (dispatch) => {
//         dispatch(requestAddAging());
//         API.post(`/levelFive/get-agng-data`, data).then((res) => {
// let resData = res.data;
//             if(isEmpty(resData)){handleVisible()}
//            // isEmpty(resData) && handleVisible()
//            else{
//             const unit = "pt";
//             const size = "A4";
//             const orientation = "potrait";

//             const marginLeft = 40;
//             const doc = new jsPDF(orientation, unit, size);

//             doc.setFontSize(11);
//             const title = "GRN Report";
//             const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
//             const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
//             const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
//             const headers = [["Product NAME","QUANTITY"]];
//             const data = resData.map((elt) => [elt.inventory.productName, elt.total_quantity])
//             const Total =sumBy(resData,'total_quantity')
//             const headersSigned = [["Prepared By:", "Approved By:", "Audited By:", "Recieved By:"]];
//             let content = {
//                 startY: 200,
//                 startX: 10,
//                 head: headers,
//                 body: data
//             };
//             doc.addImage(logo, 'PNG', 40, 15, 100, 100);
//             doc.text(companyName, 150, 40, 0, 20);
//             doc.setFontSize(13);
//             doc.setFont('TimesNewRoman', 'Normal')
//             doc.text(companyAddress, 150, 60, 0, 20);
//             doc.text(companyNtn, 150, 80, 0, 20);
//             doc.setFontSize(14);
//             doc.setFont(undefined, 'bold')
//             doc.text(title, 240, 120);
//             doc.setFontSize(12);
//             doc.setFont(undefined, 'normal')
//              doc.text(`From ${startDate} to ${endDate}`, 220, 140);
//             doc.autoTable(content);
//             doc.text(`Total Quantity:${Total}`,370,doc.lastAutoTable.finalY + 10)
//             let contentSign = {
//                 startY: doc.lastAutoTable.finalY + 30,
//                 startX: 5,
//                 head: headersSigned,
//                 0: { cellWidth: 130 },
//                 1: { cellWidth: 130 },
//                 2: { cellWidth: 130 },
//                 3: { cellWidth: 130 },
//                 body: [[{ content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }]],
//                 theme: 'grid',
//                 headStyles: {
//                     fillColor: '#2980ba'
//                 },

//                 bodyStyles: {
//                     minCellHeight: 300
//                 }
//             };
//             doc.autoTable(contentSign);
//             doc.save(`ReceivingReport ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)}
//             dispatch(successAddAging(res.data));
//         }).catch((error) => {
//             dispatch(errorAddAging());
//         })
//     };
// };
