import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs,Breadcrumb, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import Select from 'react-select';
import Swal from 'sweetalert';
import moment from "moment";
import jsPDF from "jspdf";
import ClearIcon from '@mui/icons-material/Clear';


import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { getMonthWiseDiscountReport } from "./Report.action";
import IconMenu from "../../../drawer/IconMenu";
const MonthWiseDiscountReport = (props) => {


    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };


    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Issuance Added Successfully",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error",
            button: "Ok",
        });
    }

    const [state, setState] = React.useState(
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const exportPDF = () => {
        props.getMonthWiseDiscountReport({ startDate: startDate, endDate: endDate },
          handleDangerVisible)
    }
    console.log(props.monthlyDiscountReportData, "ddddddddd")

    useEffect(() => {
    }, []);
    return (
        <>
          <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Date Wise Discount Report</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
            {props.isFetchingMonthlyDiscountReport ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
          
                    <div className="main">
                    <Row>
              <Breadcrumb>
              <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Date Wise Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
                        <Row>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className="sendDiv" style={{ marginTop: '2%' }}>
                            {isUndefined(startDate) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", border: "1px solid" }}>Generate Discounts Report</Button> : props.isFetchingMonthlyDiscountReport ? <Button>Downloading PDF</Button> : <Button onClick={() => exportPDF()}>Generate Discounts Report</Button>}
                        </div>
                       
                    </div>

                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMonthWiseDiscountReport: (data,handleDangerVisible) => dispatch(getMonthWiseDiscountReport(data,handleDangerVisible)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingMonthlyDiscountReport: state.ReportReducer.isFetchingMonthlyDiscountReport,
    monthlyDiscountReportData: state.ReportReducer.monthlyDiscountReportData,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthWiseDiscountReport);