import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
//import { addExpense, getExpense, deleteExpense,getBooking, getMasterControl,/*UpdateHRExpense */
//SearchMarqueeExpenseReport,
//getBookingById} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { SearchGenerateReport } from "./ProfitLoss.action";
import { setHeading } from "../../accounts/account.actions";


const ProfitLossStatement = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [openingBalance, setOpeningBalance] = useState();
  const [closingBalance, setClosingBalance] = useState();
  function reportGenerate() {
    props.SearchGenerateReport(openingBalance, closingBalance);
  }
  useEffect(() => {
    props.setHeading("Profit Loss")
  }, []);
  return (
    <>
      {props.isFetchingExpenseData || props.isAddingExpenseData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="6" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="6" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Opening balance :</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Opening balance...."
                    onChange={(e) => setOpeningBalance(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Closeing balance :</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Closeing balance...."
                    onChange={(e) => setClosingBalance(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <div className="sendDiv">
                <Button
                  onClick={() => {
                    reportGenerate();
                  }}
                >
                  Generate Report
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    SearchGenerateReport: (openingBalance, closingBalance) =>
      dispatch(SearchGenerateReport(openingBalance, closingBalance)),
      setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitLossStatement);
