import * as React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routing from "../src/routes/routes";
import { Store } from "./store";
import { socket } from "./socket";
import { Offline, Online } from "react-detect-offline";
import NoInternet from "./views/user_views/forms/AddDetails/NoInternet";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
function App() {
  const [isConnected, setIsConnected] = React.useState(socket.connected);
  const [fooEvents, setFooEvents] = React.useState([]);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  React.useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value) {
      setFooEvents((previous) => [...previous, value]);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, []);
  React.useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);
  return (
    <div className="App">
      <Store>
        {/* {isOnline ? ( */}
          <Router>
            <Routing />
          </Router>
        {/* ) : (
          <NoInternet/>
        )} */}
      </Store>
    </div>
  );
}

export { App };
