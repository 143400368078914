import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../add-details.css";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, sumBy, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import useScanDetection from 'use-scan-detection';
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Chip } from "@mui/material";
import { cleanFilterItem } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import InputGroup from "react-bootstrap/InputGroup";
import { SettingsRemote } from "@material-ui/icons";
import { baseURL } from "../../../../../global/api";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {
  AddOrders,
  deleteOrders,
  getCustomersData,
  getCustomersDataWithoutPagination,
  GetOrderById,
  getOrders,
  getOrderTypes,
  getTables,
  OrderEditRoute,
  updateOrders,
} from "./POSView.action";
import { getCoupons } from "../AddCoupons/Coupons.action";
import { getCustomerData } from "../AddCustomer/Customer.action";
import { getProductData } from "../AddProduct/Product.action";
import { getCategoryData } from "../categories/Catagory.action";
import {
  filterItem,
  filterItemByMenu,
  getProductType,
  getServiceAndHybridProduct,
} from "../Recipe/Recipe.action";
import { getProductTypeById } from "../Recipe/Recipe.action";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { getPendingOrder, updateStatus } from "../KDS/KDSView.action";
import IconMenu from "../../../drawer/IconMenu";
import { getRestaurantData } from "../AddResturant/Resturant.action";

import { getUserRes } from "../../AddUser/user.actions";

const AddBillingPos = (props) => {
  const { state } = useLocation();
  console.log(state, "vvvvvvvvvvvvv");
  const [issuanceType, setIssuanceType] = useState("Full");
  const [issuanceTypeLabel, setIssuanceTypeLabel] = useState("Full");

  const [inputField, setInputField] = useState([]);
  const [inputFielddumy, setInputFieldDumy] = useState([]);
  const [categoryId, setcategoryId] = useState();
  const [discountId, setDiscountId] = useState();
  const [discountname, setDiscountname] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState("Manual");
  const [customer, setCustomer] = useState("");
  const [customerLabel, setCustomerLabel] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponLabel, setCouponLabel] = useState("");
  const [table, setTable] = useState("");
  const [tableLabel, setTableLabel] = useState("");
  const [orderType, setOrderType] = useState("1");
  const [orderTypeLabel, setOrderTypeLabel] = useState("DINEIN");
  const [totalAmount, setTotalAmount] = useState(0);
  const [SubTotalAmount, setSubTotalAmount] = useState(totalAmount);
  const [taxAmount, setTaxAmount] = useState(0);
  const [afterTaxTotalAmount, setAfterTaxTotalAmount] =
    useState(SubTotalAmount);
  const [amount, setAmount] = useState("");
  const [productId, setProductId] = useState("");
  const [productLabel, setProductLabel] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [dangerVisibleAlertCoupon, setDangerVisibleAlertCoupon] = useState(false);
  const [dangerVisibleAlertQuantity, setDangerVisibleAlertQuantity] = useState(false);
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] = useState(false);
  const [categoryId1, setcategoryId1] = useState();
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [waiterId, setWaiterId] = useState("");
  const [waiterName, setWaiterName] = useState("");
  const [People, setPeople] = useState(isEmpty(state)?0:state.numberOfPeople);
  const [disableProduct, setDisableProduct] = useState(false);
  const [orderTypeId, setOrderTypeId] = useState(
    isEmpty(props.seatSelected._id) ? 2 : 1
  );
  const [orderTypeName, setOrderTypeName] = useState(
    isEmpty(state) ? "Take Away" : "Dine In"
  );
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [taxLabel, setTax] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dumyitem,setDumyItem]=useState("")
  const [show1, setShow1] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
 const [customerModal, setCustomerModal] = useState(false); 
  const handleClose1 = () => {
    setShow1(false)
    setCard(0);
    setCash(0);
  
  };

  const [CommentModal, setCommentModalModal] = useState(false);
  const handleCloseComment = () => setCommentModalModal(false);
  const handleShowComment  = (item) => {
    console.log(item,"testitemmmmmmmmmmm")
    setDumyItem(item)
    setCommentModalModal(true)
  
  };
  const [orderComment, setOrderComment] = useState("");
  const [orderCommentModal, setOrderCommentModal] = useState(false);
  const handleCloseOrderComment = () => setOrderCommentModal(false);
  const handleShowOrderComment  = (item) => {
    setOrderCommentModal(true)
  
  };

  const handleShow1 = () => setShow1(true);
  const handleShowCoupon = () => setCouponModal(true);
  const handleCloseCoupon = () => setCouponModal(false);
  const handleShowCustomer = () => setCustomerModal(true);
  const handleCloseCustomer = () => setCustomerModal(false);
  const [barcode, setBarocode] = useState("");
const [cardId,setCardId]=useState("");
const [cardName,setCardName]=useState("");
  const handleClickCategory = (item) => {
    setCardId(item.value)
    setCardName(item.label)
    // setcategoryId1(selectedOption.value);
    // setcategoryId(selectedOption.label);
    // console.log(selectedOption.value, "valueeeeeeeeeeee");
    item.value == "All"
      ? props.getProduct()
      : 
      props.serchByMenu(item.value);
  };
  const handleChangeCategory = (selectedOption) => {
    setcategoryId1(selectedOption.value);
    setcategoryId(selectedOption.label);
    console.log(selectedOption.value, "valueeeeeeeeeeee");
    selectedOption.value == "All"
      ? props.getProduct()
      : 
      props.serchByMenu(selectedOption.value);
  };

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      // button: "Ok",
      buttons: {
        roll: {
          text: "Go To Order View",
          value: "OV",
        },
        roll2: {
          text: "Ok",
          value: "ok",
        },
      },
    }).then(function (x) {
      if (x == "OV") {
        navigate("/order");
      }
      else if(x=="ok"){
       isEmpty(state)?navigate("/pos"): navigate("/table");
      }
    });
  };
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType,permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName)?true:false
   }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleDangerVisibleCopon = () => {
    setDangerVisibleAlertCoupon(true);
    setTimeout(() => {
      setDangerVisibleAlertCoupon(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Total Order Amount Is Less Than Paid Amount, Please Change The Coupon",
      icon: "error",
      button: "Ok",
    });
  };
  const handleDangerVisibleQuantity = () => {
    setDangerVisibleAlertQuantity(true);
    setTimeout(() => {
      setDangerVisibleAlertQuantity(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "low Quantity",
      icon: "info",
      button: "Ok",
    });
  };
  const handleDangerVisibleProduct = () => {
    setDangerVisibleAlertProduct(true);
    setTimeout(() => {
      setDangerVisibleAlertProduct(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Product Found",
      icon: "error",
      button: "Ok",
    });
  };
  const getdata = () => {
    props.getPendingOrder();
  };
  function uploadPurchase(isPrint) {
    // console.log(inputFielddumy,"testtttttttttt")
    // let field=inputField.concat(inputFielddumy)
    // // let p=field[1].product
    // field.map((x) => {
    //   inputFielddumy.map((z)=>{
    //     console.log(x,z,"lllllllllllllllllllllll")
    //   if(x.product==z.product){
    //     console.log(x,"filedddddddddddddddddd")
    //   }})
    //     })
    props.addOrders(
      {
        isPrint,
        items: inputField,
        grandTotal: parseInt(SubTotalAmount),
        tax: parseInt(taxAmount),
        // (Math.round(afterTaxTotalAmount*100)/100) - (Math.round(SubTotalAmount*100)/100),
        coupon: coupon,
        customer: customer,
        orderType: orderTypeName,
        tableId: isEmpty(state)?null:state.id,
        barcodeId:barcode,
        discount:couponLabel == "" ? 0 : totalAmount-SubTotalAmount,
        subTotal:totalAmount,
        waiterId:waiterId,
        numberOfPeople:People,
        orderComment:orderComment,
      },
      handleVisible,
      handleDangerVisible,
      // getdata,
    );
    isEmpty(state)?navigate("/pos"): navigate("/table");
    setCard(0);
    setCash(0);
    setOrderTypeName("");
    setAdvanceAmount(0);
    setRemainingAmount(0);
    setDiscountPercentage(0);
    setTotalAmount(0);
    setTaxAmount(0);
    setTable("");
    setTableLabel("");
    setCouponLabel("");
    setCustomerLabel("");
    setCustomer("");
    setSubTotalAmount(0);
    setInputField([]);
    setCoupon("");
    setCouponType("");
    setCouponDiscount(0);
    setAfterTaxTotalAmount(0);
    //  state=0;
    handleClose1();
  }
  // function uploadPurchasePrint(is_print) {
  //   props.addOrders(
  //     {
  //       is_print,
  //       items: inputField,
  //       grandTotal: afterTaxTotalAmount,
  //       tax: taxAmount,
  //       // (Math.round(afterTaxTotalAmount*100)/100) - (Math.round(SubTotalAmount*100)/100),
  //       coupon: coupon,
  //       customer: customer,
  //       orderType: orderTypeName,
  //       tableId: state,
  //       barcodeId:barcode,
  //     },
  //     handleVisible,
  //     handleDangerVisible,
  //     // getdata,
  //   );
  //   isEmpty(state)?navigate("/pos"): navigate("/table");
  //   setCard(0);
  //   setCash(0);
  //   setOrderTypeName("");
  //   setAdvanceAmount(0);
  //   setRemainingAmount(0);
  //   setDiscountPercentage(0);
  //   setTotalAmount(0);
  //   setTaxAmount(0);
  //   setTable("");
  //   setTableLabel("");
  //   setCouponLabel("");
  //   setCustomerLabel("");
  //   setCustomer("");
  //   setSubTotalAmount(0);
  //   setInputField([]);
  //   setCoupon("");
  //   setCouponType("");
  //   setCouponDiscount(0);
  //   setAfterTaxTotalAmount(0);
  //   //  state=0;
  //   handleClose1();
  // }
  function openPaymentMethodModal() {
    handleShow1();
  }
  // function openCouponModal() {
  //   handleShow1();
  // }
  const handleClickCoupon = (selectedOption) => {
    setSubTotalAmount(totalAmount);
    console.log(selectedOption, "click ho");
    setCoupon(selectedOption.value);
    setCouponLabel(selectedOption.label);
    setAmount(selectedOption.amount);
    setCouponType(selectedOption.type);
    let dis =
      selectedOption.type == "fixed"
        ? selectedOption.amount
        : totalAmount * selectedOption.amount * 0.01;
    setCouponDiscount(
      selectedOption.type == "fixed"
        ? selectedOption.amount
        : totalAmount * selectedOption.amount * 0.01
    );
    // console.log(selectedOption.type,"llllllll",discount)
    setSubTotalAmount(totalAmount - dis);
    handleCloseCoupon()
  };
  const handleClick = (selectedOption) => {
    console.log(selectedOption, "click ho");
    setCustomer(selectedOption.value);
    setCustomerLabel(selectedOption.label);
    // setAmount(selectedOption.amount);
    handleCloseCustomer()
  };
  const handleDelete = () => {
    console.log("delete ho");
    setCustomer("");
    setCustomerLabel("");
  };
  const handleDeleteCoupon = () => {
    console.log("delete ho");
    setCoupon("");
    setCouponLabel("");
    setCouponDiscount(0)
    setSubTotalAmount(totalAmount);
  };

  const getDiscountList = !isEmpty(props.couponsDataList)
    ? props.couponsDataList.map((x) => {
        let data = {
          value: x._id,
          label: x.name,
          type: x.type,
          amount: x.amount,
        };
        return data;
      })
    : [];
  console.log(getDiscountList, "uuuuuuuuuu");
  const getCustomersList = !isEmpty(props.customerDataList)
    ? props.customerDataList.map((x) => {
        let data = {
          value: x._id,
          label: `${x.name}`,
        };
        return data;
      })
    : [];
  console.log(getCustomersList, getDiscountList, "customer");
  const getOrderTypesList = !isEmpty(props.orderTypesList)
    ? props.orderTypesList.map((x) => {
        let data = { value: x.order_type_id, label: x.order_type_name };
        return data;
      })
    : [];
  const getTables_List = !isEmpty(props.tablesList)
    ? props.tablesList.map((x) => {
        let data = {
          value: x.restaurant_table_id,
          label: x.restaurant_table_name,
        };
        return data;
      })
    : [];

  console.log(props.orderTypesList, "props.orderTypesList pos view");
  console.log(getOrderTypesList, "getOrderTypesList pos view");

  const variationData = (item) => {
    
    const list = [...inputField];
    console.log(item,list, inputField,availableQuantity,"sssssssss1111");
    setAvailableQuantity(item.productBalance);
    setProductId(item._id);
    setProductLabel(item.name);
    if (item.productType._id == "6433dd117aad3eb039c1744f") {
      // if(isEmpty(item.variation)){
      let damy = { price: item.salePrice, label: item.name, value: item._id };
      console.log(damy, "bbbbbbb");
      insertItem(damy);
      // else{
      //   let dumyList
      //   let damy = { price: item.salePrice, label: item.name, value: item._id};
      // console.log(damy, "bbbbbbb1111");
      // isEmpty(inputFielddumy)?
      // setInputFieldDumy(item.variation):
      // // dumyList=[...inputFielddumy]
      // // console.log(dumyList,"testDeal")
      // setInputFieldDumy(inputFielddumy.concat(item.variation))
      // insertItem(damy)
      // }
    } else {
      handleShow();
      setProductId(item._id);
      setProductLabel(item.name);
      props.getProductVariation(item._id);
    }
  };
  const getVariationList = !isEmpty(props.productTypeListById)
    ? props.productTypeListById[0].variation.map((x) => {
        let data = { value: x._id, label: `${x.flavorLabel}-${x.sizeLabel}-`, price: x.sellingPrice,isRecipe:x.isRecipe};
        return data;
      })
    : [];

  const handleRemoveInput = (id) => {
    console.log(couponDiscount,"couponDiscount")
    const list = [...inputField];
    let index = list.findIndex((item) => item.productVariationId === id);
    list.splice(index, 1);
    setTotalAmount(sumBy(list, "productTotalAmount"));
    if(couponDiscount == 0){
      setSubTotalAmount( sumBy(list, "productTotalAmount"))}
         else{
          let dis =
          couponType == "fixed"
            ? amount
            : sumBy(list, "productTotalAmount") * amount * 0.01;
            setCouponDiscount(dis)
            setSubTotalAmount( sumBy(list, "productTotalAmount") - dis
           )
          }
    setInputField(list);
  };

  let getcategoryList =
    !isEmpty(props.categoryList.data) &&
    props.categoryList.data.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  !isEmpty(props.categoryList.data) &&
    getcategoryList.unshift({ value: "All", label: "ALL" });
  let ProductTypeData = [
    {
      orderTypeId: 2,
      orderTypeName: "Take Away",
    },
    {
      orderTypeId: 3,
      orderTypeName: "Delivery",
    },
    {
      orderTypeId: 4,
      orderTypeName: "Drive Throw",
    },
  ];
  const ProductTypeList =
    !isEmpty(ProductTypeData) &&
    ProductTypeData.map((x) => {
      let data = { value: x.orderTypeId, label: x.orderTypeName };
      return data;
    });

  const handleChangeOrderType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setOrderTypeName(selectedOption.label);
    setOrderTypeId(selectedOption.value);
  };
 const filterdUserList = !isEmpty(props.userList) &&props.userList.filter(e => e.role == "waiter")

  const WaiterList =
  !isEmpty(filterdUserList) &&
  filterdUserList.map((x) => {
    let data = { value: x._id, label: x.userName };
    return data;
  });
  console.log(filterdUserList,WaiterList,"ttttttttttttttttttttttttt")
  const handleChangeWaiter = (selectedOption) => {
    console.log(selectedOption, "sop");
    setWaiterName(selectedOption.label);
    setWaiterId(selectedOption.value);
  };
  const changeItemQuanity = (item, symbol) => {

    const list = [...inputField];

    console.log(item, inputField, "sssssssss");

    let index = list.findIndex(
      (itemInv) => itemInv.productVariationId === item.productVariationId
    );
    console.log(index, availableQuantity, "iiii");

    if (symbol == 1) {
       if(checkQuantity(item)==true){
        list[index]["quantity"] = Number(list[index]["quantity"]) + 1;
        list[index]["productTotalAmount"] =
          Number(list[index]["productSellingPrice"]) *
          Number(list[index]["quantity"]);
        }
          else{
            setInputField(list)
          }
    } else {
      list[index]["quantity"] =
        Number(list[index]["quantity"]) == 1
          ? Number(list[index]["quantity"])
          : Number(list[index]["quantity"]) - 1;
      list[index]["productTotalAmount"] =
        Number(list[index]["quantity"]) == 1
          ? Number(list[index]["productSellingPrice"]) * 1
          : Number(list[index]["productSellingPrice"]) *
            Number(list[index]["quantity"]);
    }
    setTotalAmount(sumBy(list, "productTotalAmount"));
    if(couponDiscount == 0){
      setSubTotalAmount( sumBy(list, "productTotalAmount"))}
         else{
          let dis =
          couponType == "fixed"
            ? amount
            : sumBy(list, "productTotalAmount") * amount * 0.01;
            setCouponDiscount(dis)
            setSubTotalAmount( sumBy(list, "productTotalAmount") - dis
           )
          }
    setInputField(list);
    
  };

  const changeComment = (e) => {

    const list = [...inputField];

    console.log(inputField,dumyitem, "sssssssss");
   let i= find(list, { product: dumyitem.product })
   i["comment"]=e
    setInputField(list);
    
  };

  const insertItem = (item, index) => {
    const list = [...inputField];
    console.log(couponDiscount,"itemdata")
    if (isEmpty(find(list, { productVariationId: item.value }))) {
      console.log(item,"item")
      let dummyItem = {};
      dummyItem["comment"]=""
      dummyItem["product"] = productId == "" ? item.value : productId;
      dummyItem["productLabel"] =
        productLabel == "" ? item.label : productLabel;
      dummyItem["productVariationId"] = item.value;
      dummyItem["productVariationLabel"] = item.label;
      dummyItem["quantity"] = 1;
      dummyItem["productSellingPrice"] = Number(item.price);
      dummyItem["productTotalAmount"] = 1 * Number(item.price);
      let listData = [...list, dummyItem];
      setTotalAmount(sumBy(listData, "productTotalAmount"));
      
       if(couponDiscount == 0){
        setSubTotalAmount( sumBy(listData, "productTotalAmount"))}
           else{
            let dis =
            couponType == "fixed"
              ? amount
              : sumBy(listData, "productTotalAmount") * amount * 0.01;
              setCouponDiscount(dis)
              setSubTotalAmount( sumBy(listData, "productTotalAmount") - dis
             )
            }
      ;

    //   if(!isEmpty(list)){
    //  let index=list.length;
    //  if(checkQuantity(dummyItem)==true){
    //  setInputField(listData);}
    //  else{
    //   setInputField(list)
    //  }}
    //  else{
      setInputField(listData)
    //  }
     
      handleClose();
      setProductId("");
      setProductLabel("");
    } else {
      let index = list.findIndex(
        (itemInv) => itemInv.productVariationId === item.value
      );
      console.log(availableQuantity, "Q2");
      let index1=list.length;
      if(checkQuantity(list[index])==true){
        list[index]["comment"]=""
        list[index]["quantity"] = Number(list[index]["quantity"]) + 1;
      list[index]["productTotalAmount"] =
        Number(list[index]["productSellingPrice"]) *
        Number(list[index]["quantity"]);
      console.log(list, "yyyyyyy");
      setTotalAmount(sumBy(list, "productTotalAmount"));
      if(couponDiscount == 0){
        setSubTotalAmount( sumBy(list, "productTotalAmount"))}
           else{
            let dis =
            couponType == "fixed"
              ? amount
              : sumBy(list, "productTotalAmount") * amount * 0.01;
              setCouponDiscount(dis)
              setSubTotalAmount( sumBy(list, "productTotalAmount") - dis
             )
            }
      handleClose();
      setProductId("");
      setProductLabel("");
      setInputField(list);
    }
      else{
       setInputField(list)
      }
    
    }
  };
  const handlePaymentType = (type) => {
    if (props.restaurantList[0].taxType == "FBRTax") {
      console.log(props.restaurantList[0],type, "jjjjjjj111");
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTax(props.restaurantList[0].fbrTaxCash);
        let taxCash =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].fbrTaxCash)) /
          100;
        setTaxAmount(taxCash);
        console.log(props.restaurantList[0], taxCash, "taxxxxxxxxxxxxx");
        setAfterTaxTotalAmount(Number(taxCash) + Number(SubTotalAmount));
      } else {
        setCard(1);
        setCash(0);
        setTax(props.restaurantList[0].fbrTaxCard);
        let taxCard =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].fbrTaxCard)) /
          100;
        setTaxAmount(taxCard);
        console.log(
          props.restaurantList[0].fbrTaxCard,
          taxCard,
          "taxxxxxxxxxxxxx"
        );
        setAfterTaxTotalAmount(Number(taxCard) + Number(SubTotalAmount));
      }
    } else {
      console.log(props.restaurantList[0],type, "jjjjjjj22");
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTax(props.restaurantList[0].praTaxCash);
        let taxCash =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].praTaxCash)) /
          100;
        console.log(props.restaurantList[0], taxCash, "taxxxxxxxxxxxxx");
        setTaxAmount(taxCash);
        setAfterTaxTotalAmount(taxCash + SubTotalAmount);
      } else {
        setCard(1);
        setCash(0);
        setTax(props.restaurantList[0].praTaxCard);
        let taxCard =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].praTaxCard)) /
          100;
        setTaxAmount(taxCard);
        console.log(
          props.restaurantList[0].praTaxCard,
          taxCard,
          "taxxxxxxxxxxxxx"
        );
        setAfterTaxTotalAmount(taxCard + SubTotalAmount);
      }
    }
  };
  const checkQuantity=(item)=>{
    console.log(item,availableQuantity,"checkQ")
    let dumyAvailableQuantity=availableQuantity==0?2:Number(availableQuantity)
    console.log(dumyAvailableQuantity,"checkQtt")
    if ( item.product == item.productVariationId && Number(item.quantity) >= dumyAvailableQuantity) {
      console.log("iftrue")
      handleDangerVisibleQuantity()
      return false;
      }
      else{
        return true;
      }
  }

  const barcod=(id)=>{
    console.log(id,props.productSearchList,"testtttttttttttttt")
  let i=  find(props.productSearchList,{barcodeId:id});
  console.log(i,"tttttttttttttttt")
  isEmpty(i)?handleDangerVisibleProduct():
  variationData(i)

  }
  useScanDetection(
    Checkpermissions("permissionsCatName","Enable Barcode")&&
    {
    onComplete: barcod,
  //  minLength: 13 // EAN13
});

  useEffect(() => {
    props.getUser()
    props.getResturant();
    props.getCoupons();
    props.getCustomer();
    props.getProduct();
    props.getCatagoery();
  }, []);

  return (
    <>
    <div >
    <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Recieve Order</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
      </div>
      {/* for loading */}
      {props.isFetchingServiceAndHybridProductList ||
      props.isFetchingUser||
      props.isFetchingCouponsData ||
      props.isFetchingCustomer ||
      props.isAddingOrders ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingOrders ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          {/* <div className="main"> */}
       <div  style={{
        padding: '4px',
        // backgroundColor: '#08c708',
        width: '100%',
        overflow: 'auto',
        whiteSpace: 'nowrap',}}>
 {/* <Button> */}
{!isEmpty(getcategoryList)
  ? getcategoryList.map((item, i) => {
      return (
        <React.Fragment key={i}>
           {/* <Card
              style={{marginTop:'1%', cursor:'pointer',height:'auto',backgroundColor:item.value==cardId?'#c1c1c1':'#81abd3' }}
              onClick={(e) => handleClickCategory(item)}
            > */}
            <button style={{display: 'inline-block',
  // color: white,
  backgroundColor:'#81abd3',
  textAlign: 'center',
  padding: '14px',
  marginRight:'10px',
  textDecoration: 'none',
  cursor:'pointer',
borderStyle:'none'}}
onClick={(e) => handleClickCategory(item)}
  >
                <b   >  {item.label}</b>
               </button>
              
            {/* </Card> */}

          </React.Fragment>
      );
    })
  : []}
  {/* </Button> */}
        {/* a ba aba ababaa baujbjwdbxejk cx cbewkjbcfec fecbfejcbf cfckihbfcj fcfjc fjc fcjfc fcfeubckjjfke cec cjhvceg cxecxehjcvxeucxe xjehvcxe cxecjvevc eceh  ce cxejjceh cxefmcjehy bc em cec ec e ceh chefbcbkefhfbbch dwx jg ssj xeewcx e cwj cxkew  cxfej cxjxdmasx edc fejh cxwdmscx ed  cjwhc xdwjjh */}
       </div>
          {/* mian Code */}
            <Row style={{overflowX:'hidden',marginTop:'10px'}}>
              {/* <Col   xl="2"
                lg="2"
                md="2"
                sm="3"
                // style={{marginLeft:'-40px'}}
                >
                   <Row
                  style={{ height: '85vh', overflow: "auto" }}
                  className="overflow-auto"
                >
                  
            {!isEmpty(getcategoryList)
                    ? getcategoryList.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Col xl="12" lg="12" md="12" sm="12">
                              <Card
                                style={{marginTop:'1%', cursor:'pointer',height:'auto',backgroundColor:item.value==cardId?'#c1c1c1':'#81abd3' }}
                                onClick={(e) => handleClickCategory(item)}
                              >
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                    component="div"
                                     style={{
                                      marginTop:'-10px',
                                      marginBottom:'-10px'
                                    //   color:
                                    //     item._id == idEdit
                                    //       ? "#008BFF"
                                    //       : "black",
                                     }}
                                  >
                                  <b>  {item.label}</b>
                                 
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Col>
                          </React.Fragment>
                        );
                      })
                    : []}</Row></Col> */}
              {/* colum for product display side */}
              <Col
                xl="7"
                lg="7"
                md="6"
                sm="6"
                style={{
                  borderRightStyle: "solid",
                  borderRightColor: "#e9ecef",
                }}
              >

               
                {/* row for filter product ,select order type,filter by menu */}
                <Row>
                  <Col xl="3" lg="3" md="6" sm="6">
                    <Form.Group>
                      <Form.Label>
                        <b>Search Items</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Items..."
                        name="receivedBy"
                        onChange={(e) => props.searchProduct(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="3" lg="3" md="6" sm="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b> Select Waiter</b>
                      </Form.Label>
                      <Select
                        value={{ label: waiterName }}
                        // isDisabled={
                        //   isEmpty(props.seatSelected._id) ? false : true
                        // }
                        placeholder="Select Waiter..."
                        onChange={(e) => handleChangeWaiter(e)}
                        options={WaiterList}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="3" lg="3" md="6" sm="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b> Select Menu</b>
                      </Form.Label>
                      <Select
                        value={{ label: categoryId }}
                        placeholder="Select Category..."
                        onChange={(e) => handleChangeCategory(e)}
                        options={
                          isEmpty(getcategoryList) ? [] : getcategoryList
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="3" lg="3" md="6" sm="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Order Type</b>
                      </Form.Label>
                      <Select
                        value={{ label: orderTypeName }}
                        isDisabled={
                          isEmpty(props.seatSelected._id) ? false : true
                        }
                        placeholder="Select Order Type..."
                        onChange={(e) => handleChangeOrderType(e)}
                        options={ProductTypeList}
                      />
                    </Form.Group>
                  </Col>
                 
                  {/* <Col xl="3" lg="3" md="6" sm="6">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Bar Code Scaner</b></Form.Label>
                                    <Form.Control type="number" placeholder="Bar Code number...." value={barcode} disabled onBlur={(e) => setBarocode(e.target.value)} />
                                </Form.Group>
                            </Col> */}
                </Row>
                {/* <Row>
             
                  <Col xl="3" lg="3" md="3" xs="3">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>No of People :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={People}
                        placeholder="No of People...."
                        onChange={(e) => setPeople(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                {/* Row for product display */}
                {/* height 375 */}
                {cardName!=""&&
<Row><Col>
<Card style={{height:'50px',backgroundColor:'#81abd3',marginBottom:'5px'}}>
                            <CardActionArea >
                          
                              <CardContent>
                                
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                    component="div"
                                    style={{fontSize:'12px'}}
                                  >
                                 <p style={{textAlign:'center',fontSize:'16px'}}>  <b >{cardName}</b></p>
                                  </Typography>
                                
                              </CardContent>
                            </CardActionArea>
                          </Card></Col></Row>}
                <Row   style={{ height: props.productSearchList.length>23?'80vh': 'auto', overflow: "auto" }}
                  className="overflow-auto" >
                {/* <p style={{textAlign:'center'}}><b>{cardName}</b></p> */}

                
                  {props.productSearchList.map((item, i) => {
                       console.log(item, i, "today");
                    return (
                      <React.Fragment key={i}>
                        <Col
                          xl="3"
                          lg="3"
                          md="6"
                          sm="6"
                          style={{ marginBottom: 20 }}
                        
                        >
                          <Card style={{height:'170px',backgroundColor:'#c1c1c1'}} sx={{ opacity:item.productType._id=="6433dd117aad3eb039c1744f"&& item.productBalance<=0 ? 0.5 : 1,pointerEvents:item.productType._id=="6433dd117aad3eb039c1744f"&& item.productBalance<=0 ? "none" : "auto"}} onClick={(e) => variationData(item)} >
                            <CardActionArea >
                               <CardMedia
                                component="img"
                                height="120px"
                                width="100px"
                                image={`${item.images}`}
                                alt="Product"
                               
                              /> 
                              <CardContent
                              // style={{marginBottom:'15px'}}
                              >
                                {!isEmpty(
                                  props.serviceAndHybridProductList
                                ) && (
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                    component="div"
                                    style={{fontSize:'12px'}}
                                  >
                                   <b> {item.name}</b>
                                  </Typography>
                                )}
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </Col>
              {/* colum for order detail */}
              <Col xl="5" lg="5" md="6" sm="6">
                <div>
                  {/* coupo */}
                  {Checkpermissions("permissionsCatName","Assign Coupan")&&
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Coupons</b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl="1" lg="2" md="2" sm="4">
                      <AddCircleOutlineIcon
                        style={{ marginTop: "9px", cursor: "pointer" }}
             
                        onClick={handleShowCoupon}
                      ></AddCircleOutlineIcon>
                     
                    </Col>
                    {couponLabel == "" ? (
                      ""
                    ) : (
                      
                      <Col xl="4" lg="6" md="6" sm="12">
                        <Chip
                          color="primary"
                          label={
                            couponLabel
                          
                          }
                          onDelete={handleDeleteCoupon}
                          style={{ marginTop: "5px", marginLeft: "-10px" }}
                        ></Chip>
                      </Col>
                    )}
                    
                  </Row>}
                  {/* customer */}
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Customer</b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl="1" lg="2" md="2" sm="4">
                      <AddCircleOutlineIcon
                        style={{ marginTop: "9px", cursor: "pointer" }}
                        onClick={handleShowCustomer}
                      ></AddCircleOutlineIcon>
                    </Col>
                    {customerLabel == "" ? (
                      ""
                    ) : (
                      <Col xl="4" lg="6" md="6" sm="12">
                        <Chip
                          color="primary"
                          label={
                            customerLabel
                          }
                          onDelete={handleDelete}
                        ></Chip>
                      </Col>
                    )}
                    
                  </Row>
                </div>
               
                <Row>
                <Col xl="1" lg="1" md="2" sm="6">
                    <h6>
                      <b>Sr </b>
                    </h6>
                  </Col>
                  <Col xl="5" lg="5" md="5" sm="6">
                    <h6>
                      <b>Product</b>
                    </h6>
                  </Col>
                  {/* <Col xl="3" lg="3" md="3" sm="3">
                    <h6>
                      <b>Product</b>
                    </h6>
                  </Col> */}
                  <Col xl="3" lg="3" md="3" sm="6" style={{marginLeft:'3%'}}>
                    <h6>
                      <b>Qty</b>
                    </h6>
                  </Col>
                  <Col xl="3" lg="3" md="2" sm="6" style={{marginLeft:'-5%'}}>
                    <h6>
                      <b>Total</b>
                    </h6>
                  </Col>
                </Row>
                  {/* items list */}
                <Row
                // class="border-bottom"
                  style={{
                    height: '355px',
                    maxHeight:'355px',
                    overflowY: "auto",
                    overflowX: "hidden",
                    marginRight:'-30px'
                  }}
                >
                  <Col xl="12" lg="12" md="12" sm="12" >
                  {!isEmpty(inputField) &&
                    inputField.map((item, i) => {
                      console.log(item, "ttttttt");
                      return (
                        <Row
                          style={{
                            // backgroundColor: !(i % 2 == 0)
                              // ? "#f1f4f5"
                              // : "white",
                            // border: 5,
                            // fontSize:9,
                            
                          }}
                        >
                          <Col xl="1" lg="1" md="1" sm="3">
                          <Form.Group className="mb-1 pt-2">
                              <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px',backgroundColor:'#81abd3',fontWeight:'bold'}}
                                placeholder={`${i+1}`}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="5" lg="5" md="5" sm="9" >
                            <Form.Group className="mb-1 pt-2">
                              <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                                placeholder={`${item.productLabel} - ${item.productVariationLabel}`}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="3" lg="3" md="3" sm="6" >
                            <InputGroup className="mb-1 pt-2 pt-2">
                              <RemoveCircleOutlineOutlinedIcon
                                style={{ marginTop: "8px", cursor: "pointer" }}
                                onClick={() => changeItemQuanity(item, 0)}
                              ></RemoveCircleOutlineOutlinedIcon>
                              <Form.Control
                                // placeholder={item.quantity}
                                value={item.quantity}
                                aria-label="Product Name"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                          //  onChange={checkQuantity(item)}
                              />
                              <AddCircleOutlineIcon
                                style={{ marginTop: "8px", cursor: "pointer" }}
                                onClick={() => changeItemQuanity(item, 1)}
                              ></AddCircleOutlineIcon>
                            </InputGroup>
                          </Col>
                        
                          <Col xl="3" lg="3" md="3" sm="6" style={{marginLeft:'-10px'}}>
                            <InputGroup className="mb-1 pt-2">
                              <Form.Control
                                placeholder={item.productTotalAmount}
                                aria-label="Total Amount"
                                disabled
                                style={{fontSize:11,padding:'1px',}}
                              />

                              <RemoveCircleOutlineOutlinedIcon
                                style={{ cursor: "pointer" }}
                                // className="ml-3 mb-3 mt-2"
                                onClick={() =>
                                  handleRemoveInput(item.productVariationId)
                                }
                              />
                              
                              <ChatBubbleOutlineIcon
                                style={{ cursor: "pointer" }}
                                // className="ml-3 mb-3 mt-2"
                                onClick={() =>
                                  handleShowComment(item)
                                }
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      );
                    })}
                    </Col>
                </Row>
                {/* <Row ><p style={{borderBottom:'5px',borderBottomColor:'red',width:'100%'}}></p></Row> */}
                {/* totals */}
                <Row style={{marginTop:'10px',paddingBottom:'10px',backgroundColor:'#81abd3'}}>
                  <Col xl="4" lg="4" md="4" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Grand Total</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={totalAmount}
                        disabled
                        name="receivedBy"
                        onChange={(e) => props.filterItem(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col  xl="4" lg="4" md="4" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Discount</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          couponLabel == "" ? 0 : totalAmount-SubTotalAmount
                        }
                        disabled
                        name="receivedBy"
                        onChange={(e) => props.filterItem(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Total Bill</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          couponLabel == "" ? totalAmount : SubTotalAmount
                        }
                        disabled
                        name="receivedBy"
                        onChange={(e) => props.filterItem(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col xl="3" lg="3" md="12" sm="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Issuance Type</b>
                      </Form.Label>
                      <Select
                        value={{
                          value: issuanceType,
                          label: issuanceTypeLabel,
                        }}
                        placeholder="Select Issuance Type..."
                        name="issuanceType"
                        onChange={(e) => handleChangeIssuanceType(e)}
                        options={issuanceTypeList}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
                {/* save buttons */}
                <Row>
                  <Col  xl="2"
                      lg="2"
                      md="2"
                      sm="2"
                      className="d-flex justify-content-center mt-1">
                        <Button
                        style={{backgroundColor:'#81abd3' }}>
                  <ChatBubbleOutlineIcon
                                style={{ cursor: "pointer" }}
                                // className="ml-3 mb-3 mt-2"
                                onClick={() =>
                                  handleShowOrderComment()
                                }
                              />
                              </Button></Col>
                  <Col
                      xl="5"
                      lg="5"
                      md="5"
                      sm="5"
                    className="d-flex justify-content-center mt-1"
                  >
                    <Button
                      disabled={isEmpty(inputField)  ? true : false}
                      onClick={() => SubTotalAmount<0?handleDangerVisibleCopon(): uploadPurchase(0)}
                      style={{ marginRight: 20,backgroundColor:'#81abd3' }}
                    >
                      Save
                    </Button>
                  </Col>
                  {/* {isEmpty(state)&& */}
                  <Col
                    xl="5"
                    lg="5"
                    md="5"
                    sm="5"
                    className="d-flex justify-content-center mt-1"
                  >
                    <Button
                      disabled={isEmpty(inputField)  ? true : false}
                      onClick={() => SubTotalAmount<0?handleDangerVisibleCopon(): uploadPurchase(1)}
                      style={{ marginRight: 20,backgroundColor:'#81abd3' }}
                    >
                      Save & Print
                    </Button>
                  </Col>
                   {/* } */}
                </Row>
              </Col>
            </Row>
            {/* variation modal */}

            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Variation</Modal.Title>
              </Modal.Header>
              {props.isFetchingProductTypeById ? (
                <div
                  className="loader-div "
                  style={{ margin: "10%", marginLeft: "40%" }}
                >
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading
                    {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                  </Button>
                </div>
              ) : (
                <Modal.Body>
                  {" "}
                  {getVariationList.map((x, i) => {
                       console.log(x, i, "llllllllll");

                    return (
                      <React.Fragment key={i}>
                        <Card /*sx={{ opacity:x.isRecipe==false ? 0.5 : 1,pointerEvents:x.isRecipe==false ? "none" : "auto"}}*/
                          style={{
                            marginLeft: "1%",
                            marginRight: "1%",
                            marginBottom: "2%",
                            backgroundColor: "#1976d2",
                          }}
                          onClick={(e) => insertItem(x, i)}
                        >
                          <CardActionArea>
                            <CardContent>
                              {!isEmpty(getVariationList) && (
                                <Typography
                                  gutterBottom
                                  variant="body3"
                                  mb="5%"
                                  component="div"
                                  // style={{marginBottom:'1%'}}
                                >
                                  <b style={{ color: "white" }}>
                                    <span
                                      style={{
                                        float: "left",
                                      }}
                                    >
                                      {x.label}
                                    </span>
                                    <span
                                      style={{
                                        float: "right",
                                      }}
                                    >
                                      {x.price}
                                    </span>
                                  </b>
                                </Typography>
                              )}
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </React.Fragment>
                    );
                  })}
                </Modal.Body>
              )}
            </Modal>
            {/* modal for cash or card payment */}
            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Payment method</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="4">
                    {" "}
                  </Col>
                  <Col xl="2" lg="2" md="2" sm="2">
                    {" "}
                    <Chip
                      color="primary"
                      label={"Cash"}
                      onClick={(e) => handlePaymentType("cash")}
                    ></Chip>
                  </Col>
                  <Col xl="2" lg="2" md="2" sm="2">
                    {" "}
                    <Chip
                      color="primary"
                      label={"Card"}
                      onClick={(e) => handlePaymentType("card")}
                    ></Chip>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4">
                    {" "}
                  </Col>
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>Total Amount</span>
                    <span style={{ float: "right" }}>{totalAmount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>Coupon:({couponLabel})</span>
                    <span style={{ float: "right" }}>{couponDiscount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  ----------------------------------------------------------------------------
                </Row>
                <Row>
                  <Form.Label>
                    {/* <span style={{float:'left'}}>D</span> */}
                    <span style={{ float: "right" }}>{SubTotalAmount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>
                      Tax({taxLabel}%) on{" "}
                      {cash == 0 && card == 0
                        ? ""
                        : cash == 1
                        ? "Cash"
                        : card == 1
                        ? "Card"
                        : ""}
                    </span>
                    <span style={{ float: "right" }}>{taxAmount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  ----------------------------------------------------------------------------
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>
                      Total Amount After Tax
                    </span>
                    <span style={{ float: "right" }}>
                      {afterTaxTotalAmount}
                    </span>
                  </Form.Label>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => uploadPurchase(0)}
                  disabled={cash == 1 || card == 1 ? false : true}
                >
                  Save
                </Button>
                <Button
                  variant="primary"
                  onClick={() => uploadPurchase(1)}
                  disabled={cash == 1 || card == 1 ? false : true}
                >
                  Save & Print
                </Button>
              </Modal.Footer>
            </Modal>
              {/* coupon modal */}

              <Modal show={couponModal} onHide={handleCloseCoupon} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Coupon</Modal.Title>
              </Modal.Header>
              {props.isFetchingCouponsData ? (
                <div
                  className="loader-div "
                  style={{ margin: "10%", marginLeft: "40%" }}
                >
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading
                    {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                  </Button>
                </div>
              ) : (
                <Modal.Body>
                  {" "}
                  {getDiscountList.map((x, i) => {
                                //     console.log(x, i, "llllllllll");
                                return (
                                  <React.Fragment key={i}>
                                    <Chip
                                      style={{ margin: "3px" }}
                                      label={x.label}
                                      color="primary"
                                      onClick={(e) => handleClickCoupon(x)}
                                      // onDelete={handleDelete}
                                    />
                                  </React.Fragment>
                                );
                              })}
                </Modal.Body>
              )}
            </Modal>
            {/* customer modal */}

            <Modal show={customerModal} onHide={handleCloseCustomer} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Customer</Modal.Title>
              </Modal.Header>
              {props.isFetchingCustomer ? (
                <div
                  className="loader-div "
                  style={{ margin: "10%", marginLeft: "40%" }}
                >
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading
                    {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                  </Button>
                </div>
              ) : (
                <Modal.Body>
                  {" "}
                  {getCustomersList.map((x, i) => {
                                //     console.log(x, i, "llllllllll");
                                return (
                                  <React.Fragment key={i}>
                                    <Chip
                                      style={{ margin: "3px" }}
                                      color="primary"
                                      label={x.label}
                                      onClick={(e) => handleClick(x)} //(e) => handleChangeDiscount(e)
                                      // onDelete={handleDelete}
                                    />
                                  </React.Fragment>
                                );
                              })}
                </Modal.Body>
              )}
            </Modal>
            {/* Comment Modal */}
            <Modal show={CommentModal} onHide={handleCloseComment} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Comment</Modal.Title>
              </Modal.Header>
             
                <Modal.Body>
                <Row>
                <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Comment </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        // value={inputField}
                        placeholder="Comment...."
                        onChange={(e) => changeComment(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col lg="9" md="9" xs="9"></Col>
                <Col lg="3" md="3" xs="2">
                <Button
                       //onClick={uploadCompleteProduct}>
                      // disabled={reason == "" ? true : false}
                      onClick={() => {
                        // props.orderCancel(idEdit,{
                        //   orderCancelReason:reason
                        // });
                        handleCloseComment()
                      }}
                    >
                      Save
                    </Button>
                    </Col>
                </Row>
                </Modal.Body>
            </Modal>
            {/* order commet modal */}
            <Modal show={orderCommentModal} onHide={handleCloseOrderComment} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Order Comment</Modal.Title>
              </Modal.Header>
             
                <Modal.Body>
                <Row>
                <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Comment </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                         value={orderComment}
                        placeholder="Comment...."
                        onChange={(e) => setOrderComment(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col lg="9" md="9" xs="9"></Col>
                <Col lg="3" md="3" xs="2">
                <Button
                       //onClick={uploadCompleteProduct}>
                      // disabled={reason == "" ? true : false}
                      onClick={() => {
                        // props.orderCancel(idEdit,{
                        //   orderCancelReason:reason
                        // });
                        handleCloseOrderComment()
                      }}
                    >
                      Save
                    </Button>
                    </Col>
                </Row>
                </Modal.Body>
            </Modal>
          {/* </div> */}
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUserRes()),
    getResturant: () => dispatch(getRestaurantData()),
    getProduct: () => dispatch(getServiceAndHybridProduct()),
    getCatagoery: () => dispatch(getCategoryData()),
    getCoupons: () => dispatch(getCoupons()),
    getPendingOrder: () => dispatch(getPendingOrder()),
    addOrders: (data, handleVisible, handleDangerVisible) =>
      dispatch(AddOrders(data, handleVisible, handleDangerVisible)),
    deleteOrder: (oldData) => dispatch(deleteOrders(oldData)),
    getOrder: () => dispatch(getOrders()),
    getOrderById: (orderId) => dispatch(GetOrderById(orderId)),
    updateOrder: (oldData, newData) => dispatch(updateOrders(oldData, newData)),
    getCustomer: () => dispatch(getCustomerData()),
    getProductVariation: (id) => dispatch(getProductTypeById(id)),
    getOrderTypes: () => dispatch(getOrderTypes()),
    getTables: () => dispatch(getTables()),
    updateStatus: (data, status) => dispatch(updateStatus(data, status)),
    searchProduct: (data) => dispatch(filterItem(data)),
    serchByMenu: (id) => dispatch(filterItemByMenu(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  customersList: state.POSViewReducer.customersList,
  customerDataList: state.CustomerReducer.customerDataList,
  isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,
  isFetchingOrderTypes: state.POSViewReducer.isFetchingOrderTypes,
  orderTypesList: state.POSViewReducer.orderTypesList,
  isFetchingTables: state.POSViewReducer.isFetchingTables,
  OrderDataByID: state.POSViewReducer.OrderDataByID,
  tablesList: state.POSViewReducer.tablesList,
  categoryList: state.CategoryReducer.categoryList,
  couponsDataList: state.CouponReducer.couponsDataList,
  isFetchingCouponsData: state.CouponReducer.isFetchingCouponsData,
  serviceAndHybridProductList: state.RecipeReducer.serviceAndHybridProductList,
  isFetchingServiceAndHybridProductList:
    state.ProductReducer.isFetchingServiceAndHybridProductList,
  productTypeListById: state.RecipeReducer.productTypeListById,
  isFetchingProductTypeById: state.RecipeReducer.isFetchingProductTypeById,
  seatSelected: state.POSViewReducer.seatSelected,
  isAddingOrders: state.POSViewReducer.isAddingOrders,
  productSearchList: state.RecipeReducer.productSearchList,
  restaurantList: state.ResturantReducer.restaurantDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBillingPos);
