import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Breadcrumb,
  Navbar,
} from "react-bootstrap";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import jsPDF from "jspdf";
//import logo from "../../../../../../Images/logo.png";
import ClearIcon from "@mui/icons-material/Clear";
import { getDateWiseSaleReport } from "./Report.action";
import { Link } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";

const DateWiseSaleReport = (props) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [state, setState] = React.useState({
    viewColumns: [
      { title: "Name", field: "customer.customer_name" }, //new
      { title: "CNIC", field: "customer.customer_cnic" }, //new
      { title: "Cell No.", field: "customer.customer_phone" }, //new
      { title: "Bill#", field: "issuanceId" },
      // { title: 'Order Type', render: rowData => isNull(rowData.order_type)? '':rowData.order_type.order_type_name },
      // { title: 'Table', render: rowData =>  isNull(rowData.restaurant_table)? '':rowData.restaurant_table.restaurant_table_nam },
      { title: "Requistion", field: "requsition" },
      { title: "Total Amount", field: "total_amount" },
      { title: "Discount%", field: "discount" },
      { title: "Advance Amount", field: "advance_amount" },
      { title: "Pending Amount", field: "pending_amount" },
      { title: "Status", field: "issuance_status" },
      //  { title: 'Supervisor', field: 'supervisor' },
      { title: "Billed By", field: "user.username" },
      //  { title: 'Received By', field: 'receivedBy' },
      //   { title: 'Requistion Type', field: 'requsitionType' },
      {
        title: "Date",
        field: "createdAt",
        render: (rowData) => moment(rowData.createdAt).format("L"),
      },
    ],
    columnsData: [
      {
        title: "Inventory Code",
        field: "inventory",
        render: (rowData) =>
          !isNull(rowData.inventory.warehouse)
            ? rowData.inventory.warehouse.whKey
            : 0 + "-" + !isNull(rowData.inventory.warehouse)
            ? rowData.inventory.category.cateKey
            : 0 + "-" + !isNull(rowData.inventory.warehouse)
            ? rowData.inventory.sub_category.subcateKey
            : 0 + "-" + !isNull(rowData.inventory.warehouse)
            ? rowData.inventory.invKey
            : 0,
      },
      { title: "Inventory Name", field: "inventory.productName" },
      { title: "Product Quantity", field: "productQuantity" },
      { title: "Selling Price", field: "issuanceSalePrice" },
      {
        title: "Total",
        field: "issuanceSalePrice",
        render: (rowData) =>
          Number(rowData.issuanceSalePrice) * Number(rowData.productQuantity),
      },
    ],
  });

  const tableRef = useRef();
  function updateTableQuery() {
    tableRef.current.onQueryChange();
  }

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Issuance Added Successfully",
      icon: "info",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const exportPDF = () => {
    props.getDateWiseSaleReport({ current_date: startDate },
      handleDangerVisible);
  };
  console.log(props.saleReportData, "ddddddddd");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    // props.getDateWiseSaleReport({ current_date: startDate })
  }, []);
  return (
    <>
        <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Day Wise Sale Report</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
    
     
        <Container fluid>
           
          <div className="main">

            <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Day Wise Sale Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>
            {props.isFetchingSaleReport ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Downloading PDF
          </Button>
        </div>
      ) : (
            <div className="sendDiv" style={{ marginTop: "2%" }}>
              {isUndefined(startDate) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    border: "1px solid",
                  }}
                >
                  Generate Sales Report
                </Button>
              ) : 
                <Button onClick={() => exportPDF()}>
                  Generate Sales Report
                </Button>
              }
            </div>
             )}
          </div>
        </Container>
     
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDateWiseSaleReport: (data,handleDangerVisible) => dispatch(getDateWiseSaleReport(data,handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingSaleReport: state.ReportReducer.isFetchingSaleReport,
  saleReportData: state.ReportReducer.saleReportData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DateWiseSaleReport);
