import API from "../../../../../global/api";
export const requestSizeData = () => {
    return {
        type: "REQUEST_SIZE_GET_DATA",
    };
};
export const successSizeGetData = (data) => {
    return {
        type: "SUCCESS_SIZE_GET_DATA",
        payload: data,
    };
};
export const errorSizeGetData = () => {
    return {
        type: "ERROR_SIZE_GET_DATA",
    };
};
export const requestAddSize = () => {
    return {
        type: "REQUEST_ADD_SIZE",
    };
};
export const successAddSize = (data) => {
    return {
        type: "SUCCESS_ADD_SIZE",
        payload: data,
    };
};
export const errorAddSize = () => {
    return {
        type: "ERROR_ADD_SIZE",
    };
};
export const getSizeData = () => {
    return (dispatch) => {
        dispatch(requestSizeData());
        API.get(`/size`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successSizeGetData(getData));
        }).catch((error) => {
            dispatch(errorSizeGetData());
        })
    };
};
export const addSize = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSize());
        API.post(`/size`, data).then((res) => {
           // dispatch(getSizeData());
            dispatch(successAddSize(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddSize());
            handleDangerVisible(error.response.data.message);
        })
    };
};
export const DeleteRequestSize = () => {
    return {
        type: "DELETE_REQUEST_SIZE",
    };
};
export const DeleteSuccessSize = (user_id) => {
    return {
        type: "DELETE_SUCCESS_SIZE",
        user_id: user_id,
    };
};
export const DeleteErrorSize = () => {
    return {
        type: "DELETE_ERROR_SIZE",
    };
};
export const deleteSize = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestSize());
        API.delete(`/size/${vendorId}`)
            .then(res => {
                dispatch(getSizeData());
                dispatch(DeleteSuccessSize(vendorId))
            }).catch((error) =>
            {
                dispatch(DeleteErrorSize())
    });
    }
};
export const UpdateRequestSize = () => {
    return {
        type: "UPDATE_REQUEST_SIZE",
    };
};
export const UpdateSuccessSize = (data) => {
    return {
        type: "UPDATE_SUCCESS_SIZE",
        payload: data,
    };
};

export const UpdateErrorSize = () => {
    return {
        type: "UPDATE_ERROR_SIZE",
    };
};

export const updateSize = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestSize());
        console.log(data,'hmhm')
        API.put(`/size/${oldData._id}`,
            {
                sizeName: data.sizeName,
                sizeDesc:data.sizeDesc     
            },{
        })
            .then(res => {
                dispatch(getSizeData());
                dispatch(UpdateSuccessSize([data]))
            }).catch((error) =>
                dispatch(UpdateErrorSize()));
    }
};