import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, sumBy, filter, ceil, floor } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import "jspdf-autotable";
import Swal from "sweetalert";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import useScanDetection from "use-scan-detection";
import {
  addProduct,
  addProductImage,
  getProductType,
  getUOM,
  resetReducer,
} from "./Product.action";
import { getFlavorsData } from "../AddFlavour/Flavour.action";
import { getSizeData } from "../AddSize/Size.action";
import { getMasterControlData } from "../AddMasterControl/MasterControl.action";
import { getCategoryData } from "../categories/Catagory.action";
import { setHeading } from "../../../accounts/account.actions";
import { AsyncPaginate } from "react-select-async-paginate";
import { baseURL, headers } from "../../../../../global/api";

const AddProduct = (props) => {
  const [product, setProduct] = useState("");
  const [CategoryId, setCategoryId] = useState("");
  const [Categoryname, setCategoryName] = useState("");
  const [uomId, setUomId] = useState("");
  const [uomname, setUomName] = useState("");
  const [TypeId, setTypeId] = useState("");
  const [salePrice, setsalePrice] = useState(0);
  const [code, setCode] = useState("");
  const [minimumBalance, setMinimumBalance] = useState(0);
  const [maximumBalance, setMaximumBalance] = useState(0);

  const [purchasePrice, setPurchasePrice] = useState(0);
  const [productQuantity, setProductQuantity] = useState("");
  const [Typename, setTypeName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [GreenColorTime, setGreenColorTime] = useState(0);
  const [yellowColorTime, setYellowColorTime] = useState(0);
  const [redColorTime, setRedColorTime] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [barcode, setBarocode] = useState("");
  const [value, setValue] = useState();
  // const [warehouseKey, setWarehouseKey] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [inputField, setInputField] = useState([
    {
      flavorLabel: "",
      sizeLabel: "",
      sellingPrice: 0,
      isRecipe: false,
    },
  ]);
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { flavorLabel: "", sizeLabel: "", sellingPrice: 0, isRecipe: false },
    ]);
  };
  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };
  const handleChange = (e, index, type) => {
    console.log(inputField, "hmhm");
    let z = find(props.allInventory, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField];
    // if (type == "flavor") list[index]["flavorid"] = e.value;
    if (type == "flavor") list[index]["flavorLabel"] = e.label;
    // if (type == "size") list[index]["sizeId"] = e.value;
    if (type == "size") list[index]["sizeLabel"] = e.label;
    // if (type == "size") list[index]["isRecipe"]=false;
    if (type == "price") list[index]["sellingPrice"] = Number(e.target.value);
    setInputField(list);
  };
  const inventoryFlavor = props.flavorsDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.flavourName,
    };
    return data;
  });
  const inventorySize = props.sizeDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.sizeName,
    };
    return data;
  });
  const [inputField1, setInputField1] = useState([
    {
      master: "",
      quantity: 0,
      price: 0,
    },
  ]);
  const handleAddInput1 = () => {
    setInputField1([...inputField1, { master: "", quantity: 0, price: 0 }]);
  };
  const handleRemoveInput1 = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type) => {
    let z = find(props.allInventory, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField1];
    if (type == "master") list[index]["master"] = e.value;
    if (type == "master") list[index]["masterLabel"] = e.label;
    if (type == "quantity") list[index]["quantity"] = e.value;
    if (type == "quantity") list[index]["quantityLabel"] = e.label;
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    if (type == "price") list[index]["price"] = Number(e.target.value);
    setInputField1(list);
  };
  const inventoryMaster = props.masterControlDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.masterControlName,
    };
    return data;
  });
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function uploadCompleteProduct() {
    console.log(props.ProductImageURL, "chech123");
    props.addProduct(
      {
        name: product,
        uom: uomId,
        uomLabel: uomname,
        productType: TypeId,
        code: code,
        salePrice: salePrice,
        maximumBalance: maximumBalance,
        minimumBalance: minimumBalance,
        category: CategoryId,
        purchasePrice: purchasePrice,
        productBalance: productQuantity,
        images: props.ProductImageURL.file,
        barcodeId: barcode,
      },
      handleVisible,
      handleDangerVisible
    );
    props.reset();
  }

  function uploadProduct() {
    console.log(props.ProductImageURL, "chech123");
    props.addProduct(
      {
        name: product,
        discount: discount,
        category: CategoryId,
        desc: productDesc,
        images: props.ProductImageURL.file,
        greenColorTime: GreenColorTime,
        yellowColorTime: yellowColorTime,
        redColorTime: redColorTime,
        variation: inputField,
        code: code,
        //  uom:uomId,
        productType: TypeId,
        // barcodeId:barcode,
        //  uomPrice:uomPrice
      },
      handleVisible,
      handleDangerVisible
    );
    props.reset();
  }
  const wareHouseData = props.productDataList;
  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.ProductImage({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  const handleChangeCategory = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
  };

  const getCategoryList = 
  
    !isEmpty(props.categoryList) &&
    props.categoryList.data.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  const handleChangeUom = (selectedOption) => {
    console.log(selectedOption, "sop");
    setUomId(selectedOption.value);
    setUomName(selectedOption.label);
  };
  const getUomList =
    !isEmpty(props.productUomList) &&
    props.productUomList.map((x) => {
      let data = { value: x._id, label: x.unitName };
      return data;
    });
  const handleChangeType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setTypeId(selectedOption.value);
    setTypeName(selectedOption.label);
  };
  const gettypeList =
    !isEmpty(props.productTypeList) &&
    props.productTypeList.map((x) => {
      let data = { value: x._id, label: x.productTypeName };
      return data;
    });
    const permissions=JSON.parse(window.localStorage.getItem("permissions"))
    const Checkpermissions = (permissionsType,permissionsName) => {
      return permissions.some(p => p[`${permissionsType}`] === permissionsName)?true:false
     }
     
 useScanDetection(
  Checkpermissions("permissionsCatName","Enable Barcode")&&
  {
    onComplete: setBarocode,
    //  minLength: 13 // EAN13
  });
  useEffect(() => {
    props.getCategoryData();
    props.getFlavor();
    props.getSize();
    props.getMaster();
    props.getProductUom();
    props.getProductType();
    props.setHeading("Add Product");
    //props.resetState();
  }, []);
  async function loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(`${baseURL}/categories/?page=${!isEmpty(search)?"":page}&limit=${!isEmpty(search)?"":5}&search=${search}`,{headers:headers} );
    const responseJSON = await response.json();

   let check= responseJSON.total_results/responseJSON.limit;
   console.log(check,responseJSON.page,"tttttttttttt")
   let check1= parseInt(check)!=responseJSON.page?true:false
    return {
      options: isEmpty(responseJSON.data)?[]: getCategoryList(responseJSON.data),
      hasMore: isEmpty(responseJSON.data)?false:check1,
      additional: {
        page: page+1 ,
      },
    };
  }
  return (
    <>
      {props.isAddingProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Product Type *</b>
                    </Form.Label>
                    <Select
                      isDisabled={isEmpty(props.productTypeList)}
                       value={{label:Typename}}
                      placeholder="Select Product Type..."
                      onChange={handleChangeType}
                      options={gettypeList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div
                hidden={
                  TypeId == "6433dd117aad3eb039c17451" ||
                  TypeId == "6433dd117aad3eb039c1744f"
                    ? false
                    : true
                }
              >
                <Row>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Menu *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.categoryList)}
                        placeholder="Select Menu..."
                        onChange={handleChangeCategory}
                        options={getCategoryList}
                      />
                      {/* <AsyncPaginate
value={value}
loadOptions={loadOptions}
onChange={(e) => {handleChangeCategory(e);
  console.log(e,"eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
  setValue(e)

}}
additional={{
  page: 0,
}}
/> */}

                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Code *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Code...."
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Product Name...."
                        onChange={(e) => setProduct(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select UOM *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.productUomList)}
                        placeholder="Select UOM..."
                        onChange={handleChangeUom}
                        options={getUomList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Purchase Price *</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Purchase Price...."
                        onChange={(e) => setPurchasePrice(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Quantity *</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Product Quantity...."
                        onChange={(e) => setProductQuantity(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
               
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Sale Price </b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="sale Price...."
                        onChange={(e) => setsalePrice(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Minimum Balance *</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Minimum Balance...."
                        onChange={(e) => setMinimumBalance(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Maximum Balance *</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Maximum Balance...."
                        onChange={(e) => setMaximumBalance(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
              
                  {Checkpermissions("permissionsCatName","Enable Barcode")&&
                  <Col xl="4" lg="4" md="4" sm="4">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Bar Code Scaner</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Bar Code number...."
                        value={barcode}
                        disabled
                        onBlur={(e) => setBarocode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>}
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>
                          Upload Product Image
                        </b>
                      </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText="Upload Image"
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {isEmpty(code) ||
                  isEmpty(uomId) ||
                  isEmpty(product) ||
                  isEmpty(CategoryId) ||
                  productQuantity == "" ||
                  purchasePrice == 0 ||
                  minimumBalance == 0 ||
                  maximumBalance == 0 ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "#1976d2",
                        border: "1px solid #1976d2",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button onClick={uploadCompleteProduct}>Save</Button>
                  )}
                </div>
              </div>
              <div hidden={TypeId == "6433dd117aad3eb039c17450" ? false : true}>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Menu *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.categoryList)}
                        placeholder="Select Menu..."
                        onChange={handleChangeCategory}
                        options={getCategoryList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Code *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Code...."
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Product Name...."
                        onChange={(e) => setProduct(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Description :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={productDesc}
                        placeholder="Product Description...."
                        onChange={(e) => setProductDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Discount :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={discount}
                        placeholder="Discount...."
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Green Color Time :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={GreenColorTime}
                        placeholder="Green Color Time...."
                        onChange={(e) => setGreenColorTime(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Yellow Color Time :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={yellowColorTime}
                        placeholder="Yellow Color Time...."
                        onChange={(e) => setYellowColorTime(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Red Color Time :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={redColorTime}
                        placeholder="Red Color Time...."
                        onChange={(e) => setRedColorTime(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Select Variation *</b>
                      </Form.Label>
                      {inputField.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Row
                              style={{
                                marginLeft: "25px",
                                marginRight: "25px",
                              }}
                            >
                              <Col lg="3" md="3" sm="3">
                                <Form.Group>
                                  <Form.Label>
                                    <b> Select Flavor *</b>
                                  </Form.Label>
                                  <Select
                                    style={{ zIndex: 1 }}
                                    value={{ label: item.flavorLabel }}
                                    placeholder="Select Items..."
                                    name="flavor"
                                    onChange={(e) =>
                                      handleChange(e, i, "flavor")
                                    }
                                    options={inventoryFlavor}
                                    //  isOptionDisabled={(option) => !isEmpty(find(inputField, x => x.master_control_item_id == option.value))}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="3" md="3" sm="3">
                                <Form.Group>
                                  <Form.Label>
                                    <b> Select Size *</b>
                                  </Form.Label>
                                  <Select
                                    style={{ zIndex: 1 }}
                                    value={{ label: item.sizeLabel }}
                                    placeholder="Select Size..."
                                    name="size"
                                    onChange={(e) => handleChange(e, i, "size")}
                                    options={inventorySize}
                                    isOptionDisabled={(option) =>
                                      !isEmpty(
                                        find(
                                          inputField,
                                          (x) => x.sizeId == option.value
                                        )
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="3" md="3" sm="3">
                                <Form.Group className="mb-3">
                                  <Form.Label>
                                    <b>Selling Price *</b>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Add Price...."
                                    name="sellingPrice"
                                    value={item.sellingPrice}
                                    onChange={(e) =>
                                      handleChange(e, i, "price")
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                style={{ marginTop: "2.5%" }}
                              >
                                {/* <h1>Afraz</h1> */}
                                <Form.Group>
                                  {inputField.length - 1 == i && (
                                    <Button
                                      title="Click to Add Demand"
                                      onClick={handleAddInput}
                                      style={{
                                        color: "white",
                                        float: "right",
                                      }}
                                    >
                                      <AddIcon />
                                    </Button>
                                  )}

                                  {inputField.length !== 1 && (
                                    <Button
                                      title="Click to Remove Demand"
                                      onClick={() => handleRemoveInput(i)}
                                      style={{
                                        float: "right",
                                        color: "white",
                                        marginRight: "2%",
                                      }}
                                    >
                                      <RemoveIcon />
                                    </Button>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          </React.Fragment>
                        );
                      })}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>
                          Upload Product Image 
                        </b>
                      </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText="Upload Image"
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {console.log(inputField, "jjjjjjjjjjjjj")}
                  {isEmpty(code) ||
                  isEmpty(product) ||
                  // isEmpty(props.ProductImageURL) ||
                  isEmpty(CategoryId) ||
                  isEmpty(inputField[0].flavorLabel) ||
                  inputField[0].sellingPrice == 0 ||
                  isEmpty(inputField[0].sizeLabel) ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "#1976d2",
                        border: "1px solid #1976d2",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button onClick={uploadProduct}>Save</Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (data, handleVisible, handleDangerVisible) =>
      dispatch(addProduct(data, handleVisible, handleDangerVisible)),
    getFlavor: () => dispatch(getFlavorsData()),
    getSize: () => dispatch(getSizeData()),
    getMaster: () => dispatch(getMasterControlData()),
    getCategoryData: () => dispatch(getCategoryData()),
    getProductUom: () => dispatch(getUOM()),
    getProductType: () => dispatch(getProductType()),
    ProductImage: (data) => dispatch(addProductImage(data)),
    reset: () => dispatch(resetReducer()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productList: state.ProductReducer.productList,
  productTypeList: state.ProductReducer.productTypeList,
  productUomList: state.ProductReducer.productUomList,
  ProductImageURL: state.ProductReducer.ProductImageURL,
  isAddingProduct: state.ProductReducer.isAddingProduct,
  categoryList: state.CategoryReducer.categoryList,
  flavorsDataList: state.FlavourReducer.flavorsDataList,
  sizeDataList: state.sizeReducer.sizeDataList,
  masterControlDataList: state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
