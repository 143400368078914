import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from 'sweetalert';
import {
  addCashPayment,
  submitBankRecipet,
  deleteBankRecipet,
  resetFunction3,
} from "./Voucher.action";
import moment from 'moment';
import {previewVoucher, mergeNames} from '../../../../global/globalFunctions'
import { setHeading } from "../../accounts/account.actions";
import { getLevel5Data } from "../accountsLevels/accountsLevel.action";

const CashPayment = (props) => {

  const [accountNo, setaccountNo] = useState();
  const [cashAccountTitle, setCashAccountTitle] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [cashId, setCashId] = useState("6439ac55391c16046483883b");
  const [cashAccountTitleKey, setCashAccountTitleKey] = useState();
  const [voucher, setVoucher] = useState();
  const [level4Id, setLevel4Id] = useState(2);
  const [datestate, setDatestate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [level4Label, setLevel4Lablel] = useState(`3-1-1-2/BANKS`);

  const handleVisible = (data) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      buttons: ["Cancel", "Print Voucher"]
    }).then((willPrint) => {
      if (willPrint) {
        //subPage()
        previewVoucher(data, "Pending");
      } else {
         // setShowDialog(false)
      }
    })
  }

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  }

  const [values, setValues] = useState({
    voucherDescription: "",
    voucherAmount: "",
   // PONo: "",
    billNumber: "",
    accountTittle: "",
    // payTo: "",
    levelFourId: "",
    voucherDate: "",
    accountType: "1",
    credit:"",
    debit:"",
    crossAccount:""
  });

  const inputEventAccount = (selectedOption, type) => {
    console.log(selectedOption,"level4id")
    setLevel4Id(selectedOption.value);
    setValues({
      levelFourId: selectedOption.value,
    });
  };

  const inputEvent1Account = (selectedOption) => {
    setLevel4Id(selectedOption.value);
    setValues({
      ...values,
      ["levelFourId"]: selectedOption.value,
      ["accountTittle"]: get(
        find(
          props.level5List,
          (x) => x.levelFourData._id == selectedOption.value
        ),
        "levelFourData.levelFourName",
        0
      ) + "(" + get(
        find(
          props.level5List,
          (x) => x.levelFourData._id == selectedOption.value
        ),
        "levelFourData.allLevelKey",
        0
      ) + ")",
    });
  };

  // const inputEvent = (event) => {
  //   event.target.name == "voucherDate" &&
  //     setDatestate(event.target.value);
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   })
  // };
  const inputEvent = (event) => {
    event.target.name == "voucherDate" &&

      setDatestate(event.target.value);
   if(event.target.name == "voucherAmount" ) {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      credit:Number(event.target.value)
    })
   }
   else {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
   }
  };


  const cashAccountNoTop =
  !isEmpty(props.level5List) &&
  props.level5List
    .filter((x) => x.levelThree._id == cashId)
    .map((y) => {
      let data = {
        value: y.levelFourData._id,
        label:
          y.levelFourData.allLevelKey + " /" + y.levelFourData.levelFourName,
      };
      return data;
    });
  const getLevelFourList =
    !isEmpty(props.level4List) &&
    props.level4List.map((x) => {
      let data = {
        value: x.levelFourData._id,
        label: x.levelFourData.allLevlKey + "/ " + x.levelFourData.levelFourName,
      };
      return data;
    });
  const handleChangeLevel4 = (selectedOption) => {
    setLevel4Id(selectedOption.value);
    setLevel4Lablel(selectedOption.label);
  };

  const cash = props.bankRecipet;
  const [state, setState] = React.useState({
    columns: [
      { title: "Date", field: "datarecipet.voucherDate" },
      { title: "Account Name", field: "datarecipet.accountTittle" },
      { title: "Bill No", field: "datarecipet.billNo" },
      { title: "Description", field: "datarecipet.voucherDescription" },
      { title: "Amount", field: "datarecipet.voucherAmount" },
      {
        title: "DR/CR",
        field: "datarecipet.accountType",
        render: (rowData) =>
          rowData.datarecipet.accountType == 1
            ? "Credit"
            : rowData.datarecipet.accountType == 2
              ? "Debit"
              : "---",
      },
    ],
  });

  const addPayment = () => {
    let newVal = values;
    if (!isEmpty(datestate)) newVal.voucherDate = datestate;
    props.submitBankRecipet(newVal);
    setValues({
      voucherDescription: "",
      voucherAmount: "",
   //   PONo: "",
      billNo: "",
      accountTittle: "",
    //   payTo: "",
      levelFiveId: "",
      voucherDate: "",
      accountType: "1",
      credit:"",
      debit:"",
      crossAccount:""
    });
    // setCashAccountTitle("");
    // setLevel4Lablel("")
  };
  const cashAccountNo =
  !isEmpty(props.level5List) &&
  props.level5List.map((x) => {
    let data = {
      value: x.levelFourData._id,
      label:
        x.levelFourData.allLevelKey + " /" + x.levelFourData.levelFourName,
    };
    return data;
  });

  const handleChangeAccountNo = (selectedOption) => {
    setaccountNo(selectedOption.value);
      setCashAccountTitle(
        find(
          props.level5List,
          (x) => x.levelFourData._id == selectedOption.value
        ).levelFourData.levelFourName
      );
  
      setCashAccountTitleKey(
        find(
          props.level5List,
          (x) => x.levelFourData._id == selectedOption.value
        ).levelFourData.allLevelKey
      );
      setSelectedAccount(selectedOption.label)
  };

  const add = () => {
    let dt= cash.map((x) => {
      return x.datarecipet;
    })
    let cashData= [{
      voucherDescription: mergeNames(dt, 'voucherDescription'),
      voucherAmount: sumBy(cash,item => Number(item.datarecipet.voucherAmount)),
      billNumber: mergeNames(dt, 'billNo'),
      accountTittle:cashAccountTitle,
      levelFourId:accountNo,
      voucherDate: datestate,
      accountType: "2",
      crossAccount: mergeNames(dt, 'accountTittle'),
      debit: sumBy(cash,item => Number(item.datarecipet.voucherAmount)),
      credit:"",
  }]

    props.addCashPayment({
      voucherTypeId: "4",
      levelFourId: accountNo,
      cashAccTittle: cashAccountTitle,
      totalAmount: sumBy(cash,item => Number(item.datarecipet.voucherAmount)),
      voucher: voucher,
      voucherData: [...dt, ...cashData],
      voucherType: "BRV",
     // credit_or_debit: "2",
    }, handleVisible, handleDangerVisible);
    props.resetFunction3();
  };
  useEffect(() => {
    props.setHeading("Bank Receipt Voucher");
    props.getLevel5Data();
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchinglevel5List || props.isFetchinglevel4List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <Row>
                <Col lg="12">
                  <div >
                    <Row>
                      <Col lg="4" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="voucherDate"
                            value={datestate}
                            //value={values.voucherDate}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={inputEvent}
                            disabled={!isEmpty(props.bankRecipet)}
                          />
                        </Form.Group>
                      </Col>

                      {/* <Col lg="4" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Select Level4</Form.Label> */}
                      {/* <Select
                            className="selectOptionColor"
                            value = {{label: level4Label}}
                            placeholder="Select Level 4..."
                            onChange={(e) => handleChangeLevel4(e)}
                            options={getLevelFourList}
                          /> */}
                      {/* <Form.Control
                            type="text"
                            name="selectlevel4"
                            value={level4Label}
                            //value={values.voucherDate}
                           
                            disabled={true}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col lg="6" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Bank A/C No *</Form.Label>
                          <Select
                            className="selectOptionColor"
                            value={{ label: cashAccountTitle }}
                            isDisabled={isUndefined(level4Id)|| !isEmpty(accountNo)}
                            placeholder="Select Cash Account No..."
                            onChange={handleChangeAccountNo}
                            options={filter(cashAccountNoTop, x => x.value !== values.levelFourId)}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="6" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Amount Type"
                            value="Debit"
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account *</Form.Label>
                      <Select
                        value={{ label: values.accountTittle }}
                        placeholder="Select Cash Account No..."
                        onChange={(inputEventAccount, inputEvent1Account)}
                        options={filter(cashAccountNo, x => x.value !== accountNo)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Amount Type"
                        value="Credit"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="2" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bill</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bill No"
                        name="billNo"
                        value={values.billNo}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="7" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        name="voucherDescription"
                        value={values.voucherDescription}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount *</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        name="voucherAmount"
                        value={values.voucherAmount}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="6" sm="12" className="text-center">
                  <Button className="sendButtonVoucher" onClick={addPayment}
                  disabled={isEmpty(level4Id)||isEmpty(accountNo)||isEmpty(values.voucherAmount)}
                  >
                    ADD
                  </Button>
                </Col>
                <Col lg="6" sm="12">
                  <Button className="sendButtonVoucher" onClick={add}
                   disabled={isEmpty(cash)}
                  >
                    POST
                  </Button>
                </Col>
              </Row>

              <div className="table">
                <MaterialTable
                  title={`VOUCHER TOTAL : ${sumBy(cash,item => Number(item.datarecipet.voucherAmount))}`}
                  columns={state.columns}
                  data={cash}
                  options={{
                    actionsColumnIndex: -1,
                    paging: true,
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteBankRecipet(oldData);
                        }, 600);
                      }),
                  }}
                />
              </div>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCashPayment: (data, handleVisible, handleDangerVisible) => dispatch(addCashPayment(data, handleVisible, handleDangerVisible)),
    submitBankRecipet: (data) => dispatch(submitBankRecipet(data)),
    deleteBankRecipet: (id) => dispatch(deleteBankRecipet(id)),
    resetFunction3: () => dispatch(resetFunction3()),
    getLevel5Data: () => dispatch(getLevel5Data()),
    setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  bankRecipet: state.VoucherReducer.bankRecipet,
  level5List: state.accountsLevelReducer.level5List,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
});

export default connect(mapStateToProps, mapDispatchToProps)(CashPayment);
