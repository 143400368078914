import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  UomList: [],
  isAddingUom: false,
};

const uomReducer = (state = initialState, action) => {
  switch (action.type) {
      //addUom
      case "REQUEST_ADD_UOM":
        return { ...state, isAddingUom: true};
      case "SUCCESS_ADD_UOM":
        return {
          ...state,
         UomList: action.payload,
          isAddingUom: false,
        };
      case "ERROR_ADD_UOM":
        return { ...state, isAddingUom: false};
      
      default:
        return state;
    }
  };
  export default uomReducer;