import API from "../../../../../global/api";
import { get} from 'lodash';
import { getOrders } from "../POS/POSView.action";
export const requestTableData = () => {
  return {
    type: "REQUEST_TABLE_GET_DATA",
  };
};
export const successTableGetData = (data) => {
  return {
    type: "SUCCESS_TABLE_GET_DATA",
    payload: data,
  };
};
export const errorTableGetData = () => {
  return {
    type: "ERROR_TABLE_GET_DATA",
  };
};

export const getTableData = () => {
  return (dispatch) => {
    dispatch(requestTableData());
    API.get(`/table?limit=1000&page=0&search=`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "List");
        dispatch(successTableGetData(getData));
      })
      .catch((error) => {
        dispatch(errorTableGetData());
      });
  };
};
export const requestAddTable = () => {
  return {
    type: "REQUEST_ADD_TABLE",
  };
};
export const successAddTable = (data) => {
  return {
    type: "SUCCESS_ADD_TABLE",
    payload: data,
  };
};
export const errorAddTable = () => {
  return {
    type: "ERROR_ADD_TABLE",
  };
};
export const addTable = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddTable());
    API.post(`/table`, data)
      .then((res) => {
        dispatch(successAddTable(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        console.log(error, "dataaaaaaaaaaaaaaaaaaaaaa");
        dispatch(errorAddTable());
        handleDangerVisible(error.response.data.message);
      });
  };
};
//upload table
export const requestUploadTable = () => {
  return {
    type: "REQUEST_UPLOAD_TABLE",
  };
};
export const successUploadTable = (data) => {
  return {
    type: "SUCCESS_UPLOAD_TABLE",
    payload: data,
  };
};
export const errorUploadTable = () => {
  return {
    type: "ERROR_UPLOAD_TABLE",
  };
};
export const UploadTable = (data,resturantId,halId, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
formData.append("file", get(data, 'file'));
formData.append("resturantId", `${resturantId}`);
formData.append("halId", `${halId}`);
  return (dispatch) => {
    dispatch(requestUploadTable());
    API.post(`/table/upload_table_file`, formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
      .then((res) => {
        dispatch(successUploadTable(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        console.log(error, "dataaaaaaaaaaaaaaaaaaaaaa");
        dispatch(errorUploadTable());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const DeleteRequestTable = () => {
  return {
    type: "DELETE_REQUEST_TABLE",
  };
};
export const DeleteSuccessTable = (user_id) => {
  return {
    type: "DELETE_SUCCESS_TABLE",
    user_id: user_id,
  };
};
export const DeleteErrorTable = () => {
  return {
    type: "DELETE_ERROR_TABLE",
  };
};
export const deleteTable = (tableId) => {
  return (dispatch) => {
    dispatch(DeleteRequestTable());
    API.delete(`table/${tableId}`)
      .then((res) => {
        // dispatch(getTableData());
        dispatch(DeleteSuccessTable(tableId));
      })
      .catch((error) => {
        dispatch(DeleteErrorTable());
      });
  };
};
export const UpdateRequestTable = () => {
  return {
    type: "UPDATE_REQUEST_TABLE",
  };
};
export const UpdateSuccessTable = (data) => {
  return {
    type: "UPDATE_SUCCESS_TABLE",
    payload: data,
  };
};

export const UpdateErrorTable = () => {
  return {
    type: "UPDATE_ERROR_TABLE",
  };
};

export const updateTable = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestTable());
    API.put(
      `/table/${oldData._id}`,
      {
        tableName: data.tableName,
        tableSittingCapacity: data.tableSittingCapacity,
        tableDesc: data.tableDesc,
      },
      {}
    )
      .then((res) => {
        //  dispatch(getTableData());
        dispatch(UpdateSuccessTable([data]));
      })
      .catch((error) => dispatch(UpdateErrorTable()));
  };
};
export const requestTableReserved = () => {
  return {
    type: "REQUEST_TABLE_RESERVED",
  };
};
export const successTableReserved = (data) => {
  return {
    type: "SUCCESS_TABLE_RESERVED",
    payload: data,
  };
};
export const errorTableReserved = () => {
  return {
    type: "ERROR_TABLE_RESERVED",
  };
};

export const getTableReserved = () => {
  return (dispatch) => {
    dispatch(requestTableReserved());
    API.get(`/table/get_booked_table`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "List");
        dispatch(successTableReserved(getData));
      })
      .catch((error) => {
        dispatch(errorTableReserved());
      });
  };
};
// get hall by resturant id
export const requestHallById = () => {
  return {
    type: "REQUEST_HALL_BYID",
  };
};
export const successHallById = (data) => {
  return {
    type: "SUCCESS_HALL_BYID",
    payload: data,
  };
};
export const errorHallById = () => {
  return {
    type: "ERROR_HALL_BYID",
  };
};
export const getHallById = (id) => {
  return (dispatch) => {
    dispatch(requestHallById());
    API.get(`/hall/get_hall_with_restaurantId/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "List");
        dispatch(successHallById(getData));
      })
      .catch((error) => {
        dispatch(errorHallById());
      });
  };
};
export const requestShiftTable = () => {
  return {
    type: "REQUEST_SHIFT_TABLE",
  };
};
export const successShiftTable = (data) => {
  return {
    type: "SUCCESS_SHIFT_TABLE",
    payload: data,
  };
};
export const errorShiftTable = () => {
  return {
    type: "ERROR_SHIFT_TABLE",
  };
};
export const TableShiftData = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestShiftTable());
    API.post(`/table/shift_table`, data)
      .then((res) => {
        dispatch(successShiftTable(data));
        dispatch(getOrders());
        handleVisible(res.data.message);   
      })
      .catch((error) => {
        console.log(error, "dataaaaaaaaaaaaaaaaaaaaaa");
        dispatch(errorShiftTable());
          handleDangerVisible("Table not shifted Try again");
      });
  };
};
export const filterTableByHall = (data) => {
  console.log(data,"jjjjjjjjjjtable")
  return {
      type: "FILTER_TABLE_BY_HALLID",
      payload: data
  };
};