import API from "../../../../../global/api";
// src\global\api.js
import { get, isEmpty } from 'lodash';
// import logo from "../../../../Images/logo.png";
import jsPDF from 'jspdf';
import moment from 'moment';
import { Link } from "react-router-dom";
import {sumBy} from "lodash";

//  Add Opening

export const requestAddDayOpening = () => {
    return {
        type: "REQUEST_ADD_DAY_OPENING",
    };
};
export const successAddDayOpening = (data) => {
    return {
        type: "SUCCESS_ADD_DAY_OPENING",
        payload: data,
    };
};
export const errorAddDayOpening = () => {
    return {
        type: "ERROR_ADD_DAY_OPENING",
    };
};

export const addDayOpening = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDayOpening());
        API.post(`/dayOpen/add_day_open`, data).then((res) => {
            // dispatch(getWarehouseData());
            dispatch(successAddDayOpening(data));
            console.log("i am in success");
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddDayOpening());
            handleDangerVisible(error.response.data.message);
        })
    };
};

//  Get Opening

export const requestGetDayOpening = () => {
    return {
        type: "REQUEST_GET_DAY_OPENING",
    };
};
export const successGetDayOpening = (data) => {
    return {
        type: "SUCCESS_GET_DAY_OPENING",
         payload: data,
    };
};
export const errorGetDayOpening = () => {
    return {
        type: "ERROR_GET_DAY_OPENING",
    };
};

export const getDayOpening = () => {
    return (dispatch) => {
        dispatch(requestGetDayOpening());
        API.get(`/dayOpen/get_day_open`).then((res) => {
            let data=res.data;
            // dispatch(getWarehouseData());
            dispatch(successGetDayOpening(data));
            console.log("i am in success");
            //handleVisible();
        }).catch((error) => {
            dispatch(errorGetDayOpening());
           // handleDangerVisible();
        })
    };
};
//  Add Closing

export const requestAddDayClosing = () => {
    return {
        type: "REQUEST_ADD_DAY_CLOSING",
    };
};
export const successAddDayClosing = (data) => {
    return {
        type: "SUCCESS_ADD_DAY_CLOSING",
        payload: data,
    };
};
export const errorAddDayClosing = () => {
    return {
        type: "ERROR_ADD_DAY_CLOSING",
    };
};

export const addDayClosing = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDayClosing());
        API.post(`/dayOpen/closing_day`, data).then((res) => {
            // dispatch(getWarehouseData());
            dispatch(successAddDayClosing(data));
            console.log(res,"i am in success");
            handleVisible(res.data.message);
            // dispatch(getDayOpening())
        }).catch((error) => {
            dispatch(errorAddDayClosing());
            handleDangerVisible(error.response.data.message);
        })
    };
};

