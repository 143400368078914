import API from "../../../../../global/api";
//get Service And Hybrid Product 
export const requestServiceAndHybridProduct = () => {
    return {
        type: "REQUEST_SERVICE_AND_HYBRID_PRODUCT",
    };
};
export const successServiceAndHybridProduct = (data) => {
    return {
        type: "SUCCESS_SERVICE_AND_HYBRID_PRODUCT",
        payload: data,
    };
};
export const errorServiceAndHybridProduct = () => {
    return {
        type: "ERROR_SERVICE_AND_HYBRID_PRODUCT",
    };
};
export const getServiceAndHybridProduct = () => {
    return (dispatch) => {
        dispatch(requestServiceAndHybridProduct);
        API.get(`/products/service_and_hybrid_product`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successServiceAndHybridProduct(getData));
        }).catch((error) => {
            dispatch(errorServiceAndHybridProduct());
        })
    };
};
///get serivce product
export const requestServiceProduct = () => {
    return {
        type: "REQUEST_SERVICE_PRODUCT",
    };
};
export const successServiceProduct = (data) => {
    return {
        type: "SUCCESS_SERVICE_PRODUCT",
        payload: data,
    };
};
export const errorServiceProduct = () => {
    return {
        type: "ERROR_SERVICE_PRODUCT",
    };
};
export const getServiceProduct = () => {
    return (dispatch) => {
        dispatch(requestServiceProduct);
        API.get(`/products/service_product`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successServiceProduct(getData));
        }).catch((error) => {
            dispatch(errorServiceProduct());
        })
    };
};
//get complete product
export const requestProductTypeProduct = () => {
    return {
        type: "REQUEST_PRODUCT_TYPE_PRODUCT",
    };
};
export const successProductTypeProduct = (data) => {
    return {
        type: "SUCCESS_PRODUCT_TYPE_PRODUCT",
        payload: data,
    };
};
export const errorProductTypeProduct = () => {
    return {
        type: "ERROR_PRODUCT_TYPE_PRODUCT",
    };
};
export const getProductTypeComplete = () => {
    return (dispatch) => {
        dispatch(requestProductTypeProduct);
        API.get(`/products/completeProduct?limit=1000&page=0&search=`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successProductTypeProduct(getData));
        }).catch((error) => {
            dispatch(errorProductTypeProduct());
        })
    };
};
//get variatio by product id
export const requestProductTypeById = () => {
    return {
        type: "REQUEST_PRODUCT_TYPE_BYID",
    };
};
export const successProductTypeById = (data) => {
    return {
        type: "SUCCESS_PRODUCT_TYPE_BYID",
        payload: data,
    };
};
export const errorProductTypeById = () => {
    return {
        type: "ERROR_PRODUCT_TYPE_BYID",
    };
};
export const getProductTypeById = (id) => {
    return (dispatch) => {
        dispatch(requestProductTypeById());
        API.get(`/products/productVariation/${id}`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successProductTypeById(getData));
        }).catch((error) => {
            dispatch(errorProductTypeById());
        })
    };
};
//extra
export const hideForm = (data) => {
    return {
        type: "SET_HIDE_FORM",
        payload: data
    };
};
//Add recipe
export const requestAddRecipe = () => {
    return {
        type: "REQUEST_ADD_RECIPE",
    };
};
export const successAddRecipe = (data) => {
    return {
        type: "SUCCESS_ADD_RECIPE",
        payload: data,
    };
};
export const errorAddRecipe = () => {
    return {
        type: "ERROR_ADD_RECIPE",
    };
};
export const addRecipe = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddRecipe());
        API.post(`/recipe`, data).then((res) => {
             
            
            dispatch(successAddRecipe(data));
            handleVisible(res.data.message);
            // dispatch(getProductTypeProduct());
             dispatch(getRecipeData());
        }).catch((error) => {
            dispatch(errorAddRecipe());
            handleDangerVisible(error.response.data.message);
        })
    };
};
//get recipe by id
export const requestVariationById = () => {
    return {
        type: "REQUEST_VARIATION_BYID",
    };
};
export const successVariationById = (data) => {
    return {
        type: "SUCCESS_VARIATION_BYID",
        payload: data,
    };
};
export const errorVariationById = () => {
    return {
        type: "ERROR_VARIATION_BYID",
    };
};
export const getVariationById = (data) => {
    return (dispatch) => {
        dispatch(requestVariationById);
        API.post(`/recipe/getRecipeByProductId`,data).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successVariationById(getData));
        }).catch((error) => {
            dispatch(errorVariationById());
        })
    };
};
//edit recipe item
export const editAddons = (data) => {
    console.log(data,"testData")
    return {
        type: "EDIT_ADDONS",
        payload: data
    };
};
//get recipe
export const requestRecipeData = () => {
    return {
        type: "REQUEST_GET_RECIPE_DATA",
    };
};
export const successRecipeData= (data) => {
    return {
        type: "SUCCESS_GET_RECIPE_DATA",
        payload: data,
    };
};
export const errorRecipeData = () => {
    return {
        type: "ERROR_GET_RECIPE_DATA",
    };
};
export const getRecipeData = () => {
    return (dispatch) => {
        dispatch(requestRecipeData());
        API.get(`/recipe`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successRecipeData(getData));
        }).catch((error) => {
            dispatch(errorRecipeData());
        })
    };
};
//recipe edit
export const requestRecipeEditById = () => {
    return {
        type: "REQUEST_RECIPEEDIT_BYID",
    };
};
export const successRecipeEditById = (data) => {
    return {
        type: "SUCCESS_RECIPEEDIT_BYID",
        payload: data,
    };
};
export const errorRecipeEditById = () => {
    return {
        type: "ERROR_RECIPEEDIT_BYID",
    };
};
export const getRecipeEditById = (data,id,handleVisible,handleDangerVisible) => {
    return (dispatch) => {
        console.log(data,id,"rrrrrrrrrrr")
        dispatch(requestRecipeEditById);
        API.put(`/recipe/update_recipe/${id}`,data).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successRecipeEditById(getData));
             handleVisible(getData.message)
        }).catch((error) => {
            dispatch(errorRecipeEditById());
            handleDangerVisible("test11")
            console.log(error,"aaaaaaaaaa")
        })
    };
};
// reset
export const resetRecipeReducer = () => {
    return {
        type: "RESET_RECIPE",
    };
};
//filter product
export const filterItem = (data) => {
    console.log(data,"jjjjjjjjjj")
    return {
        type: "FILTER_PRODUCT",
        payload: data
    };
};
//filter by menu
export const filterItemByMenu = (data) => {
    console.log(data,"jjjjjjjjjj")
    return {
        type: "FILTER_ITEM_BY_MENU",
        payload: data
    };
};