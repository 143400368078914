import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Navbar,
} from "react-bootstrap";
import "../add-details.css";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, sumBy, isNull, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
// import WareHouseModal from "../WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Chip } from "@mui/material";
import { cleanFilterItem } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import InputGroup from "react-bootstrap/InputGroup";
import useScanDetection from 'use-scan-detection';
import { SettingsRemote } from "@material-ui/icons";
import { baseURL } from "../../../../../global/api";
import {
  AddOrders,
  deleteOrders,
  editOrder,
  editOrderCoupon,
  editOrderCustomer,
  editOrderDiscount,
  editOrderSubTotal,
  editOrderTotal,
  getCustomersData,
  getCustomersDataWithoutPagination,
  GetOrderById,
  getOrders,
  getOrderTypes,
  getTables,
  OrderEditRoute,
  updateOrders,
} from "./POSView.action";
import { getCoupons } from "../AddCoupons/Coupons.action";
import { getCustomerData } from "../AddCustomer/Customer.action";
import { getProductData } from "../AddProduct/Product.action";
import { getCategoryData } from "../categories/Catagory.action";
import Nav from "react-bootstrap/Nav";
import {
  filterItem,
  filterItemByMenu,
  getProductType,
  getServiceAndHybridProduct,
} from "../Recipe/Recipe.action";
import { getProductTypeById } from "../Recipe/Recipe.action";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { updateStatus } from "../KDS/KDSView.action";
import IconMenu from "../../../drawer/IconMenu";

const POSOrderEditView = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [issuanceType, setIssuanceType] = useState("Full");
  const [issuanceTypeLabel, setIssuanceTypeLabel] = useState("Full");

  const [cardId,setCardId]=useState("");

  const [inputField, setInputField] = useState([]);
  const [inputField1, setInputField1] = useState([]);
  const [categoryId, setcategoryId] = useState();
  const [discountId, setDiscountId] = useState();
  const [discountname, setDiscountname] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState("Manual");
  const [customer, setCustomer] = useState("");
  const [customerLabel, setCustomerLabel] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponLabel, setCouponLabel] = useState("");
  const [table, setTable] = useState("");
  const [tableLabel, setTableLabel] = useState("");
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] = useState(false);
  const [orderType, setOrderType] = useState("1");
  const [orderTypeLabel, setOrderTypeLabel] = useState("DINEIN");
  const [totalAmount, setTotalAmount] = useState(0);
  const [SubTotalAmount, setSubTotalAmount] = useState(totalAmount);
  const [taxAmount, setTaxAmount] = useState(0);
  const [amount, setAmount] = useState("");
  const [productId, setProductId] = useState("");
  const [productLabel, setProductLabel] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [categoryId1, setcategoryId1] = useState();
  const [availableQuantity, setAvailableQuantity] = useState();
  const [orderTypeId, setOrderTypeId] = useState();
  const [orderTypeName, setOrderTypeName] = useState();
  const [barcode, setBarocode] = useState("");
  const [dangerVisibleAlertCoupon, setDangerVisibleAlertCoupon] = useState(false);
  const [afterTaxTotalAmount, setAfterTaxTotalAmount] =
    useState(SubTotalAmount);
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [dangerVisibleAlertQuantity, setDangerVisibleAlertQuantity] = useState(false);
  const [taxLabel, setTax] = useState(0);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  console.log(props.OrderDataByID, "orderList");
  console.log(props.orderEdit, "editlist");
  console.log(props.seatSelected._id, "abcdef");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [couponModal, setCouponModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const handleShowCoupon = () => setCouponModal(true);
  const handleCloseCoupon = () => setCouponModal(false);
  const handleShowCustomer = () => setCustomerModal(true);
  const handleCloseCustomer = () => setCustomerModal(false);


  const handleChangeCategory = (selectedOption) => {
    setcategoryId1(selectedOption.value);
    setcategoryId(selectedOption.label);
    selectedOption.value == "All"
      ? props.getProduct()
      : props.serchByMenu(selectedOption.value);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Order Updated Successfully",
      icon: "success",
      // button: "Ok",
      buttons: {
        // roll: {
        //     text: "Print Reciept",
        //     value: "pr",
        // },
        roll2: {
          text: "Ok",
          value: "ok",
        },
      },
    }).then(function (x) {
      if (x == "ok") {
        navigate("/order")
      }
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong! 111",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase(is_print) {
    console.log(props.orderEdit.coupon, "aaaaaaaaaaaa");
    props.updateOrder(
      {
        items: props.orderEdit.items,
        //  updateitems: props.orderEdit.subOrder,
        grandTotal: props.orderEdit.grandTotal,
        subTotal: props.orderEdit.subTotal,
        discount:props.orderEdit.subTotal-props.orderEdit.grandTotal,
        coupon: isEmpty(props.orderEdit.coupon)
          ? null
          : props.orderEdit.coupon._id,
        customer: props.orderEdit.customer._id,
        tableId: props.seatSelected._id,
        barcodeId:barcode,
        // status:props.orderEdit.status
      },
      props.orderEdit,
      handleVisible,
      handleDangerVisible
    );
    navigate("/order")
    setAdvanceAmount(0);
    setRemainingAmount(0);
    setDiscountPercentage(0);
    setTotalAmount(0);
    setTaxAmount(0);
    setTable("");
    setTableLabel("");
    setCouponLabel("");
    setCustomerLabel("");
    setSubTotalAmount(0);
    setInputField([]);
  }
  const handleClickCoupon = (selectedOption) => {
    const list = [...props.orderEdit.items];
    props.setCouponEdit({
      _id: selectedOption.value,
      name: selectedOption.label,
      type:selectedOption.type,
      amount:selectedOption.amount,
      code:selectedOption.code,
    });
    props.setSubTotal(sumBy(list, "productTotalAmount"));
    
    let dis =
      selectedOption.type == "fixed"
        ? selectedOption.amount
        : sumBy(list, "productTotalAmount") * selectedOption.amount * 0.01;
        props.setDiscount(dis)
    props.setGrandTotal(sumBy(list, "productTotalAmount") - dis);
    handleCloseCoupon()
  };
  const handleClick = (selectedOption) => {
    props.setCustomerEdit({
      _id: selectedOption.value,
      name: selectedOption.label,
    });
    handleCloseCustomer()
  };
  const handleDelete = () => {
    console.log("delete ho");
    setCustomer("");
    setCustomerLabel("");
  };
  const handleDeleteCoupon = () => {
    const list = [...props.orderEdit.items];
    props.setCouponEdit(null);
    //let dis=selectedOption.type == "fixed" ? selectedOption.amount : props.orderEdit.subTotal* selectedOption.amount * 0.01
    props.setSubTotal(sumBy(list, "productTotalAmount"));
    props.setGrandTotal(sumBy(list, "productTotalAmount"));
    props.setDiscount(0)
    console.log("delete ho");
    setCoupon("");
    setCouponLabel("");
    setSubTotalAmount(totalAmount);
  };

  const getDiscountList = !isEmpty(props.couponsDataList)
    ? props.couponsDataList.map((x) => {
        let data = {
          value: x._id,
          label: x.name,
          type: x.type,
          amount: x.amount,
        };
        return data;
      })
    : [];
  console.log(getDiscountList, "uuuuuuuuuu");
  const getCustomersList = !isEmpty(props.customerDataList)
    ? props.customerDataList.map((x) => {
        let data = {
          value: x._id,
          label: `${x.name}`, //-${x.customer_cnic}-${x.customer_phone}`,
        };
        return data;
      })
    : [];
  console.log(getCustomersList, getDiscountList, "customer");
  const getOrderTypesList = !isEmpty(props.orderTypesList)
    ? props.orderTypesList.map((x) => {
        let data = { value: x.order_type_id, label: x.order_type_name };
        return data;
      })
    : [];
  const variationData = (item) => {
    console.log(item, "aaaaaaaaaaa");
    setAvailableQuantity(item.productBalance);
    setProductId(item._id);
    setProductLabel(item.name);
    if (item.productType._id == "6433dd117aad3eb039c1744f") {
      let damy = { price: item.salePrice, label: item.name, value: item._id, };
      console.log(damy, "bbbbbbb");
      insertItem(damy);
    } else {
      handleShow();
      setProductId(item._id);
      setProductLabel(item.name);
      props.getProductVariation(item._id);
      console.log(item, "today1");
    }
  };
  const getVariationList = !isEmpty(props.productTypeListById)
    ? props.productTypeListById[0].variation.map((x) => {
        let data = { value: x._id, label: `${x.flavorLabel}-${x.sizeLabel}-`, price: x.sellingPrice, };
        return data;
      })
    : [];
  console.log(getVariationList, "today2");
  const handleRemoveInput = (id) => {
    const list = [...props.orderEdit.items];
    let index = list.findIndex((item) => item.productVariationId === id);
    list.splice(index, 1);
    // setTotalAmount(sumBy(list, "productTotalAmount"));
    // setSubTotalAmount(
    //   couponDiscount == 0
    //     ? sumBy(list, "productTotalAmount")
    //     : sumBy(list, "productTotalAmount") - couponDiscount
    // );
    props.setSubTotal(sumBy(list, "productTotalAmount"));

    let dis = isEmpty(props.orderEdit.coupon)
      ? 0
      : props.orderEdit.coupon.type == "fixed"
      ? 
      props.orderEdit.coupon.amount
     
      : (Number(props.orderEdit.subTotal) *
      Number(props.orderEdit.coupon.amount)) /
    100 ;
    console.log(dis, "bbbbbbbb");
    props.setGrandTotal(
      props.orderEdit.discount == 0
        ? sumBy(list, "productTotalAmount")
        : sumBy(list, "productTotalAmount") - dis
    );
    props.setDiscount(dis);
    props.editOrder(list);
  };
  let getcategoryList =
    !isEmpty(props.categoryList.data) &&
    props.categoryList.data.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  !isEmpty(props.categoryList.data) &&
    getcategoryList.push({ value: "All", label: "ALL" });
  const changeItemQuanity = (item, symbol) => {
    const list = [...props.orderEdit.items];
    console.log(item, "sssssssss");
    let index = list.findIndex(
      (itemInv) => itemInv.productVariationId === item.productVariationId
    );
    console.log(index, availableQuantity, "iiii");
    if (symbol == 1) {
      if(checkQuantity(item)==true){
        list[index]["quantity"] = Number(list[index]["quantity"]) + 1;
        list[index]["productTotalAmount"] =
          Number(list[index]["productSellingPrice"]) *
          Number(list[index]["quantity"]);
      }
      else{
        props.editOrder(list);
      }
      props.setSubTotal(sumBy(list, "productTotalAmount"));
      let dis = isEmpty(props.orderEdit.coupon)
        ? 0
        : props.orderEdit.coupon.type == "fixed"
        ? props.orderEdit.coupon.amount
        
        : (Number(props.orderEdit.subTotal) *
        Number(props.orderEdit.coupon.amount)) /
      100 ;
      console.log(dis, "bbbbbbbb");
      props.setDiscount(dis)
      props.setGrandTotal(
        props.orderEdit.discount == 0
          ? sumBy(list, "productTotalAmount")
          : sumBy(list, "productTotalAmount") - dis
      );
    
      props.editOrder(list);
    } else {
      list[index]["quantity"] =
        Number(list[index]["quantity"]) == 1
          ? Number(list[index]["quantity"])
          : Number(list[index]["quantity"]) - 1;
      list[index]["productTotalAmount"] =
        Number(list[index]["quantity"]) == 1
          ? Number(list[index]["productSellingPrice"]) * 1
          : Number(list[index]["productSellingPrice"]) *
            Number(list[index]["quantity"]);
      props.setSubTotal(sumBy(list, "productTotalAmount"));
      let dis = isEmpty(props.orderEdit.coupon)
        ? 0
        : props.orderEdit.coupon.type == "fixed"
        ? props.orderEdit.coupon.amount
        : (Number(props.orderEdit.subTotal) *
        Number(props.orderEdit.coupon.amount)) /
      100 ;
      console.log(dis, "bbbbbbbb");
      props.setGrandTotal(
        props.orderEdit.discount == 0
          ? sumBy(list, "productTotalAmount")
          : sumBy(list, "productTotalAmount") - dis
      );
      props.setDiscount(dis)
      props.editOrder(list);
    }
    // props.setSubTotal(sumBy(list,'productTotalAmount'))
    // let dis=isEmpty(props.orderEdit.coupon)?0:props.orderEdit.coupon.type=="percentage"?(Number(props.orderEdit.subTotal)*Number(props.orderEdit.coupon.amount))/100:props.orderEdit.coupon.amount
    // console.log(dis,"bbbbbbbb")
    // props.setGrandTotal(props.orderEdit.discount==0?sumBy(list, "productTotalAmount"):sumBy(list, "productTotalAmount")-dis)
    // props.editOrder(list);
  };

  const insertItem = (item, index) => {
    
    !isEmpty(props.orderEdit.coupon) && console.log(props.orderEdit.coupon.amount, "1122");
    const list = [...props.orderEdit.items];
    // const listCheck = !isEmpty(list)
    // ? list.map((x) => {
    //     let data = {
    //       value: x.itemStatus,
    //     };
    //     return data;
    //   })
    // : [];
  //   let index1 = list.findIndex(
  //     (itemInv) => itemInv.itemStatus === "Pending" 
  //   );
  //  console.log(index1,"ccccccccccccccccccccccccccccc")
  //  let check=isEmpty(find(list[index1], { itemStatus: "Pending" }))
  //  if(check==true){

    if (isEmpty(find(list, { productVariationId: item.value }))) {
      console.log(item, "sssssif");
      let dummyItem = {};
      dummyItem["product"] = productId == "" ? item.value : productId;
      dummyItem["productLabel"] =
        productLabel == "" ? item.label : productLabel;
      dummyItem["productVariationId"] = item.value;
      dummyItem["itemStatus"] = "Pending";

      dummyItem["productVariationLabel"] = item.label;
      dummyItem["quantity"] = 1;
      dummyItem["productSellingPrice"] = Number(item.price);
      dummyItem["productTotalAmount"] = 1 * Number(item.price);
      let listData = [...list, dummyItem];
      props.setSubTotal(sumBy(listData, "productTotalAmount"));
    
      let dis = 
      isEmpty(props.orderEdit.coupon)
        ? 0
        : props.orderEdit.coupon.type == "fixed"
        ? props.orderEdit.coupon.amount
       
        : (Number(props.orderEdit.subTotal) *
        Number(props.orderEdit.coupon.amount)) /
      100 ;
      console.log(dis, "bbbbbbbb");
      props.setDiscount(dis)
      props.setGrandTotal(
        props.orderEdit.discount == 0
          ? sumBy(listData, "productTotalAmount")
          : sumBy(listData, "productTotalAmount") - dis
      );
      props.editOrder(listData);
      //setInputField(listData);
      handleClose();
      setProductId("");
      setProductLabel("");
      console.log(item,props.orderEdit.coupon, "sssssif");
    } 
    else {
      console.log(item,list, "ssssselse");
      let index = list.findIndex(
        (itemInv) => itemInv.productVariationId === item.value 
      );
       if(checkQuantity(list[index])==true){
      // console.log(index, "kkkkkkk11111");
      // list[index]["quantity"] = Number(list[index]["quantity"]) + 1;
      // list[index]["productTotalAmount"] =
      //   Number(list[index]["productSellingPrice"]) *
      //   Number(list[index]["quantity"]);
      console.log(item,"tttttttttttttttttt434")
      list[index]["quantity"] = Number(list[index]["quantity"]) + 1;
      list[index]["productTotalAmount"] =
        Number(list[index]["productSellingPrice"]) *
        Number(list[index]["quantity"]);
      }
      else{
      props.editOrder(list)
    }
    props.setSubTotal(sumBy(list, "productTotalAmount"));
    let dis = isEmpty(props.orderEdit.coupon)
      ? 0
      : props.orderEdit.coupon.type == "fixed"
      ? props.orderEdit.coupon.amount
      
      : (Number(props.orderEdit.subTotal) *
      Number(props.orderEdit.coupon.amount)) /
    100 ;
    console.log(dis, "bbbbbbbb");
    props.setDiscount(dis)
    props.setGrandTotal(
      props.orderEdit.discount == 0
        ? sumBy(list, "productTotalAmount")
        : sumBy(list, "productTotalAmount") - dis
    );
  
    props.editOrder(list);
    handleClose();
      // props.setSubTotal(sumBy(list, "productTotalAmount"));
      // let dis = !isEmpty(props.orderEdit.coupon)
      // ? (props.orderEdit.coupon.type = "fixed"
      //     ? props.orderEdit.coupon.amount
      //     : (sumBy(list, "productTotalAmount") *
      //         Number(props.orderEdit.coupon.amount)) /
      //       100)
      // : 0;
      //  props.setDiscount(dis)
      // props.setGrandTotal(
      //   props.orderEdit.discount == 0
      //     ? sumBy(list, "productTotalAmount")
      //     : sumBy(list, "productTotalAmount") - dis
      // );
      // handleClose();
      // setProductId("");
      // setProductLabel("");
      // //setInputField(list);

      // props.editOrder(list);
    
    // else{
    //   props.editOrder(list)
    // }
    }
  // }
  // else{
  //   // console.log(item, "sssssif");
  //     let dummyItem = {};
  //     dummyItem["product"] = productId == "" ? item.value : productId;
  //     dummyItem["productLabel"] =
  //       productLabel == "" ? item.label : productLabel;
  //     dummyItem["productVariationId"] = item.value;
  //     dummyItem["itemStatus"] = "Pending";

  //     dummyItem["productVariationLabel"] = item.label;
  //     dummyItem["quantity"] = 1;
  //     dummyItem["productSellingPrice"] = Number(item.price);
  //     dummyItem["productTotalAmount"] = 1 * Number(item.price);
  //     let listData = [...list, dummyItem];
  //     props.setSubTotal(sumBy(listData, "productTotalAmount"));
    
  //     let dis = 
  //     isEmpty(props.orderEdit.coupon)
  //       ? 0
  //       : props.orderEdit.coupon.type == "fixed"
  //       ? props.orderEdit.coupon.amount
       
  //       : (Number(props.orderEdit.subTotal) *
  //       Number(props.orderEdit.coupon.amount)) /
  //     100 ;
  //     console.log(dis, "bbbbbbbb");
  //     props.setDiscount(dis)
  //     props.setGrandTotal(
  //       props.orderEdit.discount == 0
  //         ? sumBy(listData, "productTotalAmount")
  //         : sumBy(listData, "productTotalAmount") - dis
  //     );
  //     props.editOrder(listData);
  //     //setInputField(listData);
  //     handleClose();
  //     setProductId("");
  //     setProductLabel("");
  //     console.log(item,props.orderEdit.coupon, "sssssif");
  // }

  };

  let ProductTypeData = [
    {
      orderTypeId: 2,
      orderTypeName: "Take Away",
    },
    {
      orderTypeId: 3,
      orderTypeName: "Delivery",
    },
    {
      orderTypeId: 4,
      orderTypeName: "Drive Throw",
    },
  ];
  const ProductTypeList =
    !isEmpty(ProductTypeData) &&
    ProductTypeData.map((x) => {
      let data = { value: x.orderTypeId, label: x.orderTypeName };
      return data;
    });

  const handleChangeOrderType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setOrderTypeName(selectedOption.label);
    setOrderTypeId(selectedOption.value);
  };
  const handlePaymentType = (type) => {
    if (props.restaurantList[0].taxType == "FBRTax") {
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTax(props.restaurantList[0].fbrTaxCash);
        let taxCash =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].fbrTaxCash)) /
          100;
        setTaxAmount(taxCash);
        console.log(props.restaurantList[0], taxCash, "taxxxxxxxxxxxxx");
        setAfterTaxTotalAmount(Number(taxCash) + Number(SubTotalAmount));
      } else {
        setCard(1);
        setCash(0);
        setTax(props.restaurantList[0].fbrTaxCard);
        let taxCard =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].fbrTaxCard)) /
          100;
        setTaxAmount(taxCard);
        console.log(
          props.restaurantList[0].fbrTaxCard,
          taxCard,
          "taxxxxxxxxxxxxx"
        );
        setAfterTaxTotalAmount(Number(taxCard) + Number(SubTotalAmount));
      }
    } else {
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTax(props.restaurantList[0].praTaxCash);
        let taxCash =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].praTaxCash)) /
          100;
        console.log(props.restaurantList[0], taxCash, "taxxxxxxxxxxxxx");
        setTaxAmount(taxCash);
        setAfterTaxTotalAmount(taxCash + SubTotalAmount);
      } else {
        setCard(1);
        setCash(0);
        setTax(props.restaurantList[0].praTaxCard);
        let taxCard =
          (Number(SubTotalAmount) *
            Number(props.restaurantList[0].praTaxCard)) /
          100;
        setTaxAmount(taxCard);
        console.log(
          props.restaurantList[0].praTaxCard,
          taxCard,
          "taxxxxxxxxxxxxx"
        );
        setAfterTaxTotalAmount(taxCard + SubTotalAmount);
      }
    }
  };
  const handleDangerVisibleCopon = () => {
    setDangerVisibleAlertCoupon(true);
    setTimeout(() => {
      setDangerVisibleAlertCoupon(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Total Order Amount Is Less Than Paid Amount, Please Change The Coupon",
      icon: "error",
      button: "Ok",
    });
  };
  const handleDangerVisibleQuantity = () => {
    setDangerVisibleAlertQuantity(true);
    setTimeout(() => {
      setDangerVisibleAlertQuantity(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "low Quantity",
      icon: "info",
      button: "Ok",
    });
  };
  const checkQuantity=(item)=>{
    console.log(item,availableQuantity,"checkQ")
    let dumyAvailableQuantity=availableQuantity==0?2:Number(availableQuantity)
    console.log(dumyAvailableQuantity,"checkQtt")
    if ( item.product == item.productVariationId && Number(item.quantity) >= dumyAvailableQuantity) {
      console.log("iftrue")
      handleDangerVisibleQuantity()
      return false;
      }
      else{
        return true;
      }
  }
  const handleDangerVisibleProduct = () => {
    setDangerVisibleAlertProduct(true);
    setTimeout(() => {
      setDangerVisibleAlertProduct(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Product Found",
      icon: "error",
      button: "Ok",
    });
  };
  const [cardName,setCardName]=useState("");
  const handleClickCategory = (item) => {
    setCardId(item.value)
    setCardName(item.label)
    // setcategoryId1(selectedOption.value);
    // setcategoryId(selectedOption.label);
    // console.log(selectedOption.value, "valueeeeeeeeeeee");
    item.value == "All"
      ? props.getProduct()
      : 
      props.serchByMenu(item.value);
  };
//   const barcod=(id)=>{
//     console.log(id,props.productSearchList,"testtttttttttttttt")
//   let i=  find(props.productSearchList,{barcodeId:id});
//   console.log(i,"tttttttttttttttt")
//   isEmpty(i)?handleDangerVisibleProduct():
//   variationData(i)

//   }
//   useScanDetection({
//     onComplete: barcod,
//   //  minLength: 13 // EAN13
// });
const permissions=JSON.parse(window.localStorage.getItem("permissions"))
const Checkpermissions = (permissionsType,permissionsName) => {
  return permissions.some(p => p[`${permissionsType}`] === permissionsName)?true:false
 }
  useEffect(() => {
    props.getOrderById(state.id);
    props.getCoupons();
    props.getCustomer();
    props.getProduct();
    props.getCatagoery();
  }, []);
  return (
    <>
      <div >
    <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Point of Sale</b></Navbar.Brand>
        {/* <div className="sendDiv" style={{ marginTop: "15px" }}>
              <Link to="/table">
                <Button> Back</Button>
              </Link>
            </div> */}
        <IconMenu/>
      </Container>
    </Navbar>
      </div>
      {props.isFetchingServiceAndHybridProductList ||
      props.isFetchingCouponsData ||
      props.isFetchingCustomer ||
      props.isAddingOrders ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingOrders ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (

        <Container fluid>
          {console.log(props.orderEdit,"props.orderEdit")}
          {/* <div className="main"> */}
          <div  style={{
        padding: '4px',
        // backgroundColor: '#08c708',
        width: '100%',
        overflow: 'auto',
        whiteSpace: 'nowrap',}}>
 {/* <Button> */}
{!isEmpty(getcategoryList)
  ? getcategoryList.map((item, i) => {
      return (
        <React.Fragment key={i}>
           {/* <Card
              style={{marginTop:'1%', cursor:'pointer',height:'auto',backgroundColor:item.value==cardId?'#c1c1c1':'#81abd3' }}
              onClick={(e) => handleClickCategory(item)}
            > */}
            <button style={{display: 'inline-block',
  // color: white,
  backgroundColor:'#81abd3',
  textAlign: 'center',
  padding: '14px',
  marginRight:'10px',
  textDecoration: 'none',
  cursor:'pointer',
borderStyle:'none'}}
onClick={(e) => handleClickCategory(item)}
  >
                <b   >  {item.label}</b>
               </button>
              
            {/* </Card> */}

          </React.Fragment>
      );
    })
  : []}
  {/* </Button> */}
        {/* a ba aba ababaa baujbjwdbxejk cx cbewkjbcfec fecbfejcbf cfckihbfcj fcfjc fjc fcjfc fcfeubckjjfke cec cjhvceg cxecxehjcvxeucxe xjehvcxe cxecjvevc eceh  ce cxejjceh cxefmcjehy bc em cec ec e ceh chefbcbkefhfbbch dwx jg ssj xeewcx e cwj cxkew  cxfej cxjxdmasx edc fejh cxwdmscx ed  cjwhc xdwjjh */}
       </div>
            <Row style={{overflowX:'hidden'}}>
            {/* <Col   xl="2"
                lg="2"
                md="2"
                sm="3"
                // style={{marginLeft:0}}
                >
                   <Row
                  style={{ height: '85vh', overflow: "auto" }}
                  className="overflow-auto"
                >
               
            {!isEmpty(getcategoryList)
                    ? getcategoryList.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Col xl="12" lg="12" md="12" sm="12">
                              <Card
                                style={{marginTop:'1%', cursor:'pointer',height:'auto',backgroundColor:item.value==cardId?'#c1c1c1':'#81abd3' }}
                                onClick={(e) => handleClickCategory(item)}
                              >
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                    component="div"
                                     style={{
                                  marginTop:'-10px',
                                  marginBottom:'-10px'
                                     }}
                                  >
                                  <b>  {item.label}</b>
                                 
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Col>
                          </React.Fragment>
                        );
                      })
                    : []}</Row></Col> */}
              <Col
                xl="7"
                lg="7"
                md="6"
                sm="6"
                style={{
                  borderRightStyle: "solid",
                  borderRightColor: "#e9ecef",
                }}
              >
                <Row>
                  <Col xl="3" lg="3" md="6" sm="6">
                    <Form.Group>
                      <Form.Label>
                        <b>Search Items</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Items..."
                        name="receivedBy"
                        onChange={(e) => props.searchProduct(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="3" lg="3" md="6" sm="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b> Select Menu</b>
                      </Form.Label>
                      <Select
                        value={{ label: categoryId }}
                        placeholder="Select Category..."
                        onChange={(e) => handleChangeCategory(e)}
                        options={
                          isEmpty(getcategoryList)
                            ? []
                            : getcategoryList.reverse()
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="3" lg="3" md="6" sm="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Order Type</b>
                      </Form.Label>
                      <Select
                        value={{ label: orderTypeName }}
                        isDisabled={
                          isEmpty(props.seatSelected._id) ? false : true
                        }
                        placeholder="Order Type..."
                        onChange={(e) => handleChangeOrderType(e)}
                        options={ProductTypeList}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col xl="3" lg="3" md="6" sm="6">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Bar Code Scaner</b></Form.Label>
                                    <Form.Control type="number" placeholder="Bar Code number...." value={barcode} disabled onBlur={(e) => setBarocode(e.target.value)} />
                                </Form.Group>
                            </Col> */}
                </Row>
                {cardName!=""&&
<Row><Col>
<Card style={{height:'50px',backgroundColor:'#81abd3',marginBottom:'5px'}}>
                            <CardActionArea >
                          
                              <CardContent>
                                
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                    component="div"
                                    style={{fontSize:'12px'}}
                                  >
                                 <p style={{textAlign:'center',fontSize:'16px'}}>  <b >{cardName}</b></p>
                                  </Typography>
                                
                              </CardContent>
                            </CardActionArea>
                          </Card></Col></Row>}
                <Row style={{ height: props.productSearchList.length>23?'80vh': 'auto', overflow: "auto" }}
                  className="overflow-auto">
                  {props.productSearchList.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Col
                          xl="3"
                          lg="3"
                          md="6"
                          sm="6"
                          style={{ marginBottom: 20 }}
                        >
                          <Card style={{height:'170px',backgroundColor:'#c1c1c1'}} sx={{ opacity:item.productType._id=="6433dd117aad3eb039c1744f"&& item.productBalance<=0 ? 0.5 : 1,pointerEvents:item.productType._id=="6433dd117aad3eb039c1744f"&& item.productBalance<=0 ? "none" : "auto"}} onClick={(e) => variationData(item)}>
                            <CardActionArea>
                               <CardMedia
                                component="img"
                                height="120px"
                                width="100px"
                                image={`${item.images}`}
                                alt="Product"
                              /> 
                              <CardContent>
                                {!isEmpty(
                                  props.serviceAndHybridProductList
                                ) && (
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                     component="div"
                                    style={{fontSize:'12px',}}
                                  >
                                    {item.name}
                                  </Typography>
                                )}
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </Col>

              <Col xl="5" lg="5" md="6" sm="6">
                <div>
                  <Row> 
                     <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Order #:</b>
                        </Form.Label>
                        <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                                placeholder={!isEmpty(props.orderEdit)&&props.orderEdit.number}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                      </Form.Group>
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Status:</b>
                        </Form.Label>
                        <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                                placeholder={!isEmpty(props.orderEdit)&&props.orderEdit.status}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                      </Form.Group>
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Table:</b>
                        </Form.Label>
                        <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                                placeholder={!isEmpty(props.orderEdit)&&props.orderEdit.tableName}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                      </Form.Group>
                    </Col>
                    </Row>
                {Checkpermissions("permissionsCatName","Assign Coupan")&&
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Coupons</b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl="1" lg="2" md="2" sm="4">
                      <AddCircleOutlineIcon
                        style={{ marginTop: "9px", cursor: "pointer" }}
                        onClick={handleShowCoupon}
                      ></AddCircleOutlineIcon>
                    </Col>
                    {isEmpty(props.orderEdit.coupon) ||
                    isNull(props.orderEdit.coupon._id) ? (
                      ""
                    ) : (
                      <Col xl="4" lg="6" md="6" sm="12">
                        <Chip
                          color="primary"
                          label={
                            isEmpty(props.orderEdit.coupon)
                              ? ``
                              : props.orderEdit.coupon.name
                          }
                          onDelete={handleDeleteCoupon}
                          style={{ marginTop: "5px", marginLeft: "-10px" }}
                        ></Chip>
                      </Col>
                    )}
                
                  </Row>}
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Customer</b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl="1" lg="2" md="2" sm="4">
                      <AddCircleOutlineIcon
                        style={{ marginTop: "9px", cursor: "pointer" }}
                        onClick={handleShowCustomer}
                      ></AddCircleOutlineIcon>
                    </Col>
                    {isEmpty(props.orderEdit) ? (
                      ""
                    ) : (
                      <Col xl="4" lg="6" md="6" sm="12">
                        <Chip
                          color="primary"
                          //style={{ marginTop: "5px", marginLeft: "-10px" }}
                          label={
                            isEmpty(props.orderEdit.customer)
                              ? customerLabel
                              : props.orderEdit.customer.name
                          }
                          onDelete={handleDelete}
                        ></Chip>
                      </Col>
                    )}
                  
                  </Row>
                </div>
                <Row>
                  <Col xl="1" lg="1" md="2" sm="6">
                    <h6>
                      <b>Sr #</b>
                      
                    </h6>
                  </Col>
                  <Col xl="5" lg="5" md="5" sm="6">
                    <h6>
                      <b>Product</b>
                    </h6>
                  </Col>
                  {/* <Col xl="2" lg="2" md="12" sm="12">
                    <h6>
                      <b>Price</b>
                    </h6>
                  </Col> */}
                  <Col xl="3" lg="3" md="3" sm="6" style={{marginLeft:'3%'}}>
                    <h6>
                      <b>Qty</b>
                    </h6>
                  </Col>
                  <Col xl="3" lg="3" md="2" sm="6" style={{marginLeft:'-5%'}}>
                    <h6>
                      <b>Total</b>
                    </h6>
                  </Col>
                </Row>
                <Row
                  style={{
                    height: "355px",
                     maxHeight:'355px',
                    overflowY: "auto",
                    overflowX: "hidden",
                    marginRight:'-30px'
                  }}
                >
                  {/* {console.log(damyarry,"abab")} */}
                  <Col xl="12" lg="12" md="12" sm="12">
                  {!isEmpty(props.orderEdit) &&
                    props.orderEdit.items.map((item, i) => {
                      console.log(item,inputField1, "ttttttt123");
                      return (
                        <Row
                          style={{
                            // backgroundColor: !(i % 2 == 0)
                            //   ? "#f1f4f5"
                            //   : "white",
                            border: 5,
                          }}
                        >
                              <Col xl="1" lg="1" md="1" sm="3">
                          <Form.Group className="mb-1 pt-2">
                              <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px',backgroundColor:'#81abd3',fontWeight:'bold'}}
                                placeholder={`${i+1}`}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="5" lg="5" md="5" sm="9" >
                            <Form.Group className="mb-1 pt-2">
                              <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                              //  value={item.productLabel + item.productVariationId=="648abfabd5fe0c341a4d4d0f"?"":"("+ item.productVariationLabel+")"}
                                 placeholder={`${item.productLabel}-${item.productVariationLabel}`}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="3" lg="3" md="3" sm="6">
                            <InputGroup className="mb-1 pt-2 pt-2">
                            {/* {item.itemStatus=="Pending"&& */}
                              <RemoveCircleOutlineOutlinedIcon
                                style={{ marginTop: "8px", cursor: "pointer", }}
                                
                                onClick={() => changeItemQuanity(item, 0)}
                              ></RemoveCircleOutlineOutlinedIcon>
                              <Form.Control
                                value={props.orderEdit.items[i].quantity}
                                //  value={props.orderEdit.items[i].quantity}
                                aria-label="Product Name"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                              />
                              {/* {item.itemStatus=="Pending"&& */}
                              <AddCircleOutlineIcon
                                style={{ marginTop: "8px", cursor: "pointer" }}
                                onClick={() => changeItemQuanity(item, 1)}
                              ></AddCircleOutlineIcon>
                            </InputGroup>
                          </Col>
                          {/* <Col xl="3" lg="6" md="6" sm="6">
                            <Form.Group className="mb-1 pt-2">
                              <Form.Control
                                type="text"
                                disabled
                                style={{fontSize:11,padding:'3px'}}
                              //  value={item.productLabel + item.productVariationId=="648abfabd5fe0c341a4d4d0f"?"":"("+ item.productVariationLabel+")"}
                                 placeholder={`${item.productLabel}-${item.productVariationLabel}`}
                                name="receivedBy"
                                onChange={(e) =>
                                  props.filterItem(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col> */}
                          <Col xl="3" lg="3" md="3" sm="6" style={{marginLeft:'-10px'}}>
                            <InputGroup className="mb-1 pt-2">
                              <Form.Control
                                placeholder={item.productTotalAmount}
                                aria-label="Total Amount"
                                disabled
                                style={{fontSize:11,padding:'1px'}}
                              />
                              {/* {item.itemStatus=="Pending"&& */}
                              <RemoveCircleOutlineOutlinedIcon
                                style={{ cursor: "pointer" }}
                                // className="ml-3 mb-3 mt-2"
                                onClick={() =>
                                  handleRemoveInput(item.productVariationId)
                                }
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      );
                    })}
                    </Col>
                </Row>
               
                
                <Row style={{marginTop:'10px',paddingBottom:'10px',backgroundColor:'#81abd3'}}>
                  <Col xl="4" lg="4" md="4" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Grand Total</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={props.orderEdit.subTotal}
                        disabled
                        name="receivedBy"
                        onChange={(e) => props.filterItem(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Discount</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={props.orderEdit.discount}
                        disabled
                        name="receivedBy"
                        onChange={(e) => props.filterItem(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Total Bill</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          props.orderEdit.grandTotal
                        }
                        disabled
                        name="receivedBy"
                        onChange={(e) => props.filterItem(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col xl="4" lg="4" md="4" sm="4">
                    <Form.Group>
                      <Form.Label>
                        <b>Discounted Total</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          props.editOrder.subTotal
                        }
                        disabled
                        name="receivedBy"
                        onChange={(e) => props.filterItem(e.target.value)}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
                <Row>
                  <Col
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    className="d-flex justify-content-center mt-1"
                  >
                    <Button
                      //  disabled={isEmpty(inputField)?true:false}
                      // onClick={() => openPaymentMethodModal()}
                       onClick={() =>props.orderEdit.grandTotal<0?handleDangerVisibleCopon():  uploadPurchase(0)}
                      style={{ marginRight: 20,backgroundColor:'#81abd3' }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Variation</Modal.Title>
              </Modal.Header>
              {props.isFetchingProductTypeById ? (
                <div
                  className="loader-div "
                  style={{ margin: "10%", marginLeft: "40%" }}
                >
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading
                    {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                  </Button>
                </div>
              ) : (
                <Modal.Body>
                  {" "}
                  {getVariationList.map((x, i) => {
                    console.log(x, i, "llllllllll");

                    return (
                      <React.Fragment key={i}>
                        <Card
                          style={{
                            margin: "10px",
                            backgroundColor: "#1976d2",
                          }}
                          onClick={(e) => insertItem(x, i)}
                        >
                          <CardActionArea>
                            <CardContent>
                              {!isEmpty(getVariationList) && (
                                <Typography
                                  gutterBottom
                                  variant="body3"
                                  component="div"
                                >
                                  <b style={{ color: "white" }}>
                                    {x.label}
                                    <span
                                      style={{
                                        float: "right",
                                      }}
                                    >
                                      {x.price}
                                    </span>
                                  </b>
                                </Typography>
                              )}
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </React.Fragment>
                    );
                  })}
                </Modal.Body>
              )}
            </Modal>
            {/* modal for cash or card payment */}
            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Payment method</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="4">
                    {" "}
                  </Col>
                  <Col xl="2" lg="2" md="2" sm="2">
                    {" "}
                    <Chip
                      color="primary"
                      label={"Cash"}
                      onClick={(e) => handlePaymentType("cash")}
                    ></Chip>
                  </Col>
                  <Col xl="2" lg="2" md="2" sm="2">
                    {" "}
                    <Chip
                      color="primary"
                      label={"Card"}
                      onClick={(e) => handlePaymentType("card")}
                    ></Chip>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4">
                    {" "}
                  </Col>
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>Total Amount</span>
                    <span style={{ float: "right" }}>{totalAmount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>{couponLabel}</span>
                    <span style={{ float: "right" }}>{couponDiscount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  ----------------------------------------------------------------------------
                </Row>
                <Row>
                  <Form.Label>
                    {/* <span style={{float:'left'}}>D</span> */}
                    <span style={{ float: "right" }}>{SubTotalAmount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>
                      Tax({taxLabel}%) on{" "}
                      {cash == 0 && card == 0
                        ? ""
                        : cash == 1
                        ? "Cash"
                        : card == 1
                        ? "Card"
                        : ""}
                    </span>
                    <span style={{ float: "right" }}>{taxAmount}</span>
                  </Form.Label>
                </Row>
                <Row>
                  ----------------------------------------------------------------------------
                </Row>
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>
                      Total Amount After Tax
                    </span>
                    <span style={{ float: "right" }}>
                      {afterTaxTotalAmount}
                    </span>
                  </Form.Label>
                </Row>
                {/* <span style={{float:'left'}}>Total Amount</span> */}
                {/* <span style={{float:'right'}}>{totalAmount}</span> */}
                {/* <Col xl="4" lg="4" md="4" sm="4">
                  <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Total Amount:</b>
                      </Form.Label>
                      <Form.Control
                        placeholder="Total Amount ...."
                        value={totalAmount}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="4">
                  <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Discounted Amount:</b>
                      </Form.Label>
                      <Form.Control
                        placeholder="Discounted Amount ...."
                        value={SubTotalAmount}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Total Amount After Tax :</b>
                      </Form.Label>
                      <Form.Control
                        placeholder="Total Amount After Tax ...."
                        value={afterTaxTotalAmount}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col> */}

                {/* </Row> */}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => uploadPurchase()}
                  disabled={cash == 1 || card == 1 ? false : true}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
              {/* coupon modal */}

              <Modal show={couponModal} onHide={handleCloseCoupon} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Coupon</Modal.Title>
              </Modal.Header>
              {props.isFetchingCouponsData ? (
                <div
                  className="loader-div "
                  style={{ margin: "10%", marginLeft: "40%" }}
                >
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading
                    {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                  </Button>
                </div>
              ) : (
                <Modal.Body>
                  {" "}
                  {getDiscountList.map((x, i) => {
                                //     console.log(x, i, "llllllllll");
                                return (
                                  <React.Fragment key={i}>
                                    <Chip
                                      style={{ margin: "3px" }}
                                      label={x.label}
                                      color="primary"
                                      onClick={(e) => handleClickCoupon(x)}
                                      // onDelete={handleDelete}
                                    />
                                  </React.Fragment>
                                );
                              })}
                </Modal.Body>
              )}
            </Modal>
            {/* customer modal */}

            <Modal show={customerModal} onHide={handleCloseCustomer} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Customer</Modal.Title>
              </Modal.Header>
              {props.isFetchingCustomer ? (
                <div
                  className="loader-div "
                  style={{ margin: "10%", marginLeft: "40%" }}
                >
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading
                    {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                  </Button>
                </div>
              ) : (
                <Modal.Body>
                  {" "}
                  {getCustomersList.map((x, i) => {
                                //     console.log(x, i, "llllllllll");
                                return (
                                  <React.Fragment key={i}>
                                    <Chip
                                      style={{ margin: "3px" }}
                                      color="primary"
                                      label={x.label}
                                      onClick={(e) => handleClick(x)} //(e) => handleChangeDiscount(e)
                                      // onDelete={handleDelete}
                                    />
                                  </React.Fragment>
                                );
                              })}
                </Modal.Body>
              )}
            </Modal>
          {/* </div> */}
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: () => dispatch(getServiceAndHybridProduct()),
    setCouponEdit: (data) => dispatch(editOrderCoupon(data)),
    setCustomerEdit: (data) => dispatch(editOrderCustomer(data)),
    setGrandTotal: (data) => dispatch(editOrderTotal(data)),
    setDiscount:(data)=>dispatch(editOrderDiscount(data)),
    setSubTotal: (data) => dispatch(editOrderSubTotal(data)),
    getCatagoery: () => dispatch(getCategoryData()),
    getCoupons: () => dispatch(getCoupons()),
    addOrders: (data, handleVisible, handleDangerVisible) =>
      dispatch(AddOrders(data, handleVisible, handleDangerVisible)),
    deleteOrder: (oldData) => dispatch(deleteOrders(oldData)),
    getOrder: () => dispatch(getOrders()),
    getOrderById: (orderId) => dispatch(GetOrderById(orderId)),
    updateOrder: (oldData, newData, handleVisible, handleDangerVisible) =>
      dispatch(
        updateOrders(oldData, newData, handleVisible, handleDangerVisible)
      ),
    getCustomer: () => dispatch(getCustomerData()),
    getProductVariation: (id) => dispatch(getProductTypeById(id)),
    getOrderTypes: () => dispatch(getOrderTypes()),
    getTables: () => dispatch(getTables()),
    updateStatus: (data, status) => dispatch(updateStatus(data, status)),
    editOrder: (data) => dispatch(editOrder(data)),
    searchProduct: (data) => dispatch(filterItem(data)),
    serchByMenu: (id) => dispatch(filterItemByMenu(id)),
    // getOrderDataByID:()=>dispatch(OrderEditRoute())
  };
};
const mapStateToProps = (state, ownProps) => ({
  customersList: state.POSViewReducer.customersList,
  customerDataList: state.CustomerReducer.customerDataList,
  isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,
  isFetchingOrderTypes: state.POSViewReducer.isFetchingOrderTypes,
  orderTypesList: state.POSViewReducer.orderTypesList,
  isFetchingTables: state.POSViewReducer.isFetchingTables,
  OrderDataByID: state.POSViewReducer.OrderDataByID,
  orderEdit: state.POSViewReducer.orderEdit,
  tablesList: state.POSViewReducer.tablesList,
  categoryList: state.CategoryReducer.categoryList,
  couponsDataList: state.CouponReducer.couponsDataList,
  isFetchingCouponsData: state.CouponReducer.isFetchingCouponsData,
  serviceAndHybridProductList: state.RecipeReducer.serviceAndHybridProductList,
  isFetchingServiceAndHybridProductList:
    state.ProductReducer.isFetchingServiceAndHybridProductList,
  productTypeListById: state.RecipeReducer.productTypeListById,
  isFetchingProductTypeById: state.RecipeReducer.isFetchingProductTypeById,
  seatSelected: state.POSViewReducer.seatSelected,
  isAddingOrders: state.POSViewReducer.isAddingOrders,
  productSearchList: state.RecipeReducer.productSearchList,
});

export default connect(mapStateToProps, mapDispatchToProps)(POSOrderEditView);
