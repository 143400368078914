import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert";
import { getBackUp } from "./BackUp.action";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import IconMenu from "../../drawer/IconMenu";
import Typography from "@mui/material/Typography";
const UserBackUp = (props) => {
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
   // clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Error",
      icon: "error",
      button: "Ok",
    });
  };
  return (
    <>
      <Container fluid>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Back Up
            </Typography>
            <IconMenu />
          </Toolbar>
        </AppBar>
      </Box>
        <Form>
          <div className="sendDiv" style={{marginTop:'5%'}}>
            <Button
            style={{width:'20%'}}
              onClick={() => {
             props.getBackup(handleVisible,handleDangerVisible)
              }}
            >
              Back Up
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBackup: (handleVisible,handleDangerVisible) => dispatch(getBackUp(handleVisible,handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(UserBackUp);
