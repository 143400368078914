import React, { useEffect, useState } from 'react'
import { Container, Form, Col, Row, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import Swal from 'sweetalert';
import { setHeading } from '../../../accounts/account.actions';
import { addProductFile } from './Issuance.action';
import { getCategoryData } from '../categories/Catagory.action';
import { isEmpty } from 'lodash';
import Select from "react-select";

const UploadIssuance = (props) => {
  const [file, setProductFile] = useState();
  // console.log('attendanceFile', productFile);
  const [CategoryId, setCategoryId] = useState("");
  const [Categoryname, setCategoryName] = useState("");
  const [AlertVisible, setAlertVisible] = useState(false);
  const[ dangerVisibleAlert, setDangerVisibleAlert] = useState(false);




const handleVisible = () => {
  setAlertVisible(true)
  setTimeout(() => {
      setAlertVisible(false)
  }, 9000);
  Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
}

const handleDangerVisible = () => {
  setDangerVisibleAlert(true)
  setTimeout(() => {
      setDangerVisibleAlert(false)
  }, 9000);
  Swal({
      title: "Error",
      text: "File Upload Failed...",
      icon: "error",
      button: "Ok",
    });
}

const handleGenerate = () => {
  props.addProductFile({
    file: file, 
  },CategoryId, handleVisible, handleDangerVisible);
};
const handleChangeCategory = (selectedOption) => {
  console.log(selectedOption, "sop");
  setCategoryId(selectedOption.value);
  setCategoryName(selectedOption.label);
};
const getCategoryList =
!isEmpty(props.categoryList.data) &&
props.categoryList.data.map((x) => {
  let data = { value: x._id, label: x.name };
  return data;
});
useEffect(() => {
  props.getCategoryData();
    props.setHeading("Upload Product");
  //props.resetState();
}, []);
  return (
    <>
      <Container fluid>
        <div className="mainHr">
        <Row>
              <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Menu *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.categoryList)}
                        placeholder="Select Menu..."
                        onChange={handleChangeCategory}
                        options={getCategoryList}
                      />
                    </Form.Group>
                  </Col>
          
                         
                </Row>
          <Row>
            <Col lg="12" md="12" xs="12">

              <Form.Group controlId="garana" className="mb-3">
                <Form.Label>Upload File Here</Form.Label>
                <Form.Control type="file" className="form-control"
                  onBlur={(e) => setProductFile(e.target.files[0])}
                  // multiple
                />
              </Form.Group>
            </Col>
      
          </Row>
          <div className="sendDiv">
          {isEmpty(CategoryId) ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "#1976d2",
                        border: "1px solid #1976d2",
                      }}
                    >
                     Upload File
                    </Button>
                  ) : (  <Button className="sendButton" onClick={() => { handleGenerate(); }}>Upload File</Button>)}
          </div>
        </div>
      </Container>
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    // getProductType:()=>dispatch(getProductType()),
    addProductFile: (file,category, handleVisible, handleDangerVisible) =>dispatch (addProductFile(file,category, handleVisible, handleDangerVisible)),
    setHeading:(data)=>dispatch(setHeading(data)),
    getCategoryData: () => dispatch(getCategoryData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productTypeList: state.ProductReducer.productTypeList,
  categoryList: state.CategoryReducer.categoryList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadIssuance);
