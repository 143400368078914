import API from "../../../../../global/api";
export const requestMasterControlData = () => {
    return {
        type: "REQUEST_MASTER_CONTROL_GET_DATA",
    };
};
export const successMasterControlGetData = (data) => {
    return {
        type: "SUCCESS_MASTER_CONTROL_GET_DATA",
        payload: data,
    };
};
export const errorMasterControlGetData = () => {
    return {
        type: "ERROR_MASTER_CONTROL_GET_DATA",
    };
};
export const getMasterControlData = () => {
    return (dispatch) => {
        dispatch(requestMasterControlData());
        API.get(`/masterControl`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successMasterControlGetData(getData));
        }).catch((error) => {
            dispatch(errorMasterControlGetData());
        })
    };
};
export const requestUOM = () => {
    return {
        type: "REQUEST_UOM",
    };
};
export const successUOM = (data) => {
    return {
        type: "SUCCESS_UOM",
        payload: data,
    };
};
export const errorUOM = () => {
    return {
        type: "ERROR_UOM",
    };
};
export const getUOM = () => {
    return (dispatch) => {
        dispatch(requestUOM());
        API.get(`/uom`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successUOM(getData));
        }).catch((error) => {
            dispatch(errorUOM());
        })
    };
};
export const requestAddMasterControl = () => {
    return {
        type: "REQUEST_ADD_MASTER_CONTROL",
    };
};
export const successAddMasterControl = (data) => {
    return {
        type: "SUCCESS_ADD_MASTER_CONTROL",
        payload: data,
    };
};
export const errorAddMasterControl = () => {
    return {
        type: "ERROR_ADD_MASTER_CONTROL",
    };
};

export const addMasterControl = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddMasterControl());
        API.post(`/masterControl`, data).then((res) => {
           // dispatch(getMasterControlData());
           console.log(res,"dataaaaaaaaaaa")
            dispatch(successAddMasterControl(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddMasterControl());
            handleDangerVisible(error.response.data.message);
        })
    };
};
export const DeleteRequestMasterControl = () => {
    return {
        type: "DELETE_REQUEST_MASTER_CONTROL",
    };
};
export const DeleteSuccessMasterControl = (user_id) => {
    return {
        type: "DELETE_SUCCESS_MASTER_CONTROL",
        user_id: user_id,
    };
};
export const DeleteErrorMasterControl = () => {
    return {
        type: "DELETE_ERROR_MASTER_CONTROL",
    };
};
export const deleteMasterControl = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestMasterControl());
        API.delete(`/masterControl/${vendorId}`)
            .then(res => {
               // dispatch(getMasterControlData());
                dispatch(DeleteSuccessMasterControl(vendorId))
            }).catch((error) =>
            {
                dispatch(DeleteErrorMasterControl())
    });
    }
};
export const UpdateRequestMasterControl = () => {
    return {
        type: "UPDATE_REQUEST_MASTER_CONTROL",
    };
};
export const UpdateSuccessMasterControl = (data) => {
    return {
        type: "UPDATE_SUCCESS_MASTER_CONTROL",
        payload: data,
    };
};

export const UpdateErrorMasterControl = () => {
    return {
        type: "UPDATE_ERROR_MASTER_CONTROL",
    };
};

export const updateMasterControl = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestMasterControl());
        console.log(data,'hmhm')
        API.put(`/masterControl/${oldData._id}`,
            {
                masterControlName: data.masterControlName,
                masterControlDesc:data.masterControlDesc,
                uom:data.uom     
            },{
        })
            .then(res => {
             //   dispatch(getMasterControlData());
                dispatch(UpdateSuccessMasterControl([data]))
            }).catch((error) =>
                dispatch(UpdateErrorMasterControl()));
    }
};