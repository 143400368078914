// import React from 'react';
// import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom'
// import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import TextField from '@material-ui/core/TextField';
// import { makeStyles } from '@material-ui/core/styles';
// import { Route } from 'react-router-dom';
// import Container from '@material-ui/core/Container';
// import LoginModal from './LoginModal';
// import { styled } from '@material-ui/core/styles';
// import { Typography } from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import { getLogin } from "../../user_views/accounts/account.actions"
// import { FormGroup, Alert } from 'react-bootstrap';
// import KeyboardEventHandler from 'react-keyboard-event-handler';
// import './style.css';
// import { Modal, ModalHeader, ModalFooter, ModalBody, Row } from 'reactstrap';
// var bg = require('../../../../src/Images/bg.jpg');
// var back = require(`../../../Images/bg.jpg`);
// function Login(props) {
//   //const classes = useStyles();

//   const [email, setEmail] = React.useState("");
//   const [pass, setPassword] = React.useState("");
//   const [open, setOpen] = React.useState(false);
//   const [showPassword, setShowPassword] = React.useState(false);
//   const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
//   window.localStorage.clear()

//   const [show, setShow] = React.useState(false);


//   const handleShow = () => setShow(true);
//   const handleClose = () => setShow(false);

//   console.log(props.isLogin, "ppjjll")

//   const handleLogin = () => {

//     props.getLogin({
//       username: email,
//       password: pass,
//     })

//     handleShow()
//   }
//   console.log(pass, "jjllkk")
//   return (
//     <div >
//       <div className="login-block">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-4 login-sec">
//               <h2 className="text-center">User Login</h2>
//               <div className="form-group">
//                 <label for="exampleInputEmail1" className="text-uppercase">Username</label>
//                 <input type="text" id="standardbasic" className="form-control" placeholder="" onChange={(e) => setEmail(e.target.value)} />

//               </div>
//               <div className="form-group">
//                 <label for="exampleInputPassword1" className="text-uppercase">Password</label>

//                 <KeyboardEventHandler
//                   handleKeys={['Enter']}
//                   onKeyEvent={(key, e) => handleLogin()} >
//                   <div style={{ display: "grid" }} className="form-group">

//                     <input type="password" id="standard-basic" className="form-control" placeholder="" onChange={(e) => setPassword(e.target.value)}/>
//                   </div>
//                 </KeyboardEventHandler>
//               </div>

//               <div className="form-check">
//                 <button onClick={() => handleLogin()} className="btn btn-login cus-btn float-right">Sign In</button>
//               </div>
//               <Route render={({ history }) => (
//                 <button
//                   type='button'
//                   style={{ color: 'white', background: 'black', borderRadius: '5px', borderColor: 'silver', padding: '10px', fontWeight: 'bold' }}
//                   onClick={() => { history.push('/admin-login') }}
//                 >
//                   Go to Admin Login
//                 </button>
//               )} />
//             </div>
//           </div>
//         </div>


//         <div className="modal">
//           {props.isWrongCredentials == true &&
//             <Modal isOpen={show}>
//               <ModalHeader><h4>Login Unsuccessful</h4></ModalHeader>
//               <ModalBody><h5>Something is Wrong</h5></ModalBody>
//               <ModalFooter>
//                 <Row className="close">
//                   <Button onClick={handleClose}>
//                     Close
//                   </Button>
//                 </Row>
//               </ModalFooter>
//             </Modal>}
//         </div>
//       </div>
//     </div>
//   );
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     getLogin: (data) => dispatch(getLogin(data)),
//   }
// }

// const mapStateToProps = (state, ownProps, number) => ({
//   isWrongCredentials: state.userReducer.isWrongCredentials,
// })

// export default connect(mapStateToProps, mapDispatchToProps)(Login);


//New Code

import * as React from 'react';
 import { connect } from 'react-redux';
import { useContext } from "react";
//import { Context } from "../../../store";
import Avatar from '@mui/material/Avatar';
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Spinner,
  Image,
  Modal, ModalHeader, ModalFooter, ModalBody
} from "react-bootstrap";

import logo from "../../../Images/report.jpg"

import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
// import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {isEmpty} from 'lodash';
 import { getLogin, changeIsWrong } from "../../user_views/accounts/account.actions"
// import { Row, Spinner } from 'react-bootstrap';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {/* {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} */}
    </Typography>
  );
}

const theme = createTheme();

function Login(props) {
  //const [state, dispatch] = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [pass, setPassword] = React.useState("");

  const [dangerVisibleAlert, setDangerVisibleAlert] = React.useState(false);
  const navigate = useNavigate();
  console.log('props.isLogin', props.isLogin)

  console.log('props.isWrongCredentials', props.isWrongCredentials)

    const [show, setShow] = React.useState(false);


  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSubmit = () => {
    props.getLogin({
      userName:  email,
      password:  pass,
    },
    handleDangerVisible)

    setShow(true)
};
    ;


  // const handleClose = () => {
  //   props.closeSnack()
  // };
  const handleToggle = () => {
    setOpen(!open);
  };


  console.log(props.isWrongCredentials,"testtttt");

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };


  const handleForgetPassword = () => {
    navigate('/forgot-password')
  }

  const handleRegistration = () => {
    navigate('/register')
  }

  function handleCallbackResponse(response) {
    // GetGoogleLogin({
    //   google_token: response.credential,
    // }, state, dispatch);
  }

  return (
    <>   
   <div style={{overflowX:'hidden',overflowY:'hidden'}}>
   <Row>
        <Col xl="6" lg="6" md="6" sm="6" style={{backgroundColor:'#3285a8',height:'100vh'}}>
        <Row style={{marginTop:"25%"}}>
          <Col xl="3" lg="1" md="1" sm="1">
            </Col>
            <Col xl="6" lg="10" md="10" sm="10">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* <Form.Label><p style={{color:'white',fontSize:'22px',textAlign:'center',marginLeft:'20px'}}><b>WE REPORT , YOUR MIND</b></p></Form.Label> */}
                          <Form.Label><p style={{color:'white',fontSize:'22px',textAlign:'center',marginLeft:'20px'}}><b>Login</b></p></Form.Label>
                          </Form.Group>
            </Col>
            <Col xl="3" lg="1" md="1" sm="1">
            </Col>
          </Row>
          <Row>
          <Col xl="2" lg="2" md="2" sm="2">
            </Col>
            <Col xl="8" lg="8" md="8" sm="8">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label><p style={{color:'white'}}>Username</p></Form.Label>
                          <Form.Control
                            type="text"
                            name="Username"
                            placeholder="Username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                           
                          />
                        </Form.Group>
            </Col>
            <Col xl="2" lg="2" md="2" sm="2">
            </Col>
          </Row>
          <Row>
          <Col xl="2" lg="2" md="2" sm="2">
            </Col>
            <Col xl="8" lg="8" md="8" sm="8">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label><p style={{color:'white'}}>Password</p></Form.Label>
                          <Form.Control
                            type="password"
                            name="Password"
                            placeholder="Password"
                            value={pass}
                            onChange={(e) => setPassword(e.target.value)}
                           
                          />
                        </Form.Group>
            </Col>
            <Col xl="2" lg="2" md="2" sm="4">
            </Col>
          </Row>
          {/* <Row> */}
          <div className='sendDiv' >
        
              {isEmpty(email)||isEmpty(pass) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "black",
                    width: "20%",
                    backgroundColor: 'white',
                    border: "1px solid white",
                  }}
                >
                  Sign In
                </Button>
              ) :  (
                <Button  onClick={() => handleSubmit()}
                style={{
                  marginLeft: "2%",
                  color: "black",
                  width: "20%",
                  backgroundColor: "white",
                  border: "1px solid white",
                }}>
                  Sign In
                </Button>
              )}
           
            </div>
            {/* </Row> */}
            {/* <Row style={{marginTop:'10%'}}>
          <Col xl="3" lg="3" md="3" sm="0">
            </Col>
            <Col xl="6" lg="6" md="6" sm="12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label><p style={{color:'white',fontSize:'22px',textAlign:'center',marginLeft:'20px'}}><b>COPY RIGHTS RESERVED</b></p></Form.Label>
                          </Form.Group>
            </Col>
            <Col xl="3" lg="3" md="3" sm="0">
            </Col>
          </Row> */}
        </Col>
        <Col xl="6" lg="6" md="6" sm="6">
          <Image src={logo} style={{height:'99vh',width:'99%'}}/>
       </Col>
        {/* <div className="modal">
          {props.isWrongCredentials == true &&
            <Modal isOpen={show}>
              <ModalHeader><h4>Login Unsuccessful</h4></ModalHeader>
              <ModalBody><h5>Something is Wrong</h5></ModalBody>
              <ModalFooter>
                <Row className="close">
                  <Button onClick={handleClose}>
                    Close
                  </Button>
                </Row>
              </ModalFooter>
            </Modal>}
         </div> */}
      </Row>
   </div>
    </>
 
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getLogin: (data,handleDangerVisible) => dispatch(getLogin(data,handleDangerVisible)),
    closeSnack:() => dispatch(changeIsWrong())
  }
}

const mapStateToProps = (state, ownProps, number) => ({
 // isWrongCredentials: state.userReducer.isWrongCredentials,
 isLogin: state.userReducer.isLogin,
 isWrongCredentials: state.userReducer.isWrongCredentials,
message:state.userReducer.message,
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);