
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { getLevel5LedgerData,  searchAccountLedgerReport, searchAccountLedgerTable } from "./Ledger.action"
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import "./style.css"
import { isEmpty, get } from "lodash";
import Select from 'react-select';
import { Navigate , Link } from "react-router-dom";
import { startFinancialDate } from "../../../../global/globalFunctions";
import moment from "moment";
import { setHeading } from "../../accounts/account.actions";

const AccountLedger = (props) => {

  const [accountName, setAccountName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState("");


  const getLevel5LedgerData1 = props.level5LedgerData.map((x) => { let data = { value: x._id, label: x.allLevelKey + "" + x.levelFourName }; return data });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
    setAccountName(selectedOption.label)
  };

  const exportPDF = () => {
    props.searchAccountLedgerReport({
      startDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      levelFourId: item,
    }, startDate, endDate,accountName);
  }

  useEffect(() => {
    props.getLevel5LedgerData();

      props.setHeading("Ledger");
    
  }, []);

  function searchData() {
    props.searchAccountLedgerTable({
      startDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      levelFourId: item,
    },startDate, endDate,accountName);
    return <Navigate to="/accounts/Ledger-List" />
  }

  return (
    <>
      {props.isFetchingLevel5LedgerData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingLevel5LedgerData ? 'Loading...' : 'Loading...'}

          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>

                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Account *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Account..."
                    onChange={handleChangeLevel5Ledger}
                    options={getLevel5LedgerData1}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>

              <Col lg="12" sm="12" className="text-center" style={{marginTop:"2%"}}>
                {item == "" ?
                  <Button disabled>Search</Button> :
                  <Link to= {{pathname : "/accounts/Ledger-List", 
                  state: {start: startDate, end: endDate}}}>
                    <Button style={{width:"20%"}} onClick={(e) => searchData()} >Search</Button>
                  </Link>
                }
              </Col>
            </Row>

            <div className="sendDiv" style={{marginTop:'10px'}}>
              {item == "" ?
                <Button disabled  >Generate Ledger Report</Button> :
                <Button style={{width:"20%"}} onClick={() => exportPDF()}>Generate Ledger Report</Button>}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
    searchAccountLedgerReport: (data, startDate, endDate,accountName) => dispatch(searchAccountLedgerReport(data, startDate, endDate,accountName)),
    setHeading:(data)=>dispatch(setHeading(data)),
    searchAccountLedgerTable: (data, startDate, endDate,accountName) => dispatch(searchAccountLedgerTable(data, startDate, endDate,accountName)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData: state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger: state.AccountLegderReducer.isFetchingSearchAccountLedger,
  searchAccountLedgerTableList:state.AccountLegderReducer.searchAccountLedgerTableList,
  isFetchingSearchAccountLedgerTable:state.AccountLegderReducer.isFetchingSearchAccountLedgerTable,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountLedger);