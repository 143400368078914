import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert, Tabs, Tab } from "react-bootstrap";
import "./add-levels.css";
import { getLevel1Data, getLevel2Data, getLevel3Data, getLevel4Data, getLevel5Data, AddLevel5Name, getcreditdebit,getLevelOneName,getLevelTwoName, deleteLevel5,getLevelFourName, getLevel5DataForReport } from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, isUndefined,find, map, sortBy } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import ReactExport from "react-export-excel";
import Select from 'react-select';
import Level5Upload from './Level5Upload';
import PDFLevel5 from "./PDFLevel5";
import { PDFDownloadLink } from '@react-pdf/renderer';
import Level5Tap from "./Level5Tap";
import { setHeading } from "../../accounts/account.actions";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Level5Report = (props) => {
    const level5Data = props.level5ListReport;
    useEffect(() => {
         props.getLevel5Data();
         props.setHeading("Level Four Report");
    }, []);

    return (
        <>
                    {props.isFetchinglevel5ListReport ? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />

                            </Button>
                        </div>
                    ) : (
                        <Container fluid>
                            <div className="main">
                                {/* <ExcelFile element={<button className="sendButton" style={{ marginRight: "2%" }}>Generate Excel</button>}>
                                    <ExcelSheet data={map(level5Data, lev => Object.assign({}, ...function flatten(o) { return [].concat(...Object.keys(o).map(k => typeof o[k] === 'object' ? flatten(o[k]) : ({ [k]: o[k] }))) }(lev)))} name="Level3Report">
                                        <ExcelColumn label="level 1 code" value="key" />
                                        <ExcelColumn label="level 1 name" value="name" />
                                        <ExcelColumn label="level 2 code" value="levelTwoKey" />
                                        <ExcelColumn label="level 2 name" value="levelTwoName" />
                                        <ExcelColumn label="level 3 code" value="levelThreeKey" />
                                        <ExcelColumn label="level 3 name" value="levelThreeName" />
                                        <ExcelColumn label="level 4 code" value="levelFourKey" />
                                        <ExcelColumn label="level 4 name" value="levelFourName" />
                                        <ExcelColumn label="level 5 code" value="allLevelKey" />
                                        <ExcelColumn label="level 5 name" value="levelFiveName" />
                                    </ExcelSheet>
                                </ExcelFile> */}
                                <Button>
                                <PDFDownloadLink style={{color:'white'}} document={<PDFLevel5 data={props.level5ListReport} array={sortBy(props.level5ListReport.allData, ['props.level5ListReport', 'levelfiveData.allLevelKey'])} />} fileName="Level5.pdf">
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Loading document...' : 'Download now!'
                                    }
                                </PDFDownloadLink>
                                </Button>
                            </div>

                        </Container>

                    )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLevel5Data: () => dispatch(getLevel5DataForReport()),
        deleteLevel5: (levelFiveId) => dispatch(deleteLevel5(levelFiveId)),
        setHeading:(data)=>dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    level5ListReport: state.accountsLevelReducer.level5ListReport,
    isFetchinglevel5ListReport: state.accountsLevelReducer.isFetchinglevel5ListReport,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Level5Report);