import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  tableList: [],
  tableUploadList: [],
  tableDataList: [],
  isFetchingTable: false,
  isAddingTable: false,
  isUploadingTable: false,
  tableListReserved:[],
  isFetchingTableReserved:false,
  isFetchingHallById:false,
  hallListById:[],
  tableSearchList:[],
  ReserveTable:[],
};

const TableReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_TABLE_GET_DATA":
      return { ...state, isFetchingTable: true, };
  case "SUCCESS_TABLE_GET_DATA":
      return {
          ...state,
         tableDataList: action.payload,
         tableSearchList:action.payload,
          isFetchingTable: false,
      };
  case "ERROR_TABLE_GET_DATA":
      return { ...state,};
      case "FILTER_TABLE_BY_HALLID":
        console.log(state.tableSearchList,"oooooooooooooo")
        return{
            ...state,   tableSearchList:action.payload == 999999999999 ? state.tableDataList : filter(state.tableDataList, x =>!isEmpty(x.tableData.hall)&& x.tableData.hall._id == action.payload) ,
            ReserveTable: !isEmpty(state.tableSearchList) &&state.tableSearchList.filter(e => !isEmpty(e.orderDetail))
           
        }
     // TABLE RESERVED
     case "REQUEST_TABLE_TABLE_RESERVED":
      return { ...state, isFetchingTableReserved: true, };
  case "SUCCESS_TABLE_RESERVED":
      return {
          ...state,
         tableListReserved: action.payload.reservedTable,
          isFetchingTableReserved: false,
      };
  case "ERROR_TABLE_TABLE_RESERVED":
      return { ...state,};
      //addtable
      case "REQUEST_ADD_TABLE":
        return { ...state, isAddingTable: true};
      case "SUCCESS_ADD_TABLE":
        return {
          ...state,
         tableList: action.payload,
          isAddingTable: false,
        };
      case "ERROR_ADD_TABLE":
        return { ...state, isAddingTable: false};
        //upload table
        case "REQUEST_UPLOAD_TABLE":
          return { ...state, isUploadingTable: true};
        case "SUCCESS_UPLOAD_TABLE":
          return {
            ...state,
           tableUploadList: action.payload,
           isUploadingTable: false,
          };
        case "ERROR_UPLOAD_TABLE":
          return { ...state, isUploadingTable: false};
    case "REQUEST_HALL_BYID":
    return { ...state, isFetchingHallById: true, };
case "SUCCESS_HALL_BYID":
    return {
        ...state,
        hallListById: action.payload.data,
        isFetchingHallById: false,
    };
case "ERROR_HALL_BYID":
    return { ...state,isFetchingHallById:false};
      default:
        return state;
    }
  };
  export default TableReducer;