import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, toLower } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
//import logo from "../../../../../Images/logo.png";
import logo from "../../../../Images/logo.png";
import {
  addUserRes,
  deleteUserRes,
  getUserRes,
  UpdateUserRes,
} from "./user.actions";
import { getRestaurantData } from "../AddDetails/AddResturant/Resturant.action";
import { setHeading } from "../../accounts/account.actions";
const AddUser = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  // const [lastName, setLastName]=useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [submit, setSubmit] = useState(false);
  function uploadCustomer() {
    props.addUser(
      {
        firstName: customerName,
        lastName: lastName,
        userName: userName,
        password: passwordUser,
        role:toLower(roleName),
        // businessName:businessName,
        restaurant:restaurantId,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  let rollData = [
    {
      roll_id: 1,
      roll_name: "Manager",
    },
    {
      roll_id: 2,
      roll_name: "Staff",
    },
    {
      roll_id: 3,
      roll_name: "Admin",
    },
    {
      roll_id: 4,
      roll_name: "Waiter",
    },
    {roll_id:5,
    roll_name:"Supervisor"}
  ];
  const handleChangeRoll = (selectedOption) => {
    console.log(selectedOption, "sop");
    // setEventShiftId(selectedOption.value);
    setRoleName(selectedOption.label);
  };
  const rollList =
    !isEmpty(rollData) &&
    rollData.map((x) => {
      let data = { value: x.roll_id, label: x.roll_name };
      return data;
    });
    const handleChangeRestaurant = (selectedOption) => {
      console.log(selectedOption, "sop");
      setRestaurantId(selectedOption.value);
      setRestaurantName(selectedOption.label);
    };
    const getRestaurantList =
      !isEmpty(props.restaurantDataList) &&
      props.restaurantDataList.map((x) => {
        let data = { value: x._id, label: x.restaurantName};
        return data;
      });
  const clearStates = () => {
    setLastName("");
    setPasswordUser("");
    setUserName("");
    setCustomerName("");
    //setCustomerPhone("");
    setSubmit(false);
  };

  const CustomerData = isEmpty(props.userList) ? [] : props.userList.reverse();
  useEffect(() => {
    props.setHeading("Add User")
     props.getRestaurantData()
  }, []);
  return (
    <>
      {props.isFetchingRestaurant ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
      <Container fluid>
        <Form>
          <Row>
          <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Restaurant *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.restaurantDataList)}
                        placeholder="Select Restaurant..."
                        onChange={handleChangeRestaurant}
                        options={getRestaurantList}
                      />
                    </Form.Group>
                  </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter First Name *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name...."
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Last Name :</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name...."
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter User Name *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Name...."
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter User Password *</b>
                </Form.Label>
                <Form.Control
                  type="password"
                  minLength={8}
                  // maxLength={13}
                  placeholder="Enter User Password...."
                  value={passwordUser}
                  onChange={(e) => setPasswordUser(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Role *</b>
                </Form.Label>
                <Select
                  isDisabled={isEmpty(rollData)}
                  placeholder="Select Role..."
                  onChange={handleChangeRoll}
                  options={rollList}
                />
              </Form.Group>
            </Col>
            {/* <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Business Name:</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Business Name...."
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </Form.Group>
            </Col> */}
          </Row>

          <div className="sendDiv">
            <Button
            
              disabled={isEmpty(customerName) ||isEmpty(passwordUser)||isEmpty(userName)||isEmpty(roleName)||isEmpty(restaurantId)}
              onClick={() => {
                uploadCustomer();
                // setSubmit(true);
              }}
            >
              Save
            </Button>
          </div>
        </Form>
       
      </Container>)}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data, handleVisible, handleDangerVisible) =>
      dispatch(addUserRes(data, handleVisible, handleDangerVisible)),
      getRestaurantData: () => dispatch(getRestaurantData()),
      setHeading:(data)=>dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  addUser: state.UserReducer.addUser,
  isAddingUser: state.UserReducer.isAddingUser,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant:state.ResturantReducer.isFetchingRestaurant,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
