import React, { useEffect, useRef } from "react";
import {
  Container,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import {
  updateVoucherStatus,
  updateVoucherStatus1,
  getAdminVoucher,
} from "./Voucher.action";
import {reverse} from 'lodash'
import { baseURL, headers } from "../../../../global/api";
import { setHeading } from "../../accounts/account.actions";
const VoucherVerification = (props) => {

  useEffect(() => {
  //  props.getAdminVoucher();
    props.setHeading("Voucher Verification");
  }, []);
 // const VoucherData = props.voucherListUser;
  //console.log(reverse(VoucherData), "eeeee")
  const [state, setState] = React.useState({
    columns: [
      { title: "voucher Number", field: "voucherManualId" },
      { title: "Account Title", field: "cashAccTittle" },
       { title: "voucher date", field: "vocDate" },
      { title: "status", field: "status" },
    //  { title: "CR/DR", field: "creditOrDebit", render: rowData => rowData.creditOrDebit == 1 ? "Credit" : rowData.creditOrDebit == 2 ? "Debit" : "" }
    ],
    columnsData: [
      { title: "Account Title", field: "accountTittle" },
      { title: "Bill No", field: "billNumber" },
      { title: "Voucher Description", field: "voucherDescription" },
      { title: "Voucher Amount", field: "voucherAmount", render: rowData => rowData.voucherAmount.toLocaleString() },
      // {title:'Debit',field:'debit'},
      // {title:'Credit',field:'credit'},
      { title: "DR/CR", field: "accountType", render: rowData => rowData.accountType == 1 ? "Credit" : rowData.accountType == 2 ? "Debit" : "" }
    ],
  });
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };

  return (
    <>
      {props.isFetchingadminVoucherList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
             tableRef={tableRef}
              title="Approve Voucher"
              columns={state.columns}
             data={(query) =>
              new Promise((resolve, reject) => {
                console.log(query, "qr")
                let url = `${baseURL}/voucher/get_all_voucher_user?`;
                url += "size=" + query.pageSize;
                url += "&page=" + (query.page );
                url += "&search=" + query.search;
                fetch(url,{
                  method: 'GET',
                  headers: headers,
              })
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page ,
                      totalCount: result.total_results,
                    });
                  });
              })
            }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    title="Voucher Details"
                    columns={state.columnsData}
                    data={rowData.voucherData}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              actions={[
                (rowData) => {
                  return rowData.status !== "Pending"
                    ? {
                      icon: DoneIcon,
                      disabled: true,
                    }
                    : {
                      icon: DoneIcon,
                      disabled: false,
                      onClick: (event, rowData) => {
                        props.updateVoucherStatus(rowData, updateTableQuery);
                      },
                    };
                },
                (rowData) => {
                  return rowData.status !== "Pending"
                    ? {
                      icon: ClearIcon,
                      disabled: true,
                    }
                    : {
                      icon: ClearIcon,
                      disabled: false,
                      onClick: (event, rowData) => {
                        props.updateVoucherStatus1(rowData, updateTableQuery);
                      },
                    };
                },
              ]}
              options={{
                actionsColumnIndex: -1,
               // filtering: true,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>

        </Container>

      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminVoucher: () => dispatch(getAdminVoucher()),
    updateVoucherStatus: (data,updateTableQuery) => dispatch(updateVoucherStatus(data,updateTableQuery)),
    updateVoucherStatus1: (data,updateTableQuery) => dispatch(updateVoucherStatus1(data,updateTableQuery)),
    setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  voucherListUser: state.VoucherReducer.voucherListUser,
  isFetchingadminVoucherList: state.VoucherReducer.isFetchingadminVoucherList
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherVerification);
