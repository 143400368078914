import React, { useEffect, useState } from "react";
import "./seats.css";
//import Seatbooking1 from "./Seatbooking1";
import {
    Row,
    Col,
    Form,
    Table,
    Container,
    Button,
    FormGroup,
    Alert,
    Tab,
    Tabs,
  } from "react-bootstrap";
  import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Icon } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
//import Col from "react-bootstrap/Col";
import { useNavigate } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Spinner from "react-bootstrap/Spinner";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import { isEmpty, find, isUndefined, sumBy, groupBy, map, forEach } from "lodash";
import { redirect } from "react-router-dom";
import DrawGrid from "./DrawGrid";
import { setSeatAvaliable, setSeatReserved, setSeatSelected } from "./POSView.action";
import { TableShiftData, getTableData, getTableReserved } from "../AddTable/Table.action";
//import post from "./post.json";
// import tablelogo from "../../../../../Images/table1.png"
import DeckIcon from '@mui/icons-material/Deck';
import swal from "sweetalert";
const TableShift = (props,tableId) => {
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  console.log(tableId,props,"tableIdtableId")
    const navigate = useNavigate();
    const handleVisible = (msg) => {
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 9000);
      swal({
        title: "Success",
        text: msg,
        icon: "success",
        button: "Ok",
      });
    };
     const handleDangerVisible = (msg) => {
      setDangerVisibleAlert(true);
      setTimeout(() => {
        setDangerVisibleAlert(false);
      }, 9000);
      swal({
        title: "Error",
        text: msg,
        icon: "error",
        button: "Ok",
      });
    };
     const closeModal = () => {
      props.closeModal();
     }
     const checkTrue=(item)=>{
      console.log(item,props.tableId,"checktrue")
      props.TableShiftData({
        previousTableId:props.tableId,
        newtableId:item.tableData._id,
        orderId:props.orderId
      },handleVisible,handleDangerVisible)
       closeModal()
    //   isEmpty(item.orderDetail)?navigate('/pos',{state:item.tableData._id}):
    //   navigate('/pos/Editpos',{state:{id:item.orderDetail._id}})
    //  // (e) => navigate('/pos',{state:item._id})
     }
     const handleSubmited=()=> {
        props.setSeatSelected(props.seatselected.concat(props.seatReserved));
        props.setSeatReserved([]);

      }
      const [anchorEl, setAnchorEl] = useState(null);
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      useEffect(() => {
        props.getTable();
     //   props.getReservedTable();
      }, []);
      let z= [];
      let y= groupBy(props.tableDataList, x => x.tableData.hall._id)
      const  groupedLevel1 =    Object.keys(y).map(function(key, index) {  z.push({name: y[key][0].tableData.hall.hallName, val : y[key]})})
      console.log(z,"listcheck")
      
      
  return (
    < >
      {props.isFetchingTable? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingTable? "Loading..." : "Saving..."}
          </Button>
        </div>
      ) : (
    <Container fluid>
      
       
     {isEmpty(z) ? "ddddd": 
      map(z,(x,ide) => (
        <>
        <Row  key={ide}>
        <h1>{x.name}</h1>
        </Row>
        <Row>
       {map(x.val, (zz, id) => (
        console.log(zz,"zzzzzzzzzzzzz"),
        <React.Fragment key={id}>
        <Col
          xl="3"
          lg="4"
          md="6"
          sm="6"
          //  style={{backgroundColor:'red'}}
        >
          <Card style={{marginTop:'2%',marginBottom:'1%'}}
            onClick={(e) =>checkTrue(zz)} sx={{ opacity:!isEmpty(zz.orderDetail) ? 0.5 : 1,pointerEvents:!isEmpty(zz.orderDetail) ? "none" : "auto"}} >
            <CardActionArea>
              {/* <
                
                component="img"
                height="100"
                //  image={<DeckIcon/>}
              // {<DeckIcon/>}
                // image={<FontAwesomeIc icon="fa-solid fa-chair" size="2xl" />}
                alt="Product"
              > */}
              {console.log(zz,"aaaaaaaaaaaaaaaaaaaaa")}
              <DeckIcon style={{fontSize:'50px',marginLeft:'25%',color:!isEmpty(zz.orderDetail)?"red":"black"}}/>
              <CardContent>
                {!isEmpty(
                  props.tableDataList
                ) && (
                  <Typography
                    gutterBottom
                    variant="body3"
                    component="div"
                  >
                    {console.log('testCheck',zz.tableData.tableName)}
                    {/* <h1>gffgfffgggf</h1> */}
                  <b>{zz.tableData.tableName}<span style={{float:'right'}}>{zz.tableData.tableSittingCapacity}</span></b>
                {!isEmpty(zz.orderDetail)?<p style={{textAlign:'center',marginBottom:'-10px'}} ><b>{zz.orderDetail.status}</b></p>:<p></p>}
                  </Typography>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </Col>
      </React.Fragment>
         )
           )}
           </Row>
           </>
          
      ))
    }
                  
 
    
     </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTable:()=>dispatch(getTableData()),
    TableShiftData:(data,handleVisible,handleDangerVisible)=>dispatch(TableShiftData(data,handleVisible,handleDangerVisible)),
    setSeatSelected:(seat)=>dispatch(setSeatSelected(seat)),
    setSeatAvaliable:(seat)=>dispatch(setSeatAvaliable(seat)),
    setSeatReserved:(seat)=>dispatch(setSeatReserved(seat)),
    getReservedTable:()=>dispatch(getTableReserved()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  tableListReserved:state.TableReducer.tableListReserved,
    tableDataList:state.TableReducer.tableDataList,
    seatAvaliable:state.POSViewReducer.seatAvaliable,
    seatReserved:state.POSViewReducer.seatReservedx,
    seatselected:state.POSViewReducer.seatSelected,
    isFetchingTable:state.TableReducer.isFetchingTable,
});

export default connect(mapStateToProps, mapDispatchToProps)(TableShift);
