import API from "../../../../global/api";

export const requestAllVoucherType = () => {
  return {
    type: "REQUEST_GET_ALL_VOUCHER_TYPE",
  };
};
export const successAllVoucherType = (data) => {
  return {
    type: "SUCCESS_GET_ALL_VOUCHER_TYPE",
    payload: data,
  };
};
export const errorAllVoucherType = () => {
  return {
    type: "ERROR_GET_ALL_VOUCHER_TYPE",
  };
};

export const getAllVoucherType = () => {
  return (dispatch) => {
    dispatch(requestAllVoucherType());
    API.get(`/voucher/get_all_vouchertype`)
      .then((res) => {
        let getData = res.data;
        dispatch(successAllVoucherType(getData));
      })
      .catch((error) => {
        dispatch(errorAllVoucherType());
      });
  };
};

export const requestAddVoucher = () => {
  return {
    type: "REQUEST_ADD_VOUCHER",
  };
};
export const successAddVoucher = (data) => {
  return {
    type: "SUCCESS_ADD_VOUCHER",
    payload: data,
  };
};
export const errorAddVoucher = () => {
  return {
    type: "ERROR_ADD_VOUCHER",
  };
};

export const addCashPayment = (data, handleVisible, handleDangerVisible) => {
  console.log(data,"datacheck")
  return (dispatch) => {
    dispatch(requestAddVoucher());
    API.post(`/voucher/add_voucher`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "gd")
        dispatch(successAddVoucher(data));
        data['voucherManualId']=getData.vocId;
        handleVisible(data);
      })
      .catch((error) => {
        dispatch(errorAddVoucher());
        handleDangerVisible();
      });
  };
};

export const requestAllVoucher = () => {
  return {
    type: "REQUEST_ALL_VOUCHER",
  };
};
export const successAllVoucher = (data) => {
  return {
    type: "SUCCESS_ALL_VOUCHER",
    payload: data,
  };
};
export const errorAllVoucher = () => {
  return {
    type: "ERROR_ALL_VOUCHER",
  };
};

export const getAllVoucher = () => {
  return (dispatch) => {
    dispatch(requestAllVoucher());
    API.get(`/voucher/get_all_voucher`)
      .then((res) => {
        let getData = res.data;
        dispatch(successAllVoucher(getData));
      })
      .catch((error) => {
        dispatch(errorAllVoucher());
      });
  };
};

export const submitCashPayment = (data, data1) => {
  return {
    type: "SUBMIT_CASH_PAYMENT",
    payload: {
      data,
    },
  };
};

export const submitCashRecipet = (datacash, data1) => {
  return {
    type: "SUBMIT_CASH_RECIPET",
    payload: {
      datacash,
    },
  };
};

export const submitBankPayment = (databank, data1) => {
  return {
    type: "SUBMIT_BANK_PAYMENT",
    payload: {
      databank,
    },
  };
};

export const submitBankRecipet = (datarecipet, data1) => {
  return {
    type: "SUBMIT_BANK_RECIPET",
    payload: {
      datarecipet,
    },
  };
};

export const submitJournal = (datajournal, data1) => {
  return {
    type: "SUBMIT_JOURNAL",
    payload: {
      datajournal,
    },
  };
};

export const deleteCashPayment = (id) => {
  return {
    type: "DELETE_CASH_PAYMENT",
    payload: id,
  };
};

export const deleteCashRecipet = (id) => {
  return {
    type: "DELETE_CASH_RECIPET",
    payload: id,
  };
};

export const deleteBankPayment = (id) => {
  return {
    type: "DELETE_BANK_PAYMENT",
    payload: id,
  };
};

export const deleteBankRecipet = (id) => {
  return {
    type: "DELETE_BANK_RECIPET",
    payload: id,
  };
};

export const deleteJournal = (id) => {
  return {
    type: "DELETE_JOURNAL",
    payload: id,
  };
};

export const setValue = (value) => {
  return {
    type: "SET_VALUE",
    payload: value,
  };
};

export const resetFunction = () => {
  return {
    type: "RESET_DATA",
  };
};

export const resetFunction1 = () => {
  return {
    type: "RESET_DATA1",
  };
};

export const resetFunction2 = () => {
  return {
    type: "RESET_DATA2",
  };
};

export const resetFunction3 = () => {
  return {
    type: "RESET_DATA3",
  };
};

export const resetFunction4 = () => {
  return {
    type: "RESET_DATA4",
  };
};

export const requestGetVoucher = () => {
  return {
    type: "REQUEST_GetVoucher",
  };
};
export const successGetVoucher = (data) => {
  return {
    type: "SUCCESS_GetVoucher",
    payload: data,
  };
};
export const errorGetVoucher = () => {
  return {
    type: "ERROR_GetVoucher",
  };
};

export const GetVoucher = () => {
  return (dispatch) => {
    dispatch(requestGetVoucher());
    API.get(`/voucher/get_voucherTypes`)
      .then((res) => {
        dispatch(successGetVoucher(res.data));
      })
      .catch((error) => {
        dispatch(errorGetVoucher());
      });
  };
};

export const requestSearchVoucher = () => {
  return {
    type: "REQUEST_SearchVoucher",
  };
};
export const successSearchVoucher = (data) => {
  return {
    type: "SUCCESS_SearchVoucher",
    payload: data,
  };
};
export const errorSearchVoucher = () => {
  return {
    type: "ERROR_SearchVoucher",
  };
};

export const SearchVoucher = (data) => {
  return (dispatch) => {
    dispatch(requestSearchVoucher());
    API.post(`/voucher/get_voucher_by_voucherTypeId?page=0&limit=1000`,data)
      .then((res) => {
        dispatch(successSearchVoucher(res.data));
      })
      .catch((error) => {
        dispatch(errorSearchVoucher());
      });
  };
};

// Voucher Verification in User

export const requestAdminVoucher = () => {
  return {
    type: "REQUEST_ADMIN_VOUCHER",
  };
};
export const successAdminVoucher = (data) => {
  return {
    type: "SUCCESS_ADMIN_VOUCHER",
    payload: data,
  };
};
export const errorAdminVoucher = () => {
  return {
    type: "ERROR_ADMIN_VOUCHER",
  };
};

export const getAdminVoucher = () => {
  return (dispatch) => {
    dispatch(requestAdminVoucher());
    API.get(`/voucher/get_all_voucher_user`)
      .then((res) => {
        let getData = res.data;
        dispatch(successAdminVoucher(getData));
      })
      .catch((error) => {
        dispatch(errorAdminVoucher());
      });
  };
};

export const requestStatusUpdateVoucher = () => {
  return {
    type: "REQUEST_STATUS_UPDATE_VOUCHER",
  };
};
export const successStatusUpdateVoucher = (data) => {
  return {
    type: "SUCCESS_STATUS_UPDATE_VOUCHER",
    payload: data,
  };
};
export const errorStatusUpdateVoucher = () => {
  return {
    type: "ERROR_STATUS_UPDATE_VOUCHER",
  };
};

export const updateVoucherStatus = (data,updateTableQuery) => {
  return (dispatch) => {
    console.log(data,"hhhhhhhhhhhh")
    dispatch(requestStatusUpdateVoucher());
    API.put(
      `/voucher/approve_voucher/${data._id}`,
      {
        status: "Approve",
      },
      {}
    )
      .then((res) => {
       // dispatch(getAdminVoucher());
        updateTableQuery()
        dispatch(successStatusUpdateVoucher([data]));
      })
      .catch((error) => dispatch(errorStatusUpdateVoucher()));
  };
};

export const updateVoucherStatus1 = (data,updateTableQuery) => {
  return (dispatch) => {
    dispatch(requestStatusUpdateVoucher());
    API.put(
      `/voucher/approve_voucher/${data._id}`,
      {
        status: "Disapprove",
      },
      {}
    )
      .then((res) => {
        updateTableQuery()
     //   dispatch(getAdminVoucher());
        dispatch(successStatusUpdateVoucher([data]));
      })
      .catch((error) => dispatch(errorStatusUpdateVoucher()));
  };
};
