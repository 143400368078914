import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs,Breadcrumb, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import Select from 'react-select';
import Swal from 'sweetalert';
import moment from "moment";
import jsPDF from "jspdf";
import ClearIcon from '@mui/icons-material/Clear';
import { getMonthWiseSaleReport, getSpecificProductSaleReport } from "./Report.action";


import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { getProductType, getProductTypeById, getServiceAndHybridProduct } from "../Recipe/Recipe.action";
import IconMenu from "../../../drawer/IconMenu";
const SpecificProductSaleReport = (props) => {


    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    
    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");
    const [variationId, setvariationId] = useState("");
    const [variationname, setvariationName] = useState("");
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };


    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Report Generated Successfully",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error",
            button: "Ok",
        });
    }
    const handleChangeProduct = (selectedOption) => {
      console.log(selectedOption, "sop");
      setProductId(selectedOption.value);
      setProductName(selectedOption.label);
     if((selectedOption.value!='6433dd117aad3eb039c1744f')){
      props.getProductTypeByID(selectedOption.value)
     }
     setvariationId("")
     setvariationName("")
    };
    const gettypeList =
    !isEmpty(props.serviceAndHybridProductList) &&
    props.serviceAndHybridProductList.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
    const [state, setState] = React.useState(
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const exportPDF = () => {
        props.getSpecificProductSaleReport({ startDate: startDate, endDate: endDate,productId:productId,variationId:variationId },
          startDate,endDate,productName,handleVisible,handleDangerVisible,
          )
    }
    console.log(props.specificProductReportData, "ddddddddd")
    const handleChangeVariation = (selectedOption) => {
      console.log(selectedOption, "sop");
      setvariationId(selectedOption.value);
      setvariationName(selectedOption.label);
    };
    const getVariationList =
      !isEmpty(props.productTypeListById) &&
      // console.log(props.productTypeListById[0].variation,"tttttttttttttt")
      props.productTypeListById[0].variation.map((x) => {
        let data = { value: x._id, label: x.sizeLabel };
        return data;
      });
    useEffect(() => {
      props.getProductSerivceAndHybrid()
    }, []);
    return (
        <>
          <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Specific Product Sale Report</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
         
    
            {props.isFetchingServiceAndHybridProductList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
               
                    <div className="main">
                    <Row>
              <Breadcrumb>
              <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Specific Product Sale Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
                        <Row>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Products:</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.serviceAndHybridProductList)}
                        placeholder="Select Product..."
                        onChange={handleChangeProduct}
                        options={gettypeList}
                      />
                    </Form.Group>
                  </Col>
                
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Variation *</b>
                      </Form.Label>
                      <Select
                        // ref={selectRef}
                        isDisabled={isEmpty(productId)||isEmpty(getVariationList)}
                        placeholder="Select Variation..."
                        onChange={handleChangeVariation}
                        options={getVariationList}
                        value={{label:variationname}}
                      />
                    </Form.Group>
                  </Col>
                        </Row>
                        <div className="sendDiv" style={{ marginTop: '2%' }}>
                            {isUndefined(startDate)||productId=="" ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%",  border: "1px solid" }}>Generate Product Sale Report</Button> : props.isFetchingspecificProductReport ? <Button >Downloading PDF</Button> : <Button onClick={() => exportPDF()}>Generate Product Sale Report</Button>}
                        </div>
                       
                    </div>

                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSpecificProductSaleReport:(data,startDate,endDate,productName,handleVisible,handleDangerVisible)=>dispatch(getSpecificProductSaleReport(data,startDate,endDate,productName,handleVisible,handleDangerVisible)),
        getProductSerivceAndHybrid:()=>dispatch(getServiceAndHybridProduct()),
        getProductTypeByID: (data) => dispatch(getProductTypeById(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
  productTypeListById: state.RecipeReducer.productTypeListById,
  serviceAndHybridProductList:state.RecipeReducer.serviceAndHybridProductList,
  isFetchingServiceAndHybridProductList:state.RecipeReducer.isFetchingServiceAndHybridProductList,
  specificProductReportData:state.ReportReducer.specificProductReportData,
  isFetchingspecificProductReport:state.ReportReducer.isFetchingspecificProductReport,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpecificProductSaleReport);