import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Breadcrumb,
} from "react-bootstrap";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import jsPDF from "jspdf";
//import logo from "../../../../../../Images/logo.png";
import ClearIcon from "@mui/icons-material/Clear";
import { getDateWiseSaleReport, getDayOpenSaleReport } from "./Report.action";
import { Link } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";

const DateWiseSaleReport = (props) => {

  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);



  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const exportPDF = () => {
    props.getDayOpenSaleReport(
      handleDangerVisible);
  };

  return (
    <>
       <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
             Day Open Sale Report 
            </Typography>
            
            <IconMenu/>
          </Toolbar>
        </AppBar>
      </Box>
     
        <Container fluid>
           
          <div className="main">

            <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Day Open Sale Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            {props.isFetchingDayOpenSaleReport ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Downloading PDF
          </Button>
        </div>
      ) : (
            <div className="sendDiv" style={{ marginTop: "2%" }}>
           
                <Button onClick={() => exportPDF()}>
                  Generate Today Sales Report
                </Button>
              
            </div>
             )}
          </div>
        </Container>
     
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDayOpenSaleReport: (handleDangerVisible) => dispatch(getDayOpenSaleReport(handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingDayOpenSaleReport: state.ReportReducer.isFetchingDayOpenSaleReport,
  dayOpenSaleReportData: state.ReportReducer.dayOpenSaleReportData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DateWiseSaleReport);
