import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { getLevel5LedgerData, searchAccountLedger, searchAccountLedgerReport } from "./Ledger.action"
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import "./style.css"
import { get } from "lodash";
import { Link, useLocation } from "react-router-dom";
import {isEmpty, isUndefined, hasIn, isNull} from "lodash";

const LedgerTable = (props) => {
    
    const location = useLocation();
    console.log(props.searchAccountLedgerTableList,"tttttttttt");
    // const getledger = isEmpty(props.searchAccountLedgerList)? [] : props.searchAccountLedgerList.data.map((v, i )=> v)
    // console.log(getledger, "vdata")
    // const name = get(!isEmpty(getledger) && getledger[1], "accName", "");
    // const start = isEmpty(props.searchAccountLedgerList) ? "" :props.searchAccountLedgerList.start;
    // const end = isEmpty(props.searchAccountLedgerList) ? "" :props.searchAccountLedgerList.end;
    const [state, setState] = React.useState({
        columns: [
            { title: 'Date', field: 'voucher_date', filtering: false },
            { title: "Voucher#", field: "voc_manual_number", filtering: false },
            { title: "Cross Account", field: "cross_acc", filtering: false },
            { title: "Description", field: "voucher_description", filtering: false },
            { title: "Bill #", field: "voucher_bill_no", filtering: false },
            // { title: "Opening", field: "opening_balance",filtering: false, render: rowData => !hasIn(rowData, 'opening_balance') || isNull(rowData.opening_balance) ? "" :rowData.opening_balance.toLocaleString()  },
            { title: "Debit", field: "debit", filtering: false, render: rowData => !hasIn(rowData, 'debit') || isNull(rowData.debit)  ? "" :rowData.debit.toLocaleString() },
            { title: "Credit", field: "credit", filtering: false, render: rowData => !hasIn(rowData, 'credit') || isNull(rowData.credit)  ? "" : rowData.credit.toLocaleString() },
            { title: "Balance", field: "accBalance", filtering: false, render: rowData => !hasIn(rowData, 'accBalance') || isNull(rowData.accBalance) ? "" :rowData.accBalance.toLocaleString() },
            { title: 'DR/CR', field: 'accType', render: rowData => rowData.accType == 2 ? "Debit" : rowData.accType == 1 ? "Credit" : "", filtering: false },
       // {title: 'DR/CR', field: "accType"}
        ],
        columnsData: [],
    });

    useEffect(() => {

    }, []);

    return (
        <>

            {props.isFetchingSearchAccountLedgerTable ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSearchAccountLedgerTable ? 'Loading...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                        <div className="sendDiv">
                            <Link to="/accounts/ledger">
                                <Button> Back to Search</Button>
                            </Link>
                        </div>

                        <MaterialTable
                         //   title={props.searchAccountLedgerTableList.levelFourName}
                        //  // title= { name == "" ? "Ledgers"  : `${"Ledgers of " + name} \n Afraz` } 
                         title={<div style={{marginTop: '5px'}}><h5>{props.searchAccountLedgerTableList.levelFourName }</h5><h5>Date Range: {props.searchAccountLedgerTableList.startDate} - {props.searchAccountLedgerTableList.endDate}</h5>
                         <h5>Closing Balance :{props.searchAccountLedgerTableList.closingBalance}</h5>
                         <h5>Total Credit:{props.searchAccountLedgerTableList.creditSum}</h5>
                         <h5>Total Debit:{props.searchAccountLedgerTableList.debitSum}</h5>
                         </div>}
                            columns={[
                                { title: 'Date', field: 'voucherDate', filtering: false },
                                { title: "Voucher Id", field: "vocManualNumber", filtering: false },
                                //{ title: "Cross Account", field: "cross_acc", filtering: false },
                                { title: "Description", field: "voucherDescription", filtering: false },
                                { title: "Bill No", field: "billNumber", filtering: false },
                                 //{ title: "Opening", field: "openingBalance",filtering: false,  },
                                { title: "Debit", field: "debit", filtering: false, render: rowData => !hasIn(rowData, 'debit') || isNull(rowData.debit)  ? "" :rowData.debit.toLocaleString() },
                                { title: "Credit", field: "credit", filtering: false, render: rowData => !hasIn(rowData, 'credit') || isNull(rowData.credit)  ? "" : rowData.credit.toLocaleString() },
                                { title: "Balance", field: "closingBalance", filtering: false, },
                                { title: 'DR/CR', field: 'accType', render: rowData => rowData.accType == 2 ? "Debit" : rowData.accType == 1 ? "Credit" : rowData.openingBalanceSign, filtering: false },
                           // {title: 'DR/CR', field: "accType"}
                            ]}
                            data={props.searchAccountLedgerTableList.data}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: false,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
  
    };
};
const mapStateToProps = (state, ownProps) => ({
    searchAccountLedgerTableList:state.AccountLegderReducer.searchAccountLedgerTableList,
    isFetchingSearchAccountLedgerTable:state.AccountLegderReducer.isFetchingSearchAccountLedgerTable,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LedgerTable);