import API, { baseURL } from "../../../../../global/api";
import jsPDF from "jspdf";
import logo from '../../../../../../src/Images/logo.png';
import moment from "moment";
import { isEmpty, sumBy } from "lodash";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";
export const requestAddReceiving = () => {
    return {
        type: "REQUEST_ADD_RECEIVING",
    };
};
export const successAddReceiving = (data) => {
    return {
        type: "SUCCESS_ADD_RECEIVING",
        payload: data,
    };
};
export const errorAddReceiving = () => {
    return {
        type: "ERROR_ADD_RECEIVING",
    };
};

export const addReceiving = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddReceiving());
        API.post(`/receiving`, data).then((res) => {
          //  dispatch(getReceiving());
            dispatch(successAddReceiving(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddReceiving());
            handleDangerVisible(error.response.data.message);
        })
    }
};
export const requestGetReceiving = () => {
    return {
        type: "REQUEST_GET_RECEIVING",
    };
};
export const successGetReceiving = (data) => {
    console.log(data,"uuuuuuuuu")
    return {
        type: "SUCCESS_GET_RECEIVING",
        payload: data,
    };
};
export const errorGetReceiving = () => {
    return {
        type: "ERROR_GET_RECEIVING",
    };
};

export const getReceiving = () => {
    return (dispatch) => {
        dispatch(requestGetReceiving());
        API.get(`${baseURL}/receiving?page=0&limit=1000`).then((res) => {
            let getData = res.data;
            console.log(getData,"uuuuuuuuuuu")
            dispatch(successGetReceiving(getData));
        }).catch((error) => {
            dispatch(errorGetReceiving());
        })
    }
};
export const requestPDFById = () => {
    return {
        type: "REQUEST_PDF_BY_ID",
    };
};
export const successPDFById = (data) => {
    console.log(data,"uuuuuuuuu")
    return {
        type: "SUCCESS_PDF_BY_ID",
        payload: data,
    };
};
export const errorPDFById = () => {
    return {
        type: "ERROR_PDF_BY_ID",
    };
};

export const getPDFById = (id) => {
    return (dispatch) => {
        dispatch(requestPDFById());
        API.get(`${baseURL}/receiving/${id}`).then((res) => {
            let getData = res.data;

            console.log(getData,"uuuuuuuuuuu")
            const unit = "pt";
            const size = "A4";
            const orientation = "potrait";

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);
            const title = "Receiving Report";
            const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
            const ReceivedBy = "Receiving Name: " + getData.data.receivedBy;
            const ReceivedDesc = "Receiving Description: " + getData.data.recivingDescription;
            const ReceivedDate = "Receiving Date: " + getData.data.receivingDate;
            const headers = [["Code","Product Name ", "Quantity","Rate","Total"]];
            const data = getData.data.receivingData.map((elt,index) => [elt.productId.code,elt.ProductName,  elt.requiredProductQuantity,elt.marketPrice==0?elt.productPrice:elt.marketPrice,(elt.marketPrice==0?elt.productPrice:elt.marketPrice)*elt.requiredProductQuantity])
            const Total = sumBy(data, "4");
            let content = {
                startY: 220,
                startX: 10,
                head: headers,
                body: data
            };
            
            doc.addImage(logo1, 'PNG', 40, 15, 100, 100);
            doc.setFontSize(20);
            doc.setFont(undefined, 'bold')
            doc.text(companyName, 150, 50, 0, 20);
            doc.setFontSize(13);
           doc.setFont('TimesNewRoman', 'Normal')
            doc.text(companyAddress, 150, 70, 0, 20);
            doc.text(companyNtn, 150, 90, 0, 20);
            doc.setFontSize(18);
            doc.setFont('TimesNewRoman', 'bold')
             doc.text(title, 200, 120);
             doc.setFontSize(11);
             doc.setFont('TimesNewRoman', 'Normal')
             doc.text(ReceivedDate, 40, 140, 0, 20);
             doc.text(ReceivedBy, 400, 140, 0, 20);
             doc.text(ReceivedDesc, 40, 160, 0, 20);
           //  doc.text(companyNtn, 400, 160, 0, 20);
            doc.autoTable(content);
            doc.text(`Total Amount: ${Total}`,440,doc.lastAutoTable.finalY + 20)
            doc.save(`Receiving Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
            dispatch(successPDFById(getData));
        }).catch((error) => {
            dispatch(errorPDFById());
        })
    }
};
export const requestSearchRecivingByDate = () => {
    return {
        type: "REQUEST_SEARCH_Reciving_BY_DATE",
    };
};
export const successSearchRecivingByDate = (data) => {
    console.log(data,"uuuuuuuuu")
    return {
        type: "SUCCESS_SEARCH_Reciving_BY_DATE",
        payload: data,
    };
};
export const errorSearchRecivingByDate = () => {
    return {
        type: "ERROR_SEARCH_Reciving_BY_DATE",
    };
};

export const getSearchRecivingByDate = (data,startDate,endDate) => {
    return (dispatch) => {
        dispatch(requestSearchRecivingByDate());
        API.post(`${baseURL}/receiving/receiving_with_dates`,data).then((res) => {
            let resData = res.data.data;
            console.log(resData,"uuuuuuuuuuu")
            // let resData = res.data.data;
            let resData1 = res.data;
            if (isEmpty(resData)) {
              handleNoDataVisible();
            } else {
              console.log(resData,resData1,"datessssss")
              const unit = "pt";
              const size = "A4";
              const orientation = "potrait";
    
              const marginLeft = 40;
              const doc = new jsPDF(orientation, unit, size);
    
              doc.setFontSize(11);
              const title = "Receiving Report";
              const companyName = `${resData1.resImage.restaurantName}`;
              const companyAddress = `${resData1.resImage.restaurantLocation}`;
              const companyNtn = `${resData1.resImage.ntn}`;
              const logo1 = resData1.restaurantImage;
  
              const Dates =
                !isEmpty(startDate) || !isEmpty(endDate)
                  ? "Issuance Date " + " " + startDate + " to " + endDate
                  : "Issuance Date - ";
              const headers = [["Code","Product Name", "Quantity","Rate","Amount"]];
              const data2 = resData.map((elt,index) => [
                elt.productId.code,
                elt.ProductName,
                elt.requiredProductQuantity,
                elt.productId.purchasePrice,
                elt.productId.purchasePrice*elt.requiredProductQuantity
                //    (elt.inventory.productPrice*elt.productQuantity)
              ]);
              const Total = sumBy(resData, "requiredProductQuantity");
              const TotalAmount = sumBy(data2, "4");
              // const shortTotal=Total.slice(12);
              let content = {
                startY: 190,
                startX: 10,
                head: headers,
                body: data2,
              };
              doc.addImage(logo1, "PNG", 40, 15, 100, 100);
              doc.setFontSize(20);
              doc.setFont(undefined, "bold");
              doc.text(companyName, 150, 40, 0, 20);
              doc.setFontSize(13);
              doc.setFont("TimesNewRoman", "Normal");
              doc.text(companyAddress, 150, 60, 0, 20);
              doc.text(companyNtn, 150, 80, 0, 20);
              doc.setFontSize(14);
              doc.setFont(undefined, "bold");
              doc.text(title, 240, 120);
              doc.setFontSize(12);
              doc.setFont(undefined, "normal");
              doc.text(`From ${startDate} to ${endDate}`, 220, 140);
    
              doc.autoTable(content);
              doc.text(
                `Total Quantity: ${Total.toFixed(2)}`,
                400,
                doc.lastAutoTable.finalY + 20,
                0,
                20
              );
              doc.text(
                `Total Amount: ${TotalAmount.toFixed(2)}`,
                400,
                doc.lastAutoTable.finalY + 40,
                0,
                20
              );
              doc.save(
                ` Receiving Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
              );
            }
            dispatch(successSearchRecivingByDate(resData));
        }).catch((error) => {
            dispatch(errorSearchRecivingByDate());
        })
    }
};
