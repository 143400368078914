import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { filter, isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addMasterControl, getUOM } from "./MasterControl.action";
import { setHeading } from "../../../accounts/account.actions";
import { getLevel5Data } from "../../accountsLevels/accountsLevel.action";
const AddMasterControl = (props) => {
  // const [masterControl, setMasterControl] = useState("");
  // const [masterControlUom, setMasterControlUom] = useState("");
  // const [masterControlUomName, setMasterControlUomName] = useState("");
  // const [masterControlDesc, setMasterControlDesc] = useState("");
  // const [uomPrice, setUOMPrice] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [cashId, setCashId] = useState("6439abd6b990438a2450690a");
  const [cashAccountTitle, setCashAccountTitle] = useState('');
  const [cashAccountId, setCashAccountId] = useState('');
  const [accountTitle, setAccountTitle] = useState('');
  const [accountId, setAccountId] = useState('');
  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  }

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }
function uploadMasterControl() {
    props.addMasterControl({
      orderVoucherAccount:accountId,
      orderVoucherCashAccount:cashAccountId
    }, handleVisible, handleDangerVisible);
   setAccountTitle("");
   setAccountId('');
   setCashAccountId('');
   setCashAccountTitle('');
  }
  const handleChangeAccount = (selectedOption) => {
    setAccountId(selectedOption.value);
    setAccountTitle(selectedOption.label);
  };
  const cashAccountNo =
  !isEmpty(props.level5List) &&
  props.level5List.map((x) => {
    let data = {
      value: x.levelFourData._id,
      label:
        x.levelFourData.allLevelKey + " /" + x.levelFourData.levelFourName,
    };
    return data;
  });
  const cashAccountNoTop =
    !isEmpty(props.level5List) &&
    props.level5List
      .filter((x) => x.levelThree._id == cashId)
      .map((y) => {
        let data = {
          value: y.levelFourData._id,
          label:
            y.levelFourData.allLevelKey + " /" + y.levelFourData.levelFourName,
        };
        return data;
      });
      console.log(cashAccountNo,"cash1111")
      const handleChangeCashAccount = (selectedOption) => {
        setCashAccountId(selectedOption.value);
        setCashAccountTitle(selectedOption.label);
      };
    useEffect(() => {
    props.setHeading("Add Master Control");
    props.getLevelFive();
    console.log(props.level5List, "list");
  }, []);
  return (
    <>
      {props.isFetchinglevel5List ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
            <Form>
                <Row>
                <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Order Voucher Account *</b></Form.Label>
                      <Select
                        placeholder="Select Order Voucher Account..."
                        onChange={handleChangeAccount}
                        options={filter(cashAccountNo ,x => x.value !== cashId)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Order Voucher Cash Account *</b></Form.Label>
                      <Select
                        placeholder="Select Order Voucher Cash Account..."
                        onChange={handleChangeCashAccount}
                        options={cashAccountNoTop}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {isEmpty(accountId)||isEmpty(cashAccountId)  ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "#1976d2", border: "1px solid #1976d2" }}>Save</Button> : <Button  onClick={uploadMasterControl}>Save</Button>}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMasterControl: (data, handleVisible, handleDangerVisible) => dispatch(addMasterControl(data, handleVisible, handleDangerVisible)),
    setHeading:(data)=>dispatch(setHeading(data)),
    // getUOM:()=>dispatch(getUOM()),
    getLevelFive:()=>dispatch(getLevel5Data())
  };
};
const mapStateToProps = (state, ownProps) => ({
  masterControlList: state.MasterControlReducer.masterControlList,
  isAddingMasterControl:state.MasterControlReducer.isAddingMasterControl,
  level5List:state.accountsLevelReducer.level5List,
  isFetchinglevel5List:state.accountsLevelReducer.isFetchinglevel5List,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMasterControl);
