import {
    Button,
    Container,
  } from "react-bootstrap";
  // import "./add-details.css";
  import { connect } from "react-redux";
  import MaterialFlavors from 'material-table';
  import Spinner from "react-bootstrap/Spinner";
  import { baseURL ,headers} from "../../../../../global/api";

  import { setHeading } from "../../../accounts/account.actions";
  import { useEffect } from "react";
import { getReceiving } from "./Receiving.action";
import MaterialTable from "material-table";
import { useNavigate } from 'react-router-dom';
  const ReceivingTable = (props) => {
    const permissions=JSON.parse(window.localStorage.getItem("permissions"))
    const Checkpermissions = (permissionsType,permissionsName) => {
      console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
     return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
    }
    const navigate = useNavigate();
   // const getData = props.ReceivingListWithDate.map((x) => { let data = { value: x.inventoryId, label: x.warehouse.whKey + "-" + x.category.cateKey + "-" + x.invKey + " " + x.productName }; return data })

    return (
      <>
      {console.log(props.ReceivingListWithDate, "uuwithDate")}
        {props.isFetchingReceivingWithDate ? (
          <div className="loader-div">
            <Spinner
              variant="primary"
              animation="grow"
              size="xl"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <Container fluid>
          <div className="main">
          <div className="sendDiv">
                             <Button  style={{marginTop:'10px'}} onClick={() =>navigate('/inventory/Product/RecivingReportDate') }>Back</Button>
                        </div>
          <MaterialFlavors
                    title="GRN Details"
                    columns={[
                      //  { title: 'Receiving Id', field: 'table_id', editable: 'never' },
                       { title: 'Receiving Date', field: 'receivingDate' },
                       { title: 'Restaurant Name', field: 'restaurantId.restaurantName' },
                       { title: 'Receivied By', field: 'receivedBy' },
                       { title: 'Receiving Description', field: 'recivingDescription' },
                     ]}
                     data={props.ReceivingListWithDate}
                //      data={(query) =>
                //       new Promise((resolve, reject) => {
                //           let url = `${baseURL}/receiving?`;
                //           url += "limit=" + query.pageSize;
                //           url += "&page=" + (query.page);
                //           url += "&search=" + query.search;
                //           fetch(url, {
                //               method: 'GET',
                //               headers: headers,
                //           })
                //               .then((response) => response.json())
                //               .then((result) => {
                //                   resolve({
                //                       data: result.data,
                //                       page: result.page,
                //                       totalCount: result.total_results,
                //                   });
                //               });
  
                //       })
                //   }
                  detailPanel={rowData => {
                    return (
                        <MaterialTable
                            //columns={state.columnsData}
                            columns={[
                                { title: 'Product Name ', field: 'ProductName' },
                                { title: 'Available Quantity', field: 'availableProductQuantity' },
                                { title: 'Add Quantity', field: 'requiredProductQuantity' },
                            ]}
                            data={rowData.receivingData}
                            // data={(query) =>
                            //     new Promise((resolve, reject) => {
                            //         let url = `${baseURL}/receiving?`;
                            //         url += "limit=" + query.pageSize;
                            //         url += "&page=" + (query.page);
                            //         url += "&search=" + query.search;
                            //         fetch(url, {
                            //             method: 'GET',
                            //             headers: headers,
                            //         })
                            //             .then((response) => response.json())
                                        
                            //             .then((result) => {
                            //                 let a=-1;
                            //                 resolve({
                                                
                            //                     data: result.data[a+1].receivingData,
                            //                     page: result.page,
                            //                     totalCount: result.total_results,
                            //                 });
                            //             });
                                        
                            //     })
                            // }                            
                            options={{
                                actionsColumnIndex: -1,
                                toolbar: false,
                                exportButton: true,
                                sorting: true,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}

                        />
                    )
                }}
                    options={{
                      actionsColumnIndex: -1,
                     // filtering: true,
                      exportButton: true,
                      exportAllData: true,
                      paging: true,
                      pageFlavors: 10,       // make initial page flavors
                      emptyRowsWhenPaging: false,   // To avoid of having empty rows
                      pageFlavorsOptions: [10, 20, 50],    // rows selection options
                      headerStyle: {
                        position: 'sticky', top: 0,
                        color: '#00BBBB',
                        fontWeight: '550',
                        onRowAdd: 'none',
                      },
  
                    }}
                 
                  />
                  </div>
                  </Container>
                  )}
                  </>
    );
  };
  const mapDispatchToProps = (dispatch) => {
    return {
    };
  };
  const mapStateToProps = (state, ownProps) => ({
    ReceivingListWithDate:state.RecivingReducer.ReceivingListWithDate,
    isFetchingReceivingWithDate:state.RecivingReducer.isFetchingReceivingWithDate,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ReceivingTable);
  