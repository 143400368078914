import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { addAging, agingReport } from "./Aging.actions";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { isEmpty, isDate } from "lodash";
import Select from "react-select";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../../Images/logo.png";
import { startFinancialDate } from "../../../../global/globalFunctions";
import { getLevel5LedgerData } from "../../../user_views/forms/ledger/Ledger.action";
import { setHeading } from "../../accounts/account.actions";
// import { getLevel5LedgerData } from "../ledger/Ledger.action";
const Aging = (props) => {
  const [item, setItem] = useState("");
  const [itemName, setItemName] = useState("");
  /*
  const handleChangeDays = (selectedOption) => {
    setDay(selectedOption.value);
    setStartDate("");
    setEndDate("");

  };
  const getDays = [
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '30', label: '30' },
    { value: '50', label: '50' },
  ];

  var dateShow = new Date();
  var currentDate = new Date();
  dateShow = moment(dateShow.setDate(dateShow.getDate() - day)).format("YYYY-MM-DD");
  currentDate = moment(currentDate).format("YYYY-MM-DD");

  const [state, setState] = React.useState({
    columns: [
      { title: 'Level Key', field: 'allLevelKey' },
      { title: 'Level Balance', field: 'levelBalance' },
      { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
    ],
  });*/
  const getLevel5LedgerData1 = props.level5LedgerData.map((x) => { let data = { value: x._id, label: x.allLevelKey + "" + x.levelFourName }; return data });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
    setItemName(selectedOption.label);
  };
  useEffect(() => {
     props.getLevel5LedgerData();
     props.setHeading("Aging Report")
    //   props.addAging({
    //     startedDate: startFinancialDate,
    //     endDate: moment(new Date()).format("YYYY-MM-DD"),
    //     userId: window.localStorage.getItem("userId")
    //  });
  }, []);

  function searchData() {
    props.agingReport(
      {
        levelFourId: item,
      },
      item,
      itemName
    );
  }
  return (
    <>
      <Container fluid>
        <div className="main">
          <div>
            <Form>
              <Row >
                {" "}
                <Col lg="12" md="12" sm="12">
                  <Form.Group>
                    <Form.Label>
                      <b> Select Account *</b>
                    </Form.Label>
                    <Select
                      placeholder="Select Account..."
                      onChange={handleChangeLevel5Ledger}
                      options={getLevel5LedgerData1}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col lg="12" sm="12" className="text-center">
                  <Button  disabled={isEmpty(item)?true:false} onClick={(e) => searchData()}>
                    Generate Aging Report
                  </Button>
                </Col>
              </Row>

              {/* <MaterialTable
                  title="Account Aging Summary"
                  //columns={state.columns}
                  columns={[
                    { title: 'Level Key', field: 'allLevelKey' },
                    { title: 'Level Balance', field: 'levelBalance' },
                    { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                  ]}
                  data={!isEmpty(day) ? getAging.filter(item => moment(item.createdAt).format("YYYY-MM-DD") <= currentDate && moment(item.createdAt).format("YYYY-MM-DD") >= dateShow) : getAging}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    paging: true,
                    pageSize: 200,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    exportPdf: (columns, data) => {
                      const doc = new jsPDF();

                      const columnTitles = [
                        { title: 'Level Key', field: 'allLevelKey' },
                        { title: 'Level Balance', field: 'levelBalance' },
                        { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                      ]
                        .map(columnDef => columnDef.title);

                      const pdfData = data.map(rowData =>
                        [
                          { title: 'Level Key', field: 'allLevelKey' },
                          { title: 'Level Balance', field: 'levelBalance' },
                          { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                        ].map(columnDef => (console.log(rowData[columnDef.field], "eeeee"), columnDef.field == 'createdAt' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
                      );
                      let content = {
                        startY: 50,
                        startX: 5,
                        head: [columnTitles],
                        body: pdfData
                      };
                      doc.addImage(logo, 'PNG', 10, 11, 30, 30);
                      doc.text(`Company Name: ${process.env.REACT_APP_COMPANY_NAME}`, 100, 20, 0, 20);
                      doc.text("Aging Report", 30, 45, 0, 20);
                      doc.autoTable(content);

                      doc.save(`AgingReport.pdf`);
                    },
                    headerStyle: {
                      position: 'sticky', top: 0,
                      color: '#00BBBB',
                      fontWeight: '550',
                      onRowAdd: 'none',
                    }
                  }}
                /> */}
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    agingReport: (data, code, title) =>
      dispatch(agingReport(data, code, title)),
      getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
      setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  isFetchingLevel5LedgerData: state.AccountLegderReducer.isFetchingLevel5LedgerData,
  // agingList: state.AddAgingReducer.agingList,
  // isFetchingAddAging: state.AddAgingReducer.isFetchingAddAging,
});

export default connect(mapStateToProps, mapDispatchToProps)(Aging);
